import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  mainBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  root: {
    height: '80px',
  },
  logo: {
    flexGrow: 0,
    height: '50px',
    transition: '0.6s',
    '&:hover': {
      transform: 'scale(0.9)',
    },
    [theme.breakpoints.up('md')]: {
      height: '70px',
    },
  },
  links: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      flexGrow: 1,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  additionalInfo: {
    margin: 'auto',
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  pageLink: {
    marginLeft: '15px',
    marginRight: '15px',
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    transition: '0.6s',
    paddingBottom: '5px',
    paddingLeft: '2px',
    paddingRight: '2px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
    },
  },
  activePageLink: {
    marginLeft: '15px',
    marginRight: '15px',
    color: theme.palette.primary.contrastText,
    transition: ' borderBottom 0.6s',
    textDecoration: 'none',
    paddingBottom: '5px',
    paddingLeft: '2px',
    paddingRight: '2px',
    borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
  },
}));

export default useStyles;
