import React from 'react';
import { Box, Grid, Slide, Typography, useTheme } from '@material-ui/core';

import useStyles from './Philosophy.css';
import Spacer from '../util/Spacer';

const Philosophy: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Slide in direction="down" timeout={500}>
        <div className={classes.hero} />
      </Slide>
      <div className={classes.root}>
        <Grid container>
          <Slide in direction="right" timeout={500}>
            <Grid item xs={12} md={7} className={classes.textAreaLeft}>
              <Typography variant="h4" color="primary" gutterBottom>
                KÜCHENPHILOSOPHIE
              </Typography>
              <Spacer height={30} />
              <Typography
                color="textSecondary"
                variant="body1"
                align="justify"
                gutterBottom
              >
                Top-Qualität und Frische, ernährungsbewusst und vorwiegend
                regional heißt für uns PRODUKTKENNTNIS.
              </Typography>
              <Typography color="textSecondary" variant="body1" align="justify">
                Wir wissen{' '}
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  WER
                </span>{' '}
                produziert.
              </Typography>
              <Typography color="textSecondary" variant="body1" align="justify">
                Wir wissen wer{' '}
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  WO
                </span>{' '}
                produziert.
              </Typography>
              <Typography color="textSecondary" variant="body1" align="justify">
                Wir wissen wer, wo{' '}
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  WAS
                </span>{' '}
                produziert.
              </Typography>
              <Typography color="textSecondary" variant="body1" align="justify">
                Wir wissen wer, wo, was{' '}
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  WANN
                </span>{' '}
                produziert.
              </Typography>
              <Typography color="textSecondary" variant="body1" align="justify">
                Wir wissen wer, wo, was, wann{' '}
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  WIE
                </span>{' '}
                produziert.
              </Typography>
              <Typography color="textSecondary" variant="body1" align="justify">
                Wir wissen wer, wo, was, wann, wie{' '}
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  WARUM
                </span>{' '}
                produziert.
              </Typography>
              <Spacer height={30} />
              <Typography variant="h6" color="primary" gutterBottom>
                NACHHALTIG, KLIMAFREUNDLICH, EFFIZIENT
              </Typography>
              <Box p={1}>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Kürzester Lieferweg in der Beschaffung bis zur Zustellung
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Jedes Gericht frisch zubereitet und gekocht durch erprobte
                  Mis en Place
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Faire Preisgestaltung
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Überwiegend zertifizierte Zutatenauswahl (z.B.: AMA Bio- und
                  AMA-Gütesiegel, BIO- Produkte, etc.)
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Regional, wenn möglich – global, wenn nötig
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Frei von künstlichen Aromen
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Frei von Konservierungsstoffen
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Ernährungsbewusste Zusammenstellung der Menüpläne und
                  Einzelgerichte
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="justify"
                >
                  ✔ Auslieferung/Abolung in hygienischen Thermoboxen mit
                  Porzellangeschirr und E-Bus
                </Typography>
              </Box>
              <Spacer height={30} />
              <Typography
                color="textSecondary"
                variant="body1"
                align="justify"
                gutterBottom
              >
                Mit diesem Anspruch an uns selbst kochen wir mit Liebe und
                Leidenschaft täglich für Sie frisch in kürzester Zeit und
                verneigen uns vor den großartigen Qualitätsprodukten und
                Menschen unserer Heimat.
              </Typography>
              <Spacer height={30} />
              <Typography
                color="textSecondary"
                variant="body1"
                align="justify"
                gutterBottom
              >
                Wir bedanken uns bei Ihnen und schätzen Ihre Aufmerksamkeit und
                Wertschätzung.
              </Typography>
            </Grid>
          </Slide>
          <Slide in direction="left" timeout={500}>
            <Grid item xs={12} md={5} className={classes.imgArea1} />
          </Slide>
        </Grid>
      </div>
    </>
  );
};

export default Philosophy;
