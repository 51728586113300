import { DateTime } from 'luxon';
import { IOrder } from '../api/Order';
import { IUser } from '../api/User';
import { calculateTotalPrice } from './util';
import { getOrderStateLabel, getOrderTypeLabel } from './translate';
import { ICompany } from '../api/Company';
import { OrderTypeEnum } from '../api/API';
import { getHoursFromISO } from './time';

interface ICsvOrder {
  id: string;
  Bezahlstatus: string;
  email: string;
  Name: string;
  Nachname: string;
  Addresse: string;
  PLZ: string;
  Tel: string;
  Firma: string;
  Trinkgeld: string;
  AbholungLieferung: string;
  Bestelldatum: string;
  Lieferdatum: string;
  Thermoboxen: number;
  Kommentar: string;
  Firmenzuschuss: string;
  Artikelanzahl: number;
  Gesamtpreis: string;
  Artikel: string[];
  Firmenname: string;
  Firmenaddresse?: string;
  FirmenPLZ?: string;
  Firmenkontaktperson?: string;
  Firmenemail?: string;
  Firmenkontaktnummer?: string;
  FirmentischID?: string;
  FirmenzuschussartikelID?: string;
  Bestelllimit?: string;
  Lieferzeit?: string;
  Firmenkommentar?: string;
}

interface ICsvUser {
  Erstellt: string;
  Email: string;
  Firmenadmin: string;
  Tel: string;
  Name: string;
  Nachname: string;
  Addresse: string;
  PLZ: string;
}

export interface IUserWithCompanyName extends IUser {
  companyName: string;
}

const replaceColonWithComma = (toReplace: string): string => {
  return toReplace.replace('.', ',');
};

export const getCsvDataFormatFromOrders = (
  orders: IOrder[],
  companies?: ICompany[]
): ICsvOrder[] => {
  const csvOrders: ICsvOrder[] = orders.map((order) => {
    const company =
      companies && companies.find((c) => c.id === order.companyId);
    return {
      id: order.id,
      Bezahlstatus: getOrderStateLabel(order.orderState),
      email: order.userName,
      Name: order.name,
      Nachname: order.surname,
      Addresse: order.address,
      PLZ: order.zip,
      Tel: order.phoneNumber.replace('+', '(+)'),
      Firma: order.companyName,
      Trinkgeld: replaceColonWithComma((order.tip / 100).toString()),
      AbholungLieferung:
        order.orderType === OrderTypeEnum.DELIVERY
          ? getOrderTypeLabel(order.orderType)
          : `${getOrderTypeLabel(order.orderType)} (${getHoursFromISO(
              order.deliveryDate
            )})`,
      Bestelldatum: DateTime.fromISO(order.orderDate).toLocaleString({
        locale: 'de',
      }),
      Lieferdatum: DateTime.fromISO(order.deliveryDate).toLocaleString({
        locale: 'de',
      }),
      Thermoboxen: order.thermoBoxes,
      Kommentar: order.comment,
      Firmenzuschuss: replaceColonWithComma((order.discount / 100).toString()),
      Artikelanzahl: order.articles.length,
      Gesamtpreis: calculateTotalPrice(
        order.articles,
        order.discount,
        order.tip
      )
        .toString()
        .replace('.', ','),
      Artikel: order.articles.map((article) => {
        const mods =
          article.modifiers.length > 0
            ? article.modifiers.map((mod) => ` +${mod.name}`)
            : [];
        const modsString = mods.length > 0 ? ` (${mods.join(',')})` : '';
        return ` ${article.name}${modsString}`;
      }),
      Firmenname: order.companyName,
      Firmenaddresse: company?.address,
      FirmenPLZ: company?.zip,
      Firmenkontaktperson: company?.contactPerson,
      Firmenemail: company?.contactMail,
      Firmenkontaktnummer: company?.mobileNumber.replace('+', '(+)'),
      FirmentischID: company?.tableId,
      FirmenzuschussartikelID: company?.discountArticleId,
      Bestelllimit: company?.employeeOrderLimit
        ? replaceColonWithComma((company.employeeOrderLimit / 100).toString())
        : '',
      Lieferzeit: company?.orderSortTime,
      Firmenkommentar: company?.comment,
    };
  });

  return csvOrders;
};

export const getCsvDataFormatFromUsers = (
  users: IUserWithCompanyName[]
): ICsvUser[] => {
  const csvUsers = users.map((user) => ({
    Firmenname: user.companyName,
    Erstellt: DateTime.fromISO(user.createdAt).toLocaleString({
      locale: 'de',
    }),
    Email: user.email,
    Firmenadmin: user.isCompanyAdmin ? 'Ja' : 'Nein',
    Tel: `"${user.mobileNumber}"`,
    Name: user.name,
    Nachname: user.surname,
    Addresse: user.address,
    PLZ: user.zip,
  }));
  return csvUsers;
};
