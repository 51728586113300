import React from 'react';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import useStyles from './TopBar.css';
import { IRouteConfig } from '../router/routes';
import Logo from '../../static/images/ccgmplogo.png';
import FullScreenMenu from '../fullScreenMenu/FullScreenMenu';

interface IProps {
  routes: IRouteConfig;
}

const TopBar = ({ routes }: IProps): JSX.Element => {
  const classes = useStyles();
  const currentLocation = useLocation();
  const [isFsMenuOpen, setIsFsMenuOpen] = React.useState(false);
  const toggleFsMenu = () => setIsFsMenuOpen((prev) => !prev);

  const elevationTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const PageLink = ({
    name,
    route,
  }: {
    name: string;
    route: string;
  }): JSX.Element => {
    let classname =
      currentLocation.pathname === route
        ? classes.activePageLink
        : classes.pageLink;

    if (currentLocation.pathname.includes('app') && route === routes.home) {
      classname = classes.activePageLink;
    }
    return (
      <RouterLink to={route} className={classname}>
        {name}
      </RouterLink>
    );
  };

  return (
    <Box displayPrint="none">
      <AppBar
        className={classes.mainBar}
        position="sticky"
        elevation={elevationTrigger ? 4 : 0}
      >
        <Toolbar className={classes.root}>
          <RouterLink to={routes.home}>
            <img src={Logo} alt="Logo" className={classes.logo} />
          </RouterLink>
          <Typography variant="body2" className={classes.links}>
            <PageLink route={routes.home} name="BESTELLUNGEN" />
            <PageLink route={routes.menu} name="MENÜPLAN" />
            <PageLink route={routes.news} name="NEWS" />
            <PageLink route={routes.gastro} name="GASTRO" />
            <PageLink route={routes.interviews} name="INTERVIEWS" />
            <PageLink route={routes.contact} name="KONTAKT" />
            <PageLink route={routes.philosophy} name="PHILOSOPHIE" />
          </Typography>
          <Typography
            component="div"
            variant="caption"
            className={classes.additionalInfo}
          >
            <Box textAlign="center">Mo - Fr: 08:00 bis 15:00 Uhr</Box>
            <Box textAlign="center">Küche bis 14:00 Uhr</Box>
            <Box textAlign="center">
              Samstag, Sonntag und Feiertag geschlossen
            </Box>
          </Typography>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleFsMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div id="back-to-top-anchor" />
      <FullScreenMenu isOpen={isFsMenuOpen} toggle={toggleFsMenu} />
    </Box>
  );
};

export default TopBar;
