import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  orderList: {
    marginBottom: '20px',
  },
  totalRow: {
    borderTop: `2px solid ${theme.palette.grey[400]}`,
    fontWeight: 'bold',
  },
  itemImg: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '3px',
      marginRight: '20px',
      display: 'block',
      maxWidth: '100px',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '3px',
      marginRight: '20px',
      display: 'block',
      maxWidth: '200px',
    },
  },
  deleteIcon: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '20px',
    },
  },
}));

export default useStyles;
