import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'none',
  },
  uploadButton: {
    position: 'absolute',
    bottom: '5px',
    right: '5px',
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
