import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { IArticle } from '../../api/Article';
import ArticleRow from './ArticleRow';

interface IProps {
  articles: IArticle[];
}

const ArticleList = ({ articles }: IProps): JSX.Element => {
  if (articles.length === 0) {
    return (
      <>
        <Typography variant="body2">
          Es wurden noch keine Artikel Hinzugefügt
        </Typography>
      </>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Artikelbezeichnung</TableCell>
            <TableCell align="right">Preis (€)</TableCell>
            <TableCell align="right">MWSt. (€)</TableCell>
            <TableCell align="right">Allergene</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {articles.map((article) => (
            <ArticleRow article={article} key={`articlerow-${article.id}`} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ArticleList;
