import React from 'react';
import { Fab, Typography } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';

import SkeletonList from '../skeletonList/SkeletonList';
import ArticleList from './ArticleList';
import useStyles from './AppArticleManagement.css';
import useArticleSync from './useArticleSync';
import { useTrackedState } from '../../store/Articles';
import useArticleActions from './useArticleActions';

const AppArticleManagement = (): JSX.Element => {
  const classes = useStyles();
  const { articles, loading, error } = useTrackedState();
  const { sync, loading: syncInProgress } = useArticleSync();
  const { fetch } = useArticleActions();

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const SyncButton = () => (
    <Fab
      className={classes.syncButton}
      variant="extended"
      color="primary"
      onClick={sync}
      disabled={loading || syncInProgress}
    >
      <SyncIcon className={classes.fabIcon} />
      Kassa Sync
    </Fab>
  );

  if (error) {
    return (
      <Typography variant="body2" color="error">
        {error}
      </Typography>
    );
  }

  if (loading) {
    return <SkeletonList />;
  }

  return (
    <>
      <ArticleList articles={articles} />
      <SyncButton />
    </>
  );
};

export default AppArticleManagement;
