import React from 'react';
import { List, Paper, Typography } from '@material-ui/core';

import { useTrackedState } from '../../store/Thermoboxes';
import SkeletonList from '../skeletonList/SkeletonList';
import BoxItem from './BoxItem';
import useBoxManagementActions from './useBoxManagementActions';
import { IThermoBoxCount } from '../../api/Thermobox';
import ChangeBoxCountDialog from './ChangeBoxCountDialog';
import usePolling from '../../hooks/usePolling';

const initialBoxState = {
  ownerId: '',
  ownerName: '',
  quantity: 0,
};

const AppBoxManagement = (): JSX.Element => {
  const { fetch, returnBoxes, addBoxes } = useBoxManagementActions();
  const isFirstRun = React.useRef(true);
  const { flag } = usePolling(10000);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogBoxState, setDialogBoxState] = React.useState<IThermoBoxCount>(
    initialBoxState
  );
  const { boxes, error, loading } = useTrackedState();
  React.useEffect(() => {
    if (isFirstRun.current) {
      fetch(false);
      isFirstRun.current = false;
    } else {
      fetch(true);
    }
  }, [fetch, flag]);

  const setBoxDialog = (box: IThermoBoxCount) => {
    setDialogBoxState(box);
    setDialogOpen(true);
  };

  const closeBoxDialog = () => {
    setDialogOpen(false);
    setTimeout(() => {
      setDialogBoxState(initialBoxState);
    }, 200);
  };

  if (loading) {
    return <SkeletonList />;
  }

  if (error) {
    return (
      <Typography align="center" color="error">
        {error}
      </Typography>
    );
  }

  if (boxes.length < 1) {
    return (
      <Typography align="center" color="primary">
        Derzeit keine Thermoboxen ausständig
      </Typography>
    );
  }

  return (
    <List component={Paper} dense>
      {boxes.map((box) => (
        <BoxItem
          key={`thermoboxlistitem-${box.ownerId}`}
          boxCount={box}
          selectBox={setBoxDialog}
        />
      ))}
      <ChangeBoxCountDialog
        open={dialogOpen}
        close={closeBoxDialog}
        box={dialogBoxState}
        returnBoxes={returnBoxes}
        addBoxes={addBoxes}
      />
    </List>
  );
};

export default AppBoxManagement;
