/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@material-ui/core';
import { DateTime } from 'luxon';

import useOrderArticleCountByDateRange from '../../hooks/useOrderArticleCountByDateRange';
import WeekPicker from './WeekPicker';
import PlanningTable from './PlanningTable';
import { IOrderArticleCounts } from '../../api/Order';

const WeeklyManagement = (): JSX.Element => {
  const [startDate, setStartDate] = React.useState(
    DateTime.now().startOf('day').toString()
  );

  const { data, loading, error } = useOrderArticleCountByDateRange(
    startDate,
    DateTime.fromISO(startDate).plus({ days: 6 }).endOf('day').toString()
  );

  const handleStartDateChange = (newDate: DateTime) => {
    if (newDate.isValid) {
      setStartDate(newDate.startOf('day').toString());
    }
  };

  const fillEmptyDays = (counts: IOrderArticleCounts[]) => {
    const filledCounts: IOrderArticleCounts[] = [
      {
        date: startDate.substring(0, 10),
        counts: [],
      },
      {
        date: DateTime.fromISO(startDate)
          .plus({ days: 1 })
          .toString()
          .substring(0, 10),
        counts: [],
      },
      {
        date: DateTime.fromISO(startDate)
          .plus({ days: 2 })
          .toString()
          .substring(0, 10),
        counts: [],
      },
      {
        date: DateTime.fromISO(startDate)
          .plus({ days: 3 })
          .toString()
          .substring(0, 10),
        counts: [],
      },
      {
        date: DateTime.fromISO(startDate)
          .plus({ days: 4 })
          .toString()
          .substring(0, 10),
        counts: [],
      },
      {
        date: DateTime.fromISO(startDate)
          .plus({ days: 5 })
          .toString()
          .substring(0, 10),
        counts: [],
      },
      {
        date: DateTime.fromISO(startDate)
          .plus({ days: 6 })
          .toString()
          .substring(0, 10),
        counts: [],
      },
    ];

    counts.forEach((count) => {
      const found = filledCounts.find((filled) => filled.date === count.date);
      if (found) {
        found.counts = count.counts;
      }
    });
    return filledCounts;
  };

  return (
    <>
      <WeekPicker handleStartDateChange={handleStartDateChange} />
      <Box>
        {loading && 'Loading'}
        {error && error}
        {data && <PlanningTable counts={fillEmptyDays(data)} />}
      </Box>
    </>
  );
};

export default WeeklyManagement;
