const GDPR_KEY = 'mp-gdpr-consent';

interface IUseGdprConsent {
  getConsent: () => boolean;
  confirm: () => void;
}

const useGdprConsent = (): IUseGdprConsent => {
  const getConsent = () => {
    return localStorage.getItem(GDPR_KEY) !== null;
  };

  const confirm = () => {
    localStorage.setItem(GDPR_KEY, new Date().toLocaleString());
  };

  return { getConsent, confirm };
};

export default useGdprConsent;
