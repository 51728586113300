import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  syncButton: {
    position: 'fixed',
    bottom: '15px',
    right: '15px',
  },
  fabIcon: {
    marginRight: '5px',
  },
  rowRoot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  detailTextFieldArea: {
    padding: '20px',
  },
}));

export default useStyles;
