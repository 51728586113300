import React from 'react';
import { CreateUserInput, DeleteUserInput } from '../../api/API';

import {
  addUser,
  deleteUser,
  updateUser,
  getUsersByCompanyId,
  IUser,
} from '../../api/User';
import useNotificationActions from '../../hooks/useNotificationActions';
import { useDispatch } from '../../store/Users';

interface IReturn {
  fetch: () => Promise<void>;
  add: (input: CreateUserInput) => Promise<void>;
  deleteUsr: (input: DeleteUserInput) => Promise<void>;
  update: (input: IUser) => Promise<void>;
}

const useUserActions = (companyId: string): IReturn => {
  const dispatch = useDispatch();
  const { show } = useNotificationActions();

  const fetch = React.useCallback(async () => {
    dispatch({ type: 'startLoading' });
    try {
      const res = await getUsersByCompanyId({ companyId });
      if (res.data?.getUsersByCompanyId?.error) {
        dispatch({
          type: 'setError',
          error: 'User konnten nicht geladen werden',
        });
      }

      if (res.data?.getUsersByCompanyId?.data) {
        const newUsers = res.data?.getUsersByCompanyId?.data as IUser[];
        newUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch({ type: 'setUsers', users: newUsers });
      }
    } catch (err) {
      dispatch({
        type: 'setError',
        error: 'User konnten nicht geladen werden',
      });
    }
  }, [dispatch, companyId]);

  const deleteUsr = async (input: DeleteUserInput) => {
    try {
      const res = await deleteUser(input);
      if (res.data?.deleteUser?.error) {
        show('Eror', res.data?.deleteUser?.error.message, 'error');
      } else if (res.data?.deleteUser?.data) {
        dispatch({ type: 'deleteUser', userIdToDelete: input.id });
        show(
          'Gelöscht',
          `${input.email} wurde erfolgreich gelöscht`,
          'success'
        );
      }
    } catch (error) {
      show('Eror', 'User konnte nicht gelöscht werden', 'error');
    }
  };

  const add = async (input: CreateUserInput) => {
    try {
      const res = await addUser(input);

      if (res.data?.createUser?.error) {
        show('Error', res.data.createUser.error.message, 'error');
      }

      if (res.data?.createUser?.data) {
        fetch();
        show(
          'User hinzugefügt',
          `Eine Einladung wurde an ${input.email} versendet`,
          'success'
        );
      }
    } catch (error) {
      show('Error', 'User konnte nicht gespeichert werden', 'error');
    }
  };

  const update = async (newUser: IUser) => {
    try {
      const res = await updateUser({
        ...newUser,
        canOrderOnBill: newUser.canOrderOnBill ? newUser.canOrderOnBill : false,
      });
      if (res.data?.updateUser?.error) {
        show('Error', res.data.updateUser.error.message, 'error');
      }

      if (res.data?.updateUser?.data) {
        fetch();
        show('User upgedated', '', 'success');
      }
    } catch (error) {
      show('Error', 'User konnte nicht upgedated werden', 'error');
    }
  };

  return {
    fetch,
    add,
    deleteUsr,
    update,
  };
};

export default useUserActions;
