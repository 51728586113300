import { Color } from '@material-ui/lab';

import { useDispatch } from '../store/Notification';

interface IReturn {
  show(title: string, message: string, severity: Color): void;
  hide(): void;
}

const useNotificationActions = (): IReturn => {
  const dispatch = useDispatch();

  const show = (title: string, message: string, severity: Color) => {
    dispatch({
      type: 'show',
      payload: {
        title,
        message,
        severity,
      },
    });
  };

  const hide = () => dispatch({ type: 'hide' });

  return { show, hide };
};

export default useNotificationActions;
