import React from 'react';

import { Provider as CompanyProvider } from '../../store/Companies';
import { Provider as UsersProvider } from '../../store/Users';
import UserManagementAdminView from './AdminView';
import CompanyAdminView from './CompanyAdminView';

interface IProps {
  adminView: boolean;
  companyId: string;
}

const AppUserManagment = ({ adminView, companyId }: IProps): JSX.Element => (
  <CompanyProvider>
    {adminView ? (
      <UserManagementAdminView />
    ) : (
      <UsersProvider>
        <CompanyAdminView companyId={companyId} />
      </UsersProvider>
    )}
  </CompanyProvider>
);

export default AppUserManagment;
