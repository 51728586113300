/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
import { API, GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

export interface GraphQLOptions {
  variables?: object;
  authMode?: GRAPHQL_AUTH_MODE;
}

async function callGraphQL<T>(
  query: any,
  options?: GraphQLOptions
): Promise<GraphQLResult<T>> {
  return (await API.graphql({
    query,
    ...options,
  })) as GraphQLResult<T>;
}

export default callGraphQL;
