import React from 'react';
import { Button, Typography } from '@material-ui/core';

import { useTrackedState as useTrackedCartState } from '../../store/Cart';
import { useTrackedState as useTrackedAuthState } from '../../store/Auth';
import OrderItemList from './OrderItemList';
import useStyles from './AppCheckout.css';
import { calculateTotalPrice } from '../../util/util';
import CheckoutDialog from './CheckoutDialog';

const AppCheckout = (): JSX.Element => {
  const { items } = useTrackedCartState();
  const { user, userDetails, companyDetails, role } = useTrackedAuthState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = useStyles();

  if (items.length < 1) {
    return (
      <div className={classes.root}>
        <Typography variant="body2" align="center" gutterBottom>
          Derzeit befinden sich keine Artikel im Warenkorb
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" align="center" gutterBottom>
        Bestellübersicht:
      </Typography>
      <OrderItemList
        items={items}
        isGuest={user === 'GAST'}
        discount={companyDetails?.discount || undefined}
        editView
      />
      <Button
        color="primary"
        variant="contained"
        size="large"
        fullWidth
        onClick={() => setDialogOpen(true)}
      >
        Jetzt Bestellen (€
        {calculateTotalPrice(
          items,
          companyDetails ? companyDetails.discount : 0
        )}
        )
      </Button>
      <CheckoutDialog
        items={items}
        role={role}
        userDetails={userDetails}
        companyDetails={companyDetails}
        open={dialogOpen}
        close={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default AppCheckout;
