import React from 'react';
import { DateTime } from 'luxon';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import useStyles from './AppMenuManagement.css';
import useMenuActions from './useMenuActions';
import { useTrackedState } from '../../store/Menu';
import { RoleEnum, useTrackedState as useAuthState } from '../../store/Auth';
import MenuCard from './MenuCard';
import { IMenu } from '../../api/Menu';
import { MenuEnum } from '../../api/API';
import SkeletonList from '../skeletonList/SkeletonList';
import PrintButton from '../printButton/PrintButton';

const getDateString = (dt: DateTime) => dt.toString().slice(0, 10);

const splitMenusIntoDay = (menus: IMenu[]) => {
  const initialMenus: IMenu[] = [
    {
      menuType: MenuEnum.MENU1,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
    {
      menuType: MenuEnum.MENU2,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
    {
      menuType: MenuEnum.MENU3,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
    {
      menuType: MenuEnum.MENU4,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
    {
      menuType: MenuEnum.MENU5,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
    {
      menuType: MenuEnum.MENU6,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
    {
      menuType: MenuEnum.MENU7,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
    {
      menuType: MenuEnum.ALT_MENU1,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
    {
      menuType: MenuEnum.ALT_MENU2,
      description: 'In Planung',
      allergens: '-',
      date: '',
    },
  ];
  const monMenus: IMenu[] = [...initialMenus];
  const tueMenus: IMenu[] = [...initialMenus];
  const wedMenus: IMenu[] = [...initialMenus];
  const thuMenus: IMenu[] = [...initialMenus];
  const friMenus: IMenu[] = [...initialMenus];

  menus
    .sort((a, b) => (a.menuType > b.menuType ? 1 : -1))
    .forEach((menu) => {
      const day = DateTime.fromISO(menu.date).get('weekday');
      switch (day) {
        case 1:
          monMenus.splice(
            monMenus.findIndex((m) => m.menuType === menu.menuType),
            1,
            menu
          );
          break;
        case 2:
          tueMenus.splice(
            tueMenus.findIndex((m) => m.menuType === menu.menuType),
            1,
            menu
          );
          break;
        case 3:
          wedMenus.splice(
            wedMenus.findIndex((m) => m.menuType === menu.menuType),
            1,
            menu
          );
          break;
        case 4:
          thuMenus.splice(
            thuMenus.findIndex((m) => m.menuType === menu.menuType),
            1,
            menu
          );
          break;
        case 5:
          friMenus.splice(
            friMenus.findIndex((m) => m.menuType === menu.menuType),
            1,
            menu
          );
          break;
        default:
          break;
      }
    });

  return {
    monMenus,
    tueMenus,
    wedMenus,
    thuMenus,
    friMenus,
  };
};

const AppMenuManagement = (): JSX.Element => {
  const classes = useStyles();
  const { role } = useAuthState();
  const { fetch } = useMenuActions();
  const [addWeek, setAddWeek] = React.useState(0);
  const [dateStrings, setDateStrings] = React.useState({
    mon: '',
    tue: '',
    wed: '',
    thu: '',
    fri: '',
  });
  const { menus, loading, error } = useTrackedState();
  const {
    monMenus,
    tueMenus,
    wedMenus,
    thuMenus,
    friMenus,
  } = splitMenusIntoDay(menus);

  React.useEffect(() => {
    const now = DateTime.local().plus({ weeks: addWeek });
    const mon = now.startOf('week');
    const tue = mon.plus({ days: 1 });
    const wed = mon.plus({ days: 2 });
    const thu = mon.plus({ days: 3 });
    const fri = mon.plus({ days: 4 });
    setDateStrings({
      mon: getDateString(mon),
      tue: getDateString(tue),
      wed: getDateString(wed),
      thu: getDateString(thu),
      fri: getDateString(fri),
    });
    fetch(role === RoleEnum.GUEST, getDateString(mon), getDateString(fri));
  }, [fetch, addWeek, role]);

  if (error) {
    return (
      <Typography color="error" align="center">
        {error}
      </Typography>
    );
  }

  return (
    <Grid container spacing={1}>
      <Box p={2}>
        <Typography variant="caption" color="textSecondary">
          Alle Preise verstehen sich in Euro angeführt inklusive aller Steuern
          und Abgaben. Mit Erscheinen dieser Preise/Preisliste verlieren alle
          vorhergehenden Preise und Listen Ihre Gültigkeit.
          <br />
          <a
            href="/documents/Informationspflicht_Lebensmittelherkunft.pdf"
            download
          >
            Hier können Sie die Informationspflicht zur Lebensmittelherkunft
            downloaden.
          </a>
        </Typography>
      </Box>
      <Grid item xs={12} className={classes.navButtons}>
        <Box displayPrint="none">
          <IconButton onClick={() => setAddWeek((prev) => prev - 1)}>
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton onClick={() => setAddWeek((prev) => prev + 1)}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Grid>
      {loading ? (
        <SkeletonList tiles amount={5} width={260} />
      ) : (
        <>
          <Grid item xs={12} md={4} lg>
            <MenuCard date={dateStrings.mon} day="Montag" menus={monMenus} />
          </Grid>
          <Grid item xs={12} md={4} lg>
            <MenuCard date={dateStrings.tue} day="Dienstag" menus={tueMenus} />
          </Grid>
          <Grid item xs={12} md={4} lg>
            <MenuCard date={dateStrings.wed} day="Mittwoch" menus={wedMenus} />
          </Grid>
          <Grid item xs={12} md={4} lg>
            <MenuCard
              date={dateStrings.thu}
              day="Donnerstag"
              menus={thuMenus}
            />
          </Grid>
          <Grid item xs={12} md={4} lg>
            <MenuCard date={dateStrings.fri} day="Freitag" menus={friMenus} />
          </Grid>
        </>
      )}
      <PrintButton bottom={15} right={80} />
    </Grid>
  );
};

export default AppMenuManagement;
