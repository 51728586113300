import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IDeliveryViewOrders } from '../../hooks/useOrderActions';
import useStyles from './AppOrderManagement.css';
import DeliveryViewTable from './DeliveryViewTable';
import { CompanyToDetailsMap } from './types';

interface IProps {
  orders: IDeliveryViewOrders;
  expandLvl1: boolean;
  expandLvl2: boolean;
  detailsMap: CompanyToDetailsMap;
}

interface IDeliveryAccordionProps {
  orders: IDeliveryViewOrders;
  orderGroup: string;
  expandLvl1: boolean;
  expandLvl2: boolean;
  detailsMap: CompanyToDetailsMap;
}

const DeliveryAccordion = ({
  orders,
  orderGroup,
  expandLvl1,
  expandLvl2,
  detailsMap,
}: IDeliveryAccordionProps): JSX.Element => {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setExpanded((prev) => {
      if (prev !== expandLvl1) {
        return expandLvl1;
      }
      return prev;
    });
  }, [expandLvl1]);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.accordionHeading}>
          {`${
            detailsMap[orderGroup] ? detailsMap[orderGroup].sortTime : ''
          } ${orderGroup}
          ${
            detailsMap[orderGroup] && detailsMap[orderGroup].comment
              ? `(${detailsMap[orderGroup].comment})`
              : ''
          }`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DeliveryViewTable
          orders={orders[orderGroup]}
          expandLvl2={expandLvl2}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const DeliveryView = ({
  orders,
  expandLvl1,
  expandLvl2,
  detailsMap,
}: IProps): JSX.Element => {
  const sortedByDeliveryTimeCompanies = Object.keys(orders).sort((a, b) => {
    if (!detailsMap[a]) {
      return 1;
    }
    if (!detailsMap[b]) {
      return -1;
    }
    if (detailsMap[a].sortTime < detailsMap[b].sortTime) {
      return -1;
    }
    return 1;
  });
  return (
    <>
      {sortedByDeliveryTimeCompanies.map((orderGroup) => (
        <DeliveryAccordion
          orders={orders}
          orderGroup={orderGroup}
          expandLvl1={expandLvl1}
          expandLvl2={expandLvl2}
          detailsMap={detailsMap}
        />
      ))}
    </>
  );
};

export default DeliveryView;
