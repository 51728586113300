import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { IMenu } from '../../api/Menu';
import { getReadableDateFromISO } from '../../util/time';
import { getMenuTypeLabel } from '../../util/translate';
import useMenuActions from './useMenuActions';

interface IProps {
  open: boolean;
  close(): void;
  menu: IMenu;
}

const SetMenuDialog = ({ open, close, menu }: IProps): JSX.Element => {
  const { updateMenu } = useMenuActions();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, errors } = useForm<IMenu>({
    defaultValues: {
      description: menu.description,
      allergens: menu.allergens,
    },
  });

  const handleSetMenu = async (data: IMenu) => {
    setLoading(true);
    await updateMenu({
      ...data,
      date: menu.date,
      menuType: menu.menuType,
    });
    setLoading(false);
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <form
        noValidate
        id={`${menu.date}${menu.menuType}-form`}
        onSubmit={handleSubmit(handleSetMenu)}
      >
        <DialogTitle>
          {`${getMenuTypeLabel(menu.menuType)} für den ${getReadableDateFromISO(
            menu.date
          )} bearbeiten`}
        </DialogTitle>
        <DialogContent>
          <TextField
            disabled={loading}
            margin="normal"
            id="description"
            name="description"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.description?.message}
            error={!!errors.description}
            label="Beschreibung"
            fullWidth
            variant="outlined"
          />
          <TextField
            disabled={loading}
            margin="normal"
            id="allergens"
            name="allergens"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.allergens?.message}
            error={!!errors.allergens}
            label="Allergene"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          {loading && <LinearProgress />}
          <Button disabled={loading} onClick={close}>
            Abbrechen
          </Button>
          <Button disabled={loading} color="primary" type="submit">
            Speichern
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetMenuDialog;
