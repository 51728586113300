import React from 'react';
import { FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';

import useStyles from './AppArticleManagement.css';
import { IArticle } from '../../api/Article';
import useDebounce from '../../hooks/useDebounce';
import ImageInput from '../imageInput/ImageInput';
import useArticleActions from './useArticleActions';
import { useTrackedState } from '../../store/Auth';

interface IProps {
  article: IArticle;
}

const ArticleDetailsPanel = ({ article }: IProps): JSX.Element => {
  const { update } = useArticleActions();
  const { user } = useTrackedState();
  const [loading, setLoading] = React.useState(false);
  const [maximumOrders, setMaximumOrders] = React.useState(
    article.maximumOrders
  );

  const classes = useStyles();

  const debouncedMaximumOrders = useDebounce<number>({
    value: maximumOrders || 0,
    debounceTime: 300,
  });

  const updateImage = async (key: string) => {
    setLoading(true);
    await update({
      ...article,
      imageId: key,
    });
    setLoading(false);
  };

  const handleArticleUpdate = async (
    field: 'isLocked' | 'hasThermobox',
    value: boolean
  ) => {
    setLoading(true);
    await update({
      ...article,
      [field]: value,
    });
    setLoading(false);
  };

  const handleMaxOrdersChange = async (value: number) => {
    setLoading(true);
    await update({
      ...article,
      maximumOrders: value,
    });
    setLoading(false);
  };

  React.useEffect(() => {
    if (debouncedMaximumOrders !== article.maximumOrders) {
      handleMaxOrdersChange(debouncedMaximumOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMaximumOrders]);

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      <Grid item xs={12} md={4}>
        <ImageInput
          id={article.id}
          loading={loading}
          imageKey={article.imageId}
          maxFileSize={500}
          label="Artikelbild"
          updateCallback={updateImage}
          isGuest={user === 'GAST'}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container justify="flex-end">
          <FormControlLabel
            control={
              <Switch
                checked={article.hasThermobox}
                onChange={(ev) =>
                  handleArticleUpdate('hasThermobox', ev.target.checked)
                }
                name="hasThermobox"
                color="primary"
              />
            }
            disabled={loading}
            label="Thermobox hinzufügen"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Switch
                checked={article.isLocked}
                onChange={(ev) =>
                  handleArticleUpdate('isLocked', ev.target.checked)
                }
                name="hasThermobox"
                color="primary"
              />
            }
            disabled={loading}
            label="Artikel sperren"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} className={classes.detailTextFieldArea}>
        <TextField
          type="number"
          fullWidth
          value={maximumOrders}
          onChange={(ev) => setMaximumOrders(+ev.target.value)}
          label="Max. Bestellungen / Tag"
          disabled={loading}
        />
      </Grid>
    </Grid>
  );
};

export default ArticleDetailsPanel;
