import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { IArticleGroup } from './types';
import ItemCard from './ItemCard';

interface IProps {
  group: IArticleGroup;
  isGuest: boolean;
  addDisabled: boolean;
}

const ArticleGroup = ({ group, isGuest, addDisabled }: IProps): JSX.Element => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="textSecondary">
          {group.groupname}
        </Typography>
      </Grid>
      {group.articles.map((article) => (
        <Grid item xs={12} sm={6} md={4} key={`itemcard-${article.id}`}>
          <ItemCard
            addDisabled={addDisabled}
            article={article}
            isGuest={isGuest}
          />
        </Grid>
      ))}
    </>
  );
};

export default ArticleGroup;
