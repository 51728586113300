import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: '95px',
    [theme.breakpoints.up('sm')]: {
      bottom: '20px',
    },
    right: '20px',
    zIndex: 1,
  },
}));

export default useStyles;
