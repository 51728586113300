import React from 'react';

import { useDispatch } from '../../store/Articles';
import { getAllArticles, IArticle, updateArticle } from '../../api/Article';
import { UpdateArticleInput } from '../../api/API';
import useNotificationActions from '../../hooks/useNotificationActions';

interface IReturn {
  fetch(): void;
  fetchActive(isGuest: boolean): void;
  update(newArticle: IArticle): void;
}

const useArticleActions = (): IReturn => {
  const dispatch = useDispatch();
  const { show } = useNotificationActions();

  const fetch = React.useCallback(async () => {
    dispatch({ type: 'startLoading' });
    try {
      const res = await getAllArticles(false);

      if (res.errors || res.data?.getAllArticles?.error) {
        dispatch({
          type: 'setError',
          error: 'Artikel konnten nicht geladen werden',
        });
      }

      if (res.data?.getAllArticles?.data) {
        const newArticles = res.data?.getAllArticles?.data as IArticle[];
        newArticles.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch({ type: 'setArticles', articles: newArticles });
      }
    } catch (err) {
      dispatch({
        type: 'setError',
        error: 'Artikel konnten nicht geladen werden',
      });
    }
  }, [dispatch]);

  const fetchActive = React.useCallback(
    async (guest: boolean) => {
      dispatch({ type: 'startLoading' });
      try {
        const res = await getAllArticles(guest);

        if (res.errors || res.data?.getAllArticles?.error) {
          dispatch({
            type: 'setError',
            error: 'Artikel konnten nicht geladen werden',
          });
        }

        if (res.data?.getAllArticles?.data) {
          let newArticles = res.data?.getAllArticles?.data as IArticle[];
          newArticles = newArticles.filter(
            (article) => !article.isDeprecated && !article.isLocked
          );
          newArticles.sort((a, b) => (a.name > b.name ? 1 : -1));
          dispatch({ type: 'setArticles', articles: newArticles });
        }
      } catch (err) {
        dispatch({
          type: 'setError',
          error: 'Artikel konnten nicht geladen werden',
        });
      }
    },
    [dispatch]
  );

  const update = async (newArticle: IArticle): Promise<void> => {
    const input: UpdateArticleInput = {
      id: newArticle.id,
      imageId: newArticle.imageId,
      isLocked: newArticle.isLocked,
      isMainDish: newArticle.isMainDish,
      maximumOrders: newArticle.maximumOrders,
      hasThermobox: newArticle.hasThermobox,
    };

    try {
      const res = await updateArticle(input);
      if (res.errors || res.data?.updateArticle?.error) {
        show('Error', 'Artikel konnte nicht aktualisiert werden.', 'error');
      }

      if (res.data?.updateArticle?.data) {
        dispatch({ type: 'updateArticle', newArticle });
        show('Gespeichert', 'Artikel wurde aktualisiert.', 'success');
      }
    } catch (error) {
      show('Error', 'Artikel konnte nicht aktualisiert werden.', 'error');
    }
  };

  return { fetch, fetchActive, update };
};

export default useArticleActions;
