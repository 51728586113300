if (
  // process.env.REACT_APP_APPSYNC_URL === undefined ||
  // process.env.REACT_APP_APPSYNC_REGION === undefined ||
  // process.env.REACT_APP_APPSYNC_AUTHENTIACTION_TYPE === undefined ||
  process.env.REACT_APP_COGNITO_REGION === undefined ||
  process.env.REACT_APP_COGNITO_USER_POOL_ID === undefined ||
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID === undefined ||
  process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID === undefined
) {
  throw new Error('Missing env variables');
}

const awsConfig = {
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  appSync: {
    URL: process.env.REACT_APP_APPSYNC_URL,
    REGION: process.env.REACT_APP_APPSYNC_REGION,
    AUTHENTIACTION_TYPE: process.env.REACT_APP_APPSYNC_AUTHENTIACTION_TYPE,
  },
};

export default awsConfig;
