/* eslint-disable no-useless-escape */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { UpdateCompanyInput } from '../../api/API';
import { ICompany } from '../../api/Company';
import useCompanyActions from './useCompanyActions';
import * as regex from '../../util/regex';
import { RoleEnum, useTrackedState as useAuthState } from '../../store/Auth';

interface IProps {
  open: boolean;
  close(): void;
  input: ICompany;
  single: boolean;
}

const UpdateCompanyDialog = ({
  open,
  close,
  input,
  single,
}: IProps): JSX.Element => {
  const { register, handleSubmit, errors } = useForm<UpdateCompanyInput>({
    defaultValues: {
      id: input.id,
      address: input.address,
      companyName: input.companyName,
      contactMail: input.contactMail,
      contactPerson: input.contactPerson,
      discount: input.discount / 100,
      mobileNumber: input.mobileNumber,
      tableId: input.tableId,
      zip: input.zip,
      discountArticleId: input.discountArticleId,
      employeeOrderLimit:
        input.employeeOrderLimit && input.employeeOrderLimit / 100,
      orderSortTime: input.orderSortTime ? input.orderSortTime : '12:00',
      comment: input.comment ? input.comment : '',
    },
  });

  const [loading, setLoading] = React.useState(false);

  const { update } = useCompanyActions();

  const { role } = useAuthState();

  const onSubmit = async (data: UpdateCompanyInput) => {
    setLoading(true);
    await update(
      {
        ...data,
        id: input.id,
        discount: data.discount * 100,
        employeeOrderLimit: data.employeeOrderLimit * 100,
      },
      single
    );
    setLoading(false);
    close();
  };

  return (
    <Dialog fullWidth open={open} onClose={close}>
      <DialogTitle id="form-dialog-title">Unternehmen bearbeiten</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>Daten bearbeiten:</DialogContentText>
          <TextField
            disabled={loading || role !== RoleEnum.ADMIN}
            autoFocus
            margin="dense"
            id="name"
            name="companyName"
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: {
                value: 3,
                message: 'Min. 3 Zeichen',
              },
            })}
            helperText={errors.companyName?.message}
            error={!!errors.companyName}
            label="Firmenname"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="address"
            label="Addresse"
            fullWidth
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.address?.message}
            error={!!errors.address}
            name="address"
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="zip"
            label="PLZ"
            fullWidth
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.zip?.message}
            error={!!errors.zip}
            name="zip"
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="contactperson"
            label="Kontaktperson"
            fullWidth
            name="contactPerson"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.contactPerson?.message}
            error={!!errors.contactPerson}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            name="contactMail"
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.email,
                message: 'Bitte gültige Email Adresse eingeben',
              },
            })}
            helperText={errors.contactMail?.message}
            error={!!errors.contactMail}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="tel"
            label="Kontaktnummer"
            type="tel"
            fullWidth
            name="mobileNumber"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.mobileNumber?.message}
            error={!!errors.mobileNumber}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="tableId"
            label="TischId"
            fullWidth
            name="tableId"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.tableId?.message}
            error={!!errors.tableId}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="discountArticleId"
            label="Firmenzuschuss-ArtikelId"
            fullWidth
            name="discountArticleId"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.discountArticleId?.message}
            error={!!errors.discountArticleId}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="discount"
            type="number"
            label="Firmenzuschuss"
            fullWidth
            name="discount"
            inputRef={register({
              required: 'Pflichtfeld',
              min: {
                value: 0.0,
                message: 'Zuschuss kann nicht weniger als 0 betragen',
              },
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            helperText={errors.discount?.message}
            error={!!errors.discount}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="employeeOrderLimit"
            type="number"
            label="Bestelllimit für Angestellte"
            fullWidth
            name="employeeOrderLimit"
            inputRef={register({
              required: 'Pflichtfeld',
              min: {
                value: 0.0,
                message: 'Bestelllimit kann nicht weniger als 0 betragen',
              },
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            helperText={errors.employeeOrderLimit?.message}
            error={!!errors.employeeOrderLimit}
          />
          <TextField
            disabled={loading || role !== RoleEnum.ADMIN}
            margin="dense"
            id="orderSortTime"
            type="time"
            label="Lieferzeit"
            fullWidth
            name="orderSortTime"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.orderSortTime?.message}
            error={!!errors.orderSortTime}
          />
          <TextField
            disabled={loading || role !== RoleEnum.ADMIN}
            margin="dense"
            id="comment"
            label="Kommentar"
            fullWidth
            name="comment"
            inputRef={register()}
            helperText={errors.comment?.message}
            error={!!errors.comment}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={close} color="primary">
            Abbrechen
          </Button>
          <Button disabled={loading} type="submit" color="primary">
            {loading ? <CircularProgress size={20} /> : 'Speichern'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateCompanyDialog;
