import React from 'react';
import {
  Avatar,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import useStyles from './SetNewPwForm.css';
import { useTrackedState } from '../../store/Auth';
import useAuthActions from '../../hooks/useAuthActions';

const SetNewPwForm = (): JSX.Element => {
  const classes = useStyles();
  const { isAuthenticating, tempCognitoUser } = useTrackedState();
  const { completeNewPw } = useAuthActions();
  const [pwd, setPwd] = React.useState('');
  const [pwdConfirm, setPwdConfirm] = React.useState('');

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <VpnKeyIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Neues Passwort wählen
      </Typography>
      <Typography component="h3" variant="caption" align="center">
        {/* eslint-disable-next-line prettier/prettier */}
        (min. 10 Zeichen bestehend aus aus Nummern, Großbuchstaben und Kleinbuchstaben)
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="pwd"
          label="Neues Passwort"
          type="password"
          name="pwd"
          autoComplete="current-password"
          value={pwd}
          onChange={(ev) => setPwd(ev.target.value)}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="repeatPassword"
          label="Neues Passwort wiederholen"
          type="password"
          value={pwdConfirm}
          onChange={(ev) => setPwdConfirm(ev.target.value)}
          id="password"
          autoComplete="current-password"
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={
            isAuthenticating || !pwdConfirm || !pwd || pwd !== pwdConfirm
          }
          onClick={() => completeNewPw(pwd, tempCognitoUser)}
        >
          {isAuthenticating ? <CircularProgress size={25} /> : 'Bestätigen'}
        </Button>
      </form>
    </div>
  );
};

export default SetNewPwForm;
