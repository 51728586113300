/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DateTime } from 'luxon';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Link,
  Box,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';

import { IArticle } from '../../api/Article';
import { CreateOrderInput, OrderTypeEnum } from '../../api/API';
import useOrderActions from './useOrderActions';
import { IUser } from '../../api/User';
import { ICompany } from '../../api/Company';
import OrderItemList from './OrderItemList';
import { calculateTotalPrice } from '../../util/util';
import {
  getMinDate,
  isWeekend,
  isHoliday,
  orderContainsMenu,
  isInValidOrderRange,
} from './util';
import * as regex from '../../util/regex';
import useNotificationActions from '../../hooks/useNotificationActions';
import routes from '../router/routes';
import { RoleEnum } from '../../store/Auth';
import useCartActions from '../appOrder/useCartActions';
import ccard from '../../static/images/ccard.png';
import klarna from '../../static/images/klarna.png';
import sodexo from '../../static/images/sodexo.png';
import gpay from '../../static/images/gpay.jpg';
import apay from '../../static/images/apay.png';
import edenred from '../../static/images/edenred.jpg';

interface IProps {
  open: boolean;
  close(): void;
  items: IArticle[];
  role: RoleEnum;
  userDetails: IUser | undefined;
  companyDetails: ICompany | undefined;
}

const CheckoutDialog = ({
  open,
  close,
  items,
  role,
  userDetails,
  companyDetails,
}: IProps): JSX.Element => {
  const { create, createBatch, createPayment, loading } = useOrderActions();
  const [isPaidOnBill, setIsPaidOnBill] = React.useState(false);
  const [gdpr, setGdpr] = React.useState(false);
  const isBatch =
    isPaidOnBill && (role === RoleEnum.COMPANYADMIN || role === RoleEnum.ADMIN);
  const discount = !isPaidOnBill ? companyDetails?.discount || 0 : 0;
  const isGuest = role === RoleEnum.GUEST;
  const {
    register,
    errors,
    handleSubmit,
    control,
    watch,
  } = useForm<CreateOrderInput>({
    defaultValues: {
      companyId: userDetails?.companyId || 'GAST',
      userId: userDetails?.id || 'GAST',
      userName: userDetails?.email || '',
      companyName: companyDetails?.companyName || 'GAST',
      paymentId: '',
      comment: '',
      tip: 0,
      orderType: isGuest ? OrderTypeEnum.PICKUP : OrderTypeEnum.DELIVERY,
      discount,
      articles: items,
      name: userDetails?.name || '',
      surname: userDetails?.surname || '',
      address: companyDetails?.address || '',
      zip: companyDetails?.zip || '',
      tableId: companyDetails?.tableId || '',
      phoneNumber: userDetails?.mobileNumber || '',
    },
  });
  const { show } = useNotificationActions();
  const { clearCart } = useCartActions();
  const [tipHelper, setTipHelper] = React.useState(0);
  const orderTypeHelper = watch('orderType');
  const orderHasMenu = orderContainsMenu(items);
  const minDate = getMinDate(orderHasMenu, isGuest);
  const [selectedDate, setSelectedDate] = React.useState<DateTime | null>(null);
  const history = useHistory();
  const canOrderOnBill =
    role === RoleEnum.ADMIN ||
    role === RoleEnum.COMPANYADMIN ||
    (role === RoleEnum.USER && userDetails?.canOrderOnBill === true);

  const handleTimeChange = (newTime: DateTime | null) => {
    if (newTime && newTime.isValid) {
      const hour = newTime.get('hour');
      const minute = newTime.get('minute');
      setSelectedDate((prev) => prev && prev?.set({ hour, minute }));
    }
  };

  const order = async (input: CreateOrderInput) => {
    const res = await create(input, isGuest);
    if (res) {
      await createPayment(res);
    }
  };

  const orderOnBill = async (input: CreateOrderInput) => {
    const res = await createBatch({
      ...input,
      isBatch,
    });
    if (res) {
      history.push(`${routes.postPayment}?success=true`);
      clearCart();
    }
  };

  const onSubmit = async (data: CreateOrderInput) => {
    let orderDate = selectedDate
      ? DateTime.fromISO(selectedDate.toString())
      : null;
    // set date to 11:00 if delivery is selected
    if (data.orderType === OrderTypeEnum.DELIVERY && orderDate !== null) {
      orderDate = orderDate.set({ hour: 11 });
    }
    const isBeforeMinDate = orderDate ? orderDate < minDate : false;
    let validOrderRange = true;
    if (data.orderType === OrderTypeEnum.PICKUP && orderDate) {
      validOrderRange = isInValidOrderRange(orderDate);
    }

    if (
      calculateTotalPrice(items, discount, tipHelper * 100) < 0.5 &&
      calculateTotalPrice(items, discount, tipHelper * 100) - discount > 0.0
    ) {
      show(`Mindestbestellwert €0.5`, '', 'warning');
      return;
    }

    if (!gdpr) {
      show(
        `Datenschutz`,
        `Bitte bestätigen Sie die Datenschutzbestimmungen`,
        'warning'
      );
      return;
    }

    // check if order limit is reached when payed on bill
    if (isPaidOnBill && !isBatch) {
      if (companyDetails && companyDetails.employeeOrderLimit) {
        if (
          calculateTotalPrice(items, discount, tipHelper * 100) >
          companyDetails.employeeOrderLimit / 100
        ) {
          show(
            `Bestelllimit`,
            `Ihr Bestelllimit beträgt €${
              companyDetails.employeeOrderLimit / 100
            }`,
            'warning'
          );
          return;
        }
      } else if (calculateTotalPrice(items, discount, tipHelper * 100) > 50) {
        show(`Bestelllimit`, `Ihr Bestelllimit beträgt €50`, 'warning');
        return;
      }
    }

    // check if selected date is valid
    if (
      !orderDate ||
      !orderDate.isValid ||
      isBeforeMinDate ||
      !validOrderRange
    ) {
      const orderTypeTerm =
        data.orderType === OrderTypeEnum.PICKUP ? 'Abholdatum' : 'Lieferdatum';
      show(
        `${orderTypeTerm} überprüfen`,
        `${
          data.orderType === OrderTypeEnum.DELIVERY
            ? 'Firmenbestellungen können nur bis 14:00 am Vortag getätigt werden.'
            : 'Zubereitungszeit beträgt 30 Min. Menüs können nur bis 09:00 am selben Tag getätigt werden.'
        }`,
        'warning'
      );
    } else {
      const newOrder: CreateOrderInput = {
        ...data,
        companyId: userDetails?.companyId || 'GAST',
        userId: userDetails?.id || 'GAST',
        companyName: companyDetails?.companyName || 'GAST',
        tableId: companyDetails?.tableId || '',
        discountArticleId: companyDetails?.discountArticleId || '',
        userName: userDetails?.email || data.userName, // EMAIL
        paymentId: '',
        discount,
        articles: items,
        name: userDetails?.name || data.name,
        surname: userDetails?.surname || data.surname,
        tip: Math.round(data.tip * 100),
        deliveryDate: orderDate.toString(),
      };
      if (
        isPaidOnBill ||
        calculateTotalPrice(items, discount, tipHelper * 100) * 100 <= 0
      ) {
        orderOnBill(newOrder);
      } else {
        order(newOrder);
      }
    }
  };

  return (
    <Dialog open={open} maxWidth="md" fullScreen disableEnforceFocus>
      <DialogTitle>Bestellung bestätigen</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <OrderItemList
                items={items}
                isGuest={isGuest}
                discount={discount}
                editView={false}
              />
              <Typography
                variant="h6"
                color="primary"
                align="center"
                gutterBottom
              >
                Gesamt: €{calculateTotalPrice(items, discount, tipHelper * 100)}
              </Typography>
              <Typography variant="subtitle1" color="primary">
                Akzeptierte Zahlungsarten:
              </Typography>
              <Box m={2}>
                <Box
                  m={1}
                  style={{
                    height: '50px',
                    backgroundImage: `url(${ccard})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }}
                />
                <Box
                  m={1}
                  style={{
                    height: '50px',
                    backgroundImage: `url(${klarna})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }}
                />
                <Box
                  m={1}
                  style={{
                    height: '50px',
                    backgroundImage: `url(${sodexo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }}
                />
                <Box
                  m={1}
                  style={{
                    height: '50px',
                    backgroundImage: `url(${gpay})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }}
                />
                <Box
                  style={{
                    height: '50px',
                    backgroundImage: `url(${apay})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }}
                />
                <Box
                  style={{
                    height: '50px',
                    backgroundImage: `url(${edenred})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }}
                />
              </Box>
            </Grid>
            <Grid style={{ padding: '20px' }} item xs={12} md={6}>
              {
                // ORDER ON BILL
              }
              {canOrderOnBill && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPaidOnBill}
                      onChange={(ev) => setIsPaidOnBill(ev.target.checked)}
                      name="isPaidOnBill"
                      color="primary"
                    />
                  }
                  label="Bezahlung auf Rechnung"
                />
              )}
              {
                // ORDER TYPE
              }
              <Controller
                as={
                  <RadioGroup aria-label="orderType">
                    <FormControlLabel
                      disabled={!isGuest}
                      value={OrderTypeEnum.PICKUP}
                      control={<Radio />}
                      label="Zustellung (derzeit nicht möglich)"
                    />
                    <FormControlLabel
                      disabled={isGuest}
                      value={OrderTypeEnum.DELIVERY}
                      control={<Radio />}
                      label="Abholung"
                    />
                  </RadioGroup>
                }
                name="orderType"
                control={control}
              />
              {
                // DATE
              }
              <DatePicker
                disablePast
                label={
                  orderTypeHelper === OrderTypeEnum.PICKUP
                    ? 'Abholdatum'
                    : 'Lieferdatum'
                }
                openTo="date"
                views={['date']}
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                renderInput={(props) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    {...props}
                  />
                )}
                shouldDisableDate={(day) =>
                  isWeekend(day as DateTime) || isHoliday(day as DateTime)
                }
                minDate={minDate}
                maxDate={DateTime.local().plus({ days: 7 })}
              />

              {
                // PICKUPTIME
              }
              {orderTypeHelper === OrderTypeEnum.PICKUP && (
                <TimePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  minutesStep={30}
                  ampm={false}
                  ampmInClock={false}
                  label="Abholzeit"
                  value={selectedDate}
                  onChange={(newValue) => handleTimeChange(newValue)}
                  shouldDisableTime={(timeValue, clockType) => {
                    if (clockType === 'hours') {
                      if (timeValue < 11 || timeValue > 14) {
                        return true;
                      }
                    }
                    return false;
                  }}
                />
              )}
              {
                // TIP
              }
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                  inputProps: { min: 0, max: 100 },
                }}
                disabled={loading}
                autoFocus
                margin="dense"
                id="tip"
                name="tip"
                type="number"
                onChange={(ev) => setTipHelper(+ev.target.value)}
                inputRef={register({
                  required: 'Pflichtfeld',
                  min: 0,
                })}
                helperText={errors.tip?.message}
                error={!!errors.tip}
                label="Trinkgeld"
                fullWidth
              />
              {
                // COMMENT
              }
              <TextField
                variant="outlined"
                disabled={loading}
                margin="dense"
                id="comment"
                name="comment"
                inputRef={register()}
                helperText={errors.comment?.message}
                error={!!errors.comment}
                label="Kommentar"
                fullWidth
                multiline
              />
              {
                // EMAIL
              }
              <TextField
                variant="outlined"
                disabled={loading || !isGuest}
                margin="dense"
                id="userName"
                name="userName"
                inputRef={register({
                  required: 'Pflichtfeld',
                  pattern: {
                    value: regex.email,
                    message: 'Bitte geben Sie eine gültige Email Adresse ein',
                  },
                })}
                helperText={errors.userName?.message}
                error={!!errors.userName}
                label="Email"
                fullWidth
              />
              {
                // NAME
              }
              <TextField
                variant="outlined"
                disabled={loading || !isGuest}
                margin="dense"
                id="name"
                name="name"
                inputRef={register({
                  required: 'Pflichtfeld',
                  minLength: {
                    value: 3,
                    message: 'Min 3 Zeichen',
                  },
                })}
                helperText={errors.name?.message}
                error={!!errors.name}
                label="Name"
                fullWidth
              />
              {
                // SURNAME
              }
              <TextField
                variant="outlined"
                disabled={loading || !isGuest}
                margin="dense"
                id="surname"
                name="surname"
                inputRef={register({
                  required: 'Pflichtfeld',
                  minLength: {
                    value: 3,
                    message: 'Min 3 Zeichen',
                  },
                })}
                helperText={errors.surname?.message}
                error={!!errors.surname}
                label="Nachname"
                fullWidth
              />
              {
                // ADDRESS
              }
              <TextField
                variant="outlined"
                disabled={loading}
                margin="dense"
                id="address"
                name="address"
                inputRef={register({
                  required: 'Pflichtfeld',
                  minLength: 3,
                })}
                helperText={errors.address?.message}
                error={!!errors.address}
                label="Adresse"
                fullWidth
              />
              {
                // ZIP
              }
              <TextField
                variant="outlined"
                disabled={loading}
                margin="dense"
                id="zip"
                name="zip"
                inputRef={register({
                  required: 'Pflichtfeld',
                  pattern: {
                    value: regex.austrianZip,
                    message: 'Bitte geben Sie eine gültige Postleitzahl an',
                  },
                })}
                helperText={errors.zip?.message}
                error={!!errors.zip}
                label="PLZ"
                fullWidth
              />
              {
                // PHONE
              }
              <TextField
                variant="outlined"
                disabled={loading}
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                inputRef={register({
                  required: 'Pflichtfeld',
                  pattern: {
                    value: regex.austrianPhone,
                    message:
                      'Bitte geben Sie eine gültige Telefonnummer an (+43)',
                  },
                })}
                helperText={errors.phoneNumber?.message}
                error={!!errors.phoneNumber}
                label="Telefonnummer"
                fullWidth
              />
              {
                // GDPR
              }
              <FormControlLabel
                style={{ marginTop: '5px' }}
                control={
                  <Checkbox
                    checked={gdpr}
                    onChange={(ev) => setGdpr(ev.target.checked)}
                    name="isBatch"
                    color="primary"
                  />
                }
                label={
                  <Typography>
                    Die <Link href={routes.gdpr}>Datenschutzhinweise</Link>{' '}
                    einschließlich der Möglichkeiten zum Widerruf der
                    Verarbeitung meiner Daten habe ich zur Kenntnis genommen.
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        {loading && <LinearProgress />}
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            Abbrechen
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            kostenpflichtig Bestellen
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CheckoutDialog;
