import React from 'react';
import { Color } from '@material-ui/lab';
import { createContainer } from 'react-tracked';

export interface INotificationState {
  open: boolean;
  msg: string;
  title: string;
  severity: Color;
}

export type NotificationAction =
  | {
      type: 'show';
      payload: {
        title: string;
        message: string;
        severity: Color;
      };
    }
  | { type: 'hide' };

const initialState: INotificationState = {
  open: false,
  msg: '',
  title: '',
  severity: 'info',
};

const notificationReducer = (
  state: INotificationState,
  action: NotificationAction
): INotificationState => {
  switch (action.type) {
    case 'show':
      return {
        open: true,
        msg: action.payload.message,
        title: action.payload.title,
        severity: action.payload.severity,
      };

    case 'hide':
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

const useValue = () => React.useReducer(notificationReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
