import React from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';

import useNotificationActions from '../../hooks/useNotificationActions';
import { createOrder, createBatchOrder } from '../../api/Order';
import { CreateOrderInput } from '../../api/API';
import { stripePk } from '../../static/config/stripe';

interface IReturn {
  create(input: CreateOrderInput, isGuest: boolean): Promise<string | null>;
  createBatch(input: CreateOrderInput): Promise<string | null>;
  createPayment(sessionId: string): void;
  loading: boolean;
}

const useOrderActions = (): IReturn => {
  const [stripePromise] = React.useState(loadStripe(stripePk));
  const { show } = useNotificationActions();
  const [loading, setLoading] = React.useState(false);

  const create = async (input: CreateOrderInput, isGuest: boolean) => {
    setLoading(true);
    try {
      const res = await createOrder(input, isGuest);
      if (res.data?.createOrder?.error) {
        show('Error', res.data?.createOrder?.error.message, 'error');
        setLoading(false);
        return null;
      }
      if (res.data?.createOrder?.data) {
        setLoading(false);
        return res.data?.createOrder?.data;
      }
      setLoading(false);
      return null;
    } catch (error) {
      show('Error', 'Bestellung konnte nicht aufgegeben werden', 'error');
      setLoading(false);
      return null;
    }
  };

  const createBatch = async (input: CreateOrderInput) => {
    setLoading(true);
    try {
      const res = await createBatchOrder(input);
      if (res.data?.createBatchOrder?.error) {
        show('Error', res.data?.createBatchOrder?.error.message, 'error');
        setLoading(false);
        return null;
      }
      if (res.data?.createBatchOrder?.data) {
        setLoading(false);
        return res.data?.createBatchOrder?.data;
      }
      setLoading(false);
      return null;
    } catch (error) {
      show('Error', 'Bestellung konnte nicht aufgegeben werden', 'error');
      setLoading(false);
      return null;
    }
  };

  const createPayment = async (sessionId: string) => {
    setLoading(true);
    try {
      const stripe = await stripePromise;
      await stripe?.redirectToCheckout({ sessionId });
    } catch (error) {
      show(
        'Error',
        'Verbindung zu Zahlunsdienstleister konnte nicht hergestellt werden',
        'error'
      );
    }
    setLoading(false);
  };

  return {
    loading,
    create,
    createBatch,
    createPayment,
  };
};

export default useOrderActions;
