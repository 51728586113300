export enum SortVariantEnum {
  byUser = 'byUser',
  byArticle = 'byArticle',
}

export interface IDetails {
  sortTime: string;
  comment?: string;
}

export interface CompanyToDetailsMap {
  [companyName: string]: IDetails;
}
