import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';

import { IThermoBoxCount } from '../../api/Thermobox';

interface IProps {
  boxCount: IThermoBoxCount;
  selectBox(box: IThermoBoxCount): void;
}

const BoxItem = ({ boxCount, selectBox }: IProps): JSX.Element => {
  return (
    <ListItem button onClick={() => selectBox(boxCount)}>
      <ListItemText
        primary={`${boxCount.ownerName} ${
          boxCount.phone ? `(${boxCount.phone})` : ''
        }`}
        secondary={`${boxCount.quantity} ${
          boxCount.quantity > 1 ? 'Boxen' : 'Box'
        }`}
      />
    </ListItem>
  );
};

export default BoxItem;
