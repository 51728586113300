import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { IOrderArticleCounts } from '../../api/Order';

interface IProps {
  counts: IOrderArticleCounts[];
}

interface TableData {
  [article: string]: number[];
}

interface IRowProps {
  counts: number[];
  articleName: string;
}

const createTableData = (data: IOrderArticleCounts[]) => {
  const tableData: TableData = {};
  data.forEach((counts, dateIndex) => {
    counts.counts.forEach((articleCount) => {
      if (!tableData[articleCount.articleName]) {
        tableData[articleCount.articleName] = new Array(data.length).fill(0);
      }
      tableData[articleCount.articleName][dateIndex] += articleCount.count;
    });
  });
  return tableData;
};

const PlanningTableRow = ({ counts, articleName }: IRowProps): JSX.Element => {
  return (
    <TableRow>
      <TableCell>{articleName}</TableCell>
      {counts.map((count) => (
        <TableCell>{count}</TableCell>
      ))}
    </TableRow>
  );
};

const PlanningTable = ({ counts }: IProps): JSX.Element => {
  const tableData = createTableData(counts);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {counts.map((count) => (
              <TableCell key={`tableheaditem-${count.date}`}>
                {new Date(count.date).toLocaleDateString('de')}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(tableData).map((articleName) => (
            <PlanningTableRow
              counts={tableData[articleName]}
              articleName={articleName}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlanningTable;
