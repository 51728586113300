/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';

interface IProps {
  handleStartDateChange(newDate: DateTime): void;
}

const WeekPicker = ({ handleStartDateChange }: IProps): JSX.Element => {
  const [startDate, setStartDate] = React.useState<DateTime | null>();

  return (
    <Box py={3} marginBottom={1} component={Paper}>
      <Grid container direction="column" alignItems="center">
        <Typography align="center" variant="body1" color="primary" gutterBottom>
          Wählen Sie hier den Zeitraum aus:
        </Typography>
        <Grid item>
          <DatePicker
            label="Von"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
              if (newValue) {
                handleStartDateChange(newValue);
              }
            }}
            renderInput={(props) => <TextField variant="outlined" {...props} />}
          />
          <DatePicker
            label="Bis"
            disabled
            value={startDate?.plus({ days: 6 })}
            onChange={() => {}}
            renderInput={(props) => (
              <TextField
                style={{ marginLeft: '5px' }}
                variant="outlined"
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WeekPicker;
