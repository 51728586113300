import React from 'react';

interface IProps<T> {
  value: T;
  debounceTime: number;
}

function useDebounce<T>({ value, debounceTime }: IProps<T>): T {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const dTime = debounceTime || 200;

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, dTime);

    return () => {
      clearTimeout(handler);
    };
  }, [value, dTime]);

  return debouncedValue;
}

export default useDebounce;
