import React from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import useNotificationActions from '../../hooks/useNotificationActions';
import { useTrackedState } from '../../store/Notification';

const NotificationBar = (): JSX.Element => {
  const { open, severity, title, msg } = useTrackedState();
  const { hide } = useNotificationActions();

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={hide}
      message={msg}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Alert
        elevation={6}
        severity={severity}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={hide}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        <AlertTitle>{title}</AlertTitle>
        {msg}
      </Alert>
    </Snackbar>
  );
};

export default NotificationBar;
