import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useHistory } from 'react-router-dom';

import { useTrackedState } from '../../store/Cart';
import useStyles from './AppOrder.css';
import { calculateTotalPrice } from '../../util/util';
import routes from '../router/routes';

const CardBanner = (): JSX.Element => {
  const { items } = useTrackedState();
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card className={classes.shoppingCartBanner} elevation={20}>
      <CardActionArea
        disabled={items.length < 1}
        className={classes.shoppingActions}
        onClick={() => history.push(routes.app.checkout)}
      >
        <CardContent>
          <Grid container>
            <Grid item xs>
              <Typography
                color="inherit"
                variant="button"
                className={classes.bannerText}
              >
                <ShoppingCartIcon
                  style={{ marginBottom: '-5px', marginRight: '5px' }}
                />
                jetzt bestellen
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.bannerPrice} variant="button">
                € {calculateTotalPrice(items)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardBanner;
