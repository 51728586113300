import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  navContainer: {
    display: 'flex',
  },
  contentContainer: {
    backgroundColor: theme.palette.background.default,
    padding: '10px',
    minHeight: '90vh',
    flexGrow: 1,
    paddingTop: '300px',
    '@media print': {
      paddingTop: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '10px',
    },
  },
  drawer: {
    // display: 'none',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      // display: 'flex',
      width: '220px',
    },
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  drawerContent: {
    marginTop: '80px',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      width: '220px',
    },
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
    overflow: 'auto',
  },
}));

export default useStyles;
