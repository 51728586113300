import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IOrder } from '../../api/Order';
import OrderTable from '../orderTable/OrderTable';
import useStyles from './AppOrderManagement.css';

interface IProps {
  orders: IOrder[];
  group: string;
  length: number;
  expandLvl1: boolean;
  expandLvl2: boolean;
}

const OrderAccodion = ({
  orders,
  group,
  length,
  expandLvl1,
  expandLvl2,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(expandLvl1);
  React.useEffect(() => {
    setExpanded((prev) => {
      if (prev !== expandLvl1) {
        return expandLvl1;
      }
      return prev;
    });
  }, [expandLvl1]);
  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.accordionHeading}>{group}</Typography>
        <Typography className={classes.accordionSecondaryHeading}>
          Anzahl Gerichte: {length}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <OrderTable orders={orders} expandLvl2={expandLvl2} />
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderAccodion;
