/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from '@aws-amplify/auth';

export const getCurrentUser = (): Promise<any> =>
  Auth.currentAuthenticatedUser();

export const signin = (email: string, password: string): Promise<any> =>
  Auth.signIn(email, password);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const completeNewPw = (user: any, newPw: string): Promise<any> => {
  return Auth.completeNewPassword(user, newPw);
};

export const sendConfirmationCode = (email: string): Promise<any> => {
  return Auth.forgotPassword(email);
};

export const confirmPwReset = (
  mail: string,
  code: string,
  newPw: string
): Promise<any> => {
  return Auth.forgotPasswordSubmit(mail, code, newPw);
};

export const signOut = (): Promise<any> => Auth.signOut();
