import React from 'react';
import { CreateCompanyInput, UpdateCompanyInput } from '../../api/API';

import {
  createCompany,
  getAllCompanies,
  getCompanyByCompanyId,
  ICompany,
  updateCompany,
} from '../../api/Company';
import useNotificationActions from '../../hooks/useNotificationActions';
import { useDispatch } from '../../store/Companies';

interface IReturn {
  fetch: () => Promise<void>;
  fetchSingle: (companyId: string) => Promise<void>;
  add: (input: CreateCompanyInput) => Promise<void>;
  update: (input: UpdateCompanyInput, single?: boolean) => Promise<void>;
}

const useCompanyActions = (): IReturn => {
  const dispatch = useDispatch();
  const { show } = useNotificationActions();

  const fetch = React.useCallback(async () => {
    dispatch({ type: 'startLoading' });
    try {
      const res = await getAllCompanies();
      // set errors
      if (res.errors || res.data?.getAllCompanies?.error) {
        dispatch({
          type: 'setError',
          error: 'Unternehmen konnten nicht geladen werden',
        });
      } else if (res.data?.getAllCompanies?.data) {
        const newComps = res.data?.getAllCompanies?.data as ICompany[];
        newComps.sort((a, b) => (a.companyName > b.companyName ? 1 : -1));
        dispatch({ type: 'setCompanies', companies: newComps });
      }
    } catch (err) {
      dispatch({
        type: 'setError',
        error: 'Unternehmen konnten nicht geladen werden',
      });
    }
  }, [dispatch]);

  const fetchSingle = React.useCallback(
    async (companyId: string) => {
      dispatch({ type: 'startLoading' });
      try {
        const res = await getCompanyByCompanyId({ id: companyId });
        // set errors
        if (res.errors || res.data?.getCompanyById?.error) {
          dispatch({
            type: 'setError',
            error: 'Unternehmen konnten nicht geladen werden',
          });
        } else if (res.data?.getCompanyById?.data) {
          const newComp = res.data?.getCompanyById?.data as ICompany;
          dispatch({ type: 'setCompanies', companies: [newComp] });
        }
      } catch (err) {
        dispatch({
          type: 'setError',
          error: 'Unternehmen konnte nicht geladen werden',
        });
      }
    },
    [dispatch]
  );

  const add = async (input: CreateCompanyInput) => {
    try {
      const res = await createCompany(input);
      if (res.data?.createCompany?.error) {
        show('Error', 'Unternehmen konnte nicht erstellt werden', 'error');
      } else if (res.data?.createCompany?.data) {
        show('Gespeichert', `${input.companyName} wurde erstellt`, 'success');
        fetch();
      }
    } catch (error) {
      show('Error', 'Unternehmen konnte nicht erstellt werden', 'error');
    }
  };

  const update = async (input: UpdateCompanyInput, single?: boolean) => {
    try {
      const res = await updateCompany(input);
      if (res.data?.updateCompany?.error) {
        show('Error', 'Unternehmen konnte aktualisiert werden', 'error');
      } else if (res.data?.updateCompany?.data) {
        if (single) {
          fetchSingle(input.id);
        } else {
          fetch();
        }
        show(
          'Gespeichert',
          `${input.companyName} wurde aktualisiert`,
          'success'
        );
      }
    } catch (error) {
      show('Error', 'Unternehmen konnte nicht aktualisiert werden', 'error');
    }
  };

  return {
    fetch,
    fetchSingle,
    add,
    update,
  };
};

export default useCompanyActions;
