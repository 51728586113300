import React from 'react';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useStyles from './AppMenuManagement.css';
import SetMenuDialog from './SetMenuDialog';
import { IMenu } from '../../api/Menu';
import { getMenuTypeLabel } from '../../util/translate';
import { RoleEnum, useTrackedState } from '../../store/Auth';

interface IProps {
  menu: IMenu;
}

const MenuTypeSection = ({ menu }: IProps): JSX.Element => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { role } = useTrackedState();

  return (
    <>
      <Typography
        variant="body2"
        align="center"
        color="primary"
        className={classes.menuTypeLabel}
      >
        {getMenuTypeLabel(menu.menuType)}
      </Typography>
      <Typography variant="body2" align="center">
        {menu.description && `${menu.description} (${menu.allergens})`}
      </Typography>
      {role === RoleEnum.ADMIN && (
        <Box displayPrint="none">
          <Button
            startIcon={<EditIcon />}
            fullWidth
            size="small"
            onClick={() => setDialogOpen(true)}
          >
            Bearbeiten
          </Button>
          <Divider variant="middle" />
          <SetMenuDialog
            menu={menu}
            open={dialogOpen}
            close={() => setDialogOpen(false)}
          />
        </Box>
      )}
    </>
  );
};

export default MenuTypeSection;
