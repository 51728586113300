import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '80vh',
    marginTop: '10%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
}));

export default useStyles;
