import { GraphQLResult } from '@aws-amplify/api';

import {
  GetThermoboxCountsQuery,
  ChangeThermoboxCountInput,
  ReturnThermoboxMutation,
  AddThermoboxMutation,
} from './API';
import callGraphQL from './GraphQLOperation';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

export interface IThermoBoxCount {
  ownerId: string;
  ownerName: string;
  quantity: number;
  email?: string;
  phone?: string;
}

export const getThermoboxCounts = (): Promise<
  GraphQLResult<GetThermoboxCountsQuery>
> => {
  return callGraphQL<GetThermoboxCountsQuery>(queries.getThermoboxCounts);
};

export const returnThermoboxes = (
  input: ChangeThermoboxCountInput
): Promise<GraphQLResult<ReturnThermoboxMutation>> => {
  return callGraphQL<ReturnThermoboxMutation>(mutations.returnThermobox, {
    variables: { input },
  });
};

export const addThermoboxes = (
  input: ChangeThermoboxCountInput
): Promise<GraphQLResult<AddThermoboxMutation>> => {
  return callGraphQL<AddThermoboxMutation>(mutations.addThermobox, {
    variables: { input },
  });
};
