import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { Amplify } from '@aws-amplify/core';

import Router from '../router/Router';
import awsConfig from '../../static/config/awsConfig';
import { useTrackedState as useAuthState } from '../../store/Auth';
import useArticleActions from '../appArticleManagement/useArticleActions';

Amplify.configure({
  Auth: {
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    mandatorySignIn: false,
  },
  aws_appsync_graphqlEndpoint: awsConfig.appSync.URL,
  aws_appsync_region: awsConfig.appSync.REGION,
  aws_appsync_authenticationType: awsConfig.appSync.AUTHENTIACTION_TYPE,
});

const Root: React.FC = () => {
  const { user } = useAuthState();
  const { fetchActive } = useArticleActions();

  React.useEffect(() => {
    fetchActive(user === 'GAST');
  }, [fetchActive, user]);

  return (
    <>
      <CssBaseline />
      <Router />
    </>
  );
};

export default Root;
