import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.contrastText,
    // color: theme.palette.primary.main,
    backgroundColor: 'rgba(13, 103, 27, 0.95)',
  },
  menuLink: {
    color: theme.palette.primary.contrastText,
    paddingBottom: '5px',
    textDecoration: 'none',
    // borderBottom: `2px solid rgba(232, 241, 242, 0.95)`,
    borderBottom: `2px solid transparent`,
    transition: '0.5s',
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
    },
  },
}));

export default useStyles;
