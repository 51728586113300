import React from 'react';
import { createContainer } from 'react-tracked';

interface IImageObject {
  [key: string]: string;
}

interface IImageCacheState {
  images: IImageObject;
}

type CacheAction = { type: 'addImage'; payload: { key: string; url: string } };

const initialState: IImageCacheState = {
  images: {},
};

const imageCacheReducer = (
  state: IImageCacheState,
  action: CacheAction
): IImageCacheState => {
  switch (action.type) {
    case 'addImage':
      // eslint-disable-next-line no-case-declarations
      return {
        ...state,
        images: {
          ...state.images,
          [action.payload.key]: action.payload.url,
        },
      };

    default:
      return state;
  }
};

const useValue = () => React.useReducer(imageCacheReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
