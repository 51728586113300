export interface IRouteConfig {
  app: {
    order: string;
    checkout: string;
    orderOverview: string;
    userManagement: string;
    articleManagement: string;
    menuManagement: string;
    reporting: string;
    boxManagement: string;
    weeklyManagement: string;
    companyReport: string;
  };
  home: string;
  gastro: string;
  contact: string;
  menu: string;
  news: string;
  interviews: string;
  imprint: string;
  gdpr: string;
  postPayment: string;
  philosophy: string;
}

const routes: IRouteConfig = {
  app: {
    order: '/app/order',
    checkout: '/app/checkout',
    orderOverview: '/app/orders',
    userManagement: '/app/users',
    articleManagement: '/app/articles',
    menuManagement: '/app/menu',
    reporting: '/app/reporting',
    boxManagement: '/app/box',
    weeklyManagement: '/app/weekly',
    companyReport: '/app/companyreport',
  },
  home: '/',
  gastro: '/gastro',
  contact: '/kontakt',
  menu: '/menu',
  news: '/news',
  interviews: '/interviews',
  imprint: '/impressum',
  gdpr: '/datenschutz',
  postPayment: '/checkout',
  philosophy: '/philosophie',
};

export default routes;
