import React from 'react';
import { createContainer } from 'react-tracked';

import { IOrder } from '../api/Order';

interface IOrdersState {
  orders: IOrder[];
  loading: boolean;
  error: string;
}

type OrderAction =
  | { type: 'startLoading' }
  | { type: 'setError'; error: string }
  | { type: 'setOrders'; orders: IOrder[] }
  | { type: 'addOrder'; newOrder: IOrder }
  | { type: 'deleteOrder'; orderId: string };

const initialState: IOrdersState = {
  orders: [],
  loading: false,
  error: '',
};

const orderReducer = (
  state: IOrdersState,
  action: OrderAction
): IOrdersState => {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        loading: true,
      };

    case 'setError':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'setOrders':
      return {
        ...state,
        loading: false,
        error: '',
        orders: action.orders,
      };

    case 'addOrder':
      return {
        ...state,
        loading: false,
        orders: [...state.orders, action.newOrder],
      };

    case 'deleteOrder':
      return {
        ...state,
        loading: false,
        orders: state.orders.filter((order) => order.id !== action.orderId),
      };

    default:
      return state;
  }
};

const useValue = () => React.useReducer(orderReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
