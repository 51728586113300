import React from 'react';
import {
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import useStyles from './LoginForm.css';
import { useTrackedState } from '../../store/Auth';
import useAuthActions from '../../hooks/useAuthActions';
import GdprDialog from '../GdprDialog/GdprDialog';
import useGdprConsent from '../../hooks/useGdprConsent';

const LoginForm = (): JSX.Element => {
  const classes = useStyles();
  const { isAuthenticating } = useTrackedState();
  const { signIn, forgotPassword } = useAuthActions();
  const [email, setEmail] = React.useState('');
  const [pwd, setPwd] = React.useState('');
  const [gdprDialogOpen, setGdprDialogOpen] = React.useState(false);
  const { getConsent, confirm } = useGdprConsent();

  const closeGdprDialog = React.useCallback(() => {
    setGdprDialogOpen((prev) => !prev);
  }, []);

  const login = async () => {
    if (!getConsent()) {
      setGdprDialogOpen(true);
    } else {
      signIn(email, pwd);
    }
  };

  // const handleGuestModeClick = React.useCallback(() => {
  //   if (!getConsent()) {
  //     setGdprDialogOpen(true);
  //   } else {
  //     activateGuestMode();
  //   }
  // }, [activateGuestMode, getConsent]);

  const handleConfirm = React.useCallback(() => {
    confirm();
    setGdprDialogOpen(false);
  }, [confirm]);

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Anmelden
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          disabled={isAuthenticating}
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          disabled={isAuthenticating}
          name="password"
          label="Passwort"
          type="password"
          value={pwd}
          onChange={(ev) => setPwd(ev.target.value)}
          id="password"
          autoComplete="current-password"
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isAuthenticating || !email || !pwd}
          onClick={login}
        >
          {isAuthenticating ? <CircularProgress size={25} /> : 'Anmelden'}
        </Button>
        {/*
        <TextDivider>
          <Typography variant="overline">oder:</Typography>
        </TextDivider>
         <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleGuestModeClick}
          className={classes.guestButton}
          disabled={isAuthenticating}
        >
          Als Gast bestellen (SELBSTABHOLUNG)
        </Button> */}
        <Grid container className={classes.altButtons}>
          <Grid item xs>
            <Button
              disabled={isAuthenticating}
              color="secondary"
              size="small"
              variant="text"
              onClick={forgotPassword}
            >
              Passwort vergessen?
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isAuthenticating}
              color="secondary"
              size="small"
              variant="text"
              href="/kontakt"
            >
              Sie haben keinen Account?
            </Button>
          </Grid>
        </Grid>
      </form>
      <GdprDialog
        open={gdprDialogOpen}
        close={closeGdprDialog}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

export default LoginForm;
