import React from 'react';

interface IReturn {
  flag: boolean;
}

const usePolling = (timeout: number): IReturn => {
  const [flag, setFlag] = React.useState(false);

  const poll = React.useCallback(() => {
    setTimeout(() => {
      setFlag((prev) => !prev);
      poll();
    }, timeout);
  }, [timeout]);

  React.useEffect(() => {
    poll();
  }, [poll]);

  return { flag };
};

export default usePolling;
