import { GraphQLResult } from '@aws-amplify/api';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import {
  CreateUserInput,
  CreateUserMutation,
  DeleteUserInput,
  DeleteUserMutation,
  GetUserByEmailInput,
  GetUserByEmailQuery,
  GetUsersByCompanyIdInput,
  GetUsersByCompanyIdQuery,
  UpdateUserInput,
  UpdateUserMutation,
} from './API';
import callGraphQL from './GraphQLOperation';

export interface IUser {
  companyId: string;
  createdAt: string;
  email: string;
  hasReadAgb: boolean;
  id: string;
  isCompanyAdmin: boolean;
  mobileNumber: string;
  name: string;
  surname: string;
  address: string;
  zip: string;
  newsletter: boolean;
  canOrderOnBill?: boolean;
}

export const addUser = (
  input: CreateUserInput
): Promise<GraphQLResult<CreateUserMutation>> => {
  return callGraphQL<CreateUserMutation>(mutations.createUser, {
    variables: { input },
  });
};

export const getUserByEmail = (
  input: GetUserByEmailInput
): Promise<GraphQLResult<GetUserByEmailQuery>> => {
  return callGraphQL<GetUserByEmailQuery>(queries.getUserByEmail, {
    variables: { input },
  });
};

export const getUsersByCompanyId = (
  input: GetUsersByCompanyIdInput
): Promise<GraphQLResult<GetUsersByCompanyIdQuery>> => {
  return callGraphQL<GetUsersByCompanyIdQuery>(queries.getUsersByCompanyId, {
    variables: { input },
  });
};

export const deleteUser = (
  input: DeleteUserInput
): Promise<GraphQLResult<DeleteUserMutation>> => {
  return callGraphQL<DeleteUserMutation>(mutations.deleteUser, {
    variables: { input },
  });
};

export const updateUser = (
  input: UpdateUserInput
): Promise<GraphQLResult<UpdateUserMutation>> => {
  return callGraphQL<UpdateUserMutation>(mutations.updateUser, {
    variables: { input },
  });
};
