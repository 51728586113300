import React from 'react';
import { createContainer } from 'react-tracked';

import { IUser } from '../api/User';

interface IUsersState {
  users: IUser[];
  loading: boolean;
  error: string;
}

type UsersAction =
  | { type: 'startLoading' }
  | { type: 'setError'; error: string }
  | { type: 'setUsers'; users: IUser[] }
  | { type: 'deleteUser'; userIdToDelete: string }
  | { type: 'updateUser'; newUser: IUser };

const initialState: IUsersState = {
  users: [],
  loading: false,
  error: '',
};

const companyReducer = (
  state: IUsersState,
  action: UsersAction
): IUsersState => {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        loading: true,
      };

    case 'setError':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'setUsers':
      return {
        ...state,
        loading: false,
        error: '',
        users: action.users,
      };

    case 'deleteUser': {
      return {
        ...state,
        loading: false,
        users: state.users.filter((item) => item.id !== action.userIdToDelete),
      };
    }

    case 'updateUser':
      return {
        ...state,
        loading: false,
        users: state.users.map((item) =>
          item.id === action.newUser.id ? action.newUser : item
        ),
      };

    default:
      return state;
  }
};

const useValue = () => React.useReducer(companyReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
