import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Divider,
  AccordionActions,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ICompany } from '../../api/Company';
import CompanyUserList from './CompanyUserList';
import CompanyDetailsList from './CompanyDetailsList';
import UpdateCompanyDialog from './UpdateCompanyDialog';
import useStyles from './AppUserManagement.css';
import AddUserDialog from './AddUserDialog';
import useUserActions from './useUserActions';

interface IProps {
  company: ICompany;
  single: boolean;
}

const CompanyAccordion = ({ company, single }: IProps): JSX.Element => {
  const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);
  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);
  const { add } = useUserActions(company.id);
  const classes = useStyles();

  return (
    <Accordion
      key={`company-list-${company.id}`}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={single}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">{company.companyName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} md={4}>
            <CompanyDetailsList company={company} />
          </Grid>
          <Grid item xs={12} md={8} className={classes.companyUserList}>
            <CompanyUserList companyId={company.id} />
          </Grid>
        </Grid>
        <UpdateCompanyDialog
          open={updateDialogOpen}
          close={() => setUpdateDialogOpen(false)}
          input={company}
          single={single}
        />
        <AddUserDialog
          open={addUserDialogOpen}
          close={() => setAddUserDialogOpen(false)}
          companyId={company.id}
          save={add}
        />
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button
          size="small"
          color="primary"
          onClick={() => setAddUserDialogOpen(true)}
        >
          User hinzufügen
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => setUpdateDialogOpen(true)}
        >
          Bearbeiten
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default CompanyAccordion;
