import React from 'react';
import { Backdrop, List, ListItem, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import useStyles from './FullScreenMenu.css';

interface IProps {
  isOpen: boolean;
  toggle(): void;
}

export const menuItems = [
  {
    name: 'Bestellen',
    route: '/',
  },
  {
    name: 'Menüplan',
    route: '/menu',
  },
  {
    name: 'News',
    route: '/news',
  },
  {
    name: 'Gastro',
    route: '/gastro',
  },
  {
    name: 'Interviews',
    route: '/interviews',
  },
  {
    name: 'Küchenphilosophie',
    route: '/philosophie',
  },
  {
    name: 'Kontakt',
    route: '/kontakt',
  },
];

const FullScreenMenu = ({ isOpen, toggle }: IProps): JSX.Element => {
  const classes = useStyles();

  const MenuItem = ({ name, route }: { name: string; route: string }) => {
    return (
      <ListItem>
        <Link to={route}>
          <Typography className={classes.menuLink} align="center" variant="h4">
            {name}
          </Typography>
        </Link>
      </ListItem>
    );
  };

  return (
    <Backdrop className={classes.root} open={isOpen} onClick={toggle}>
      <List dense>
        {menuItems.map((item) => {
          return (
            <MenuItem
              key={`fullscreenmenu-${item.name}`}
              name={item.name}
              route={item.route}
            />
          );
        })}
      </List>
    </Backdrop>
  );
};

export default FullScreenMenu;
