import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import {
  CreateOrderInput,
  CreateOrderMutation,
  CreateBatchOrderMutation,
  GetOrdersByDeliveryDateInput,
  OrderStateEnum,
  OrderTypeEnum,
  GetOrdersByDeliveryDateQuery,
  GetOrdersByDateRangeAndCompanyIdInput,
  GetOrdersByDateRangeAndCompanyIdQuery,
  DeleteOrderInput,
  DeleteOrderMutation,
  GetOrderArticleCountByDateRangeInput,
  GetOrderArticleCountByDateRangeQuery,
  GetOrdersByDateRangeInput,
  GetOrdersByDateRangeQuery,
} from './API';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import callGraphQL from './GraphQLOperation';
import { IArticle } from './Article';

export interface IOrder {
  id: string;
  orderState: OrderStateEnum;
  isBatchOrder: boolean;
  companyId: string;
  userId: string;
  companyName: string;
  userName: string;
  tip: number;
  orderType: OrderTypeEnum;
  orderDate: string;
  deliveryDate: string;
  tableId: string;
  discountArticleId: string;
  thermoBoxes: number;
  comment: string;
  discount: number;
  paymentId: string;
  name: string;
  surname: string;
  address: string;
  zip: string;
  phoneNumber: string;
  articles: IArticle[];
  invoiceUrl: string | null;
}

export interface IOrderArticleCount {
  count: number;
  articleName: string;
}

export interface IOrderArticleCounts {
  date: string;
  counts: IOrderArticleCount[];
}

export const createOrder = (
  input: CreateOrderInput,
  isGuest?: boolean
): Promise<GraphQLResult<CreateOrderMutation>> => {
  return callGraphQL<CreateOrderMutation>(mutations.createOrder, {
    variables: { input },
    authMode: isGuest
      ? GRAPHQL_AUTH_MODE.AWS_IAM
      : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
};

export const createBatchOrder = (
  input: CreateOrderInput
): Promise<GraphQLResult<CreateBatchOrderMutation>> => {
  return callGraphQL<CreateBatchOrderMutation>(mutations.createBatchOrder, {
    variables: { input },
  });
};

export const getOrdersByDeliveryDate = (
  input: GetOrdersByDeliveryDateInput
): Promise<GraphQLResult<GetOrdersByDeliveryDateQuery>> => {
  return callGraphQL<GetOrdersByDeliveryDateQuery>(
    queries.getOrdersByDeliveryDate,
    {
      variables: { input },
    }
  );
};

export const getOrdersByDateRangeAndCompanyId = (
  input: GetOrdersByDateRangeAndCompanyIdInput
): Promise<GraphQLResult<GetOrdersByDateRangeAndCompanyIdQuery>> => {
  return callGraphQL<GetOrdersByDateRangeAndCompanyIdQuery>(
    queries.getOrdersByDateRangeAndCompanyId,
    {
      variables: { input },
    }
  );
};

export const getOrdersByDateRange = (
  input: GetOrdersByDateRangeInput
): Promise<GraphQLResult<GetOrdersByDateRangeQuery>> => {
  return callGraphQL<GetOrdersByDateRangeQuery>(queries.getOrdersByDateRange, {
    variables: { input },
  });
};

export const deleteOrder = (
  input: DeleteOrderInput
): Promise<GraphQLResult<DeleteOrderMutation>> => {
  return callGraphQL<DeleteOrderMutation>(mutations.deleteOrder, {
    variables: { input },
  });
};

export const getOrderArticleCountByDateRange = (
  input: GetOrderArticleCountByDateRangeInput
): Promise<GraphQLResult<GetOrderArticleCountByDateRangeQuery>> => {
  return callGraphQL<GetOrderArticleCountByDateRangeQuery>(
    queries.getOrderArticleCountByDateRange,
    {
      variables: { input },
    }
  );
};
