import React from 'react';
import { Fab } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv';

import { getCsvDataFormatFromOrders } from '../../util/csvParser';
import { IOrder } from '../../api/Order';
import useStyles from './AppOrderManagement.css';
import { ICompany } from '../../api/Company';

interface IProps {
  orders: IOrder[];
  // eslint-disable-next-line react/require-default-props
  companies?: ICompany[];
}

const DownloadButton = ({ orders, companies }: IProps): JSX.Element => {
  const classes = useStyles();
  const csvData = getCsvDataFormatFromOrders(orders, companies);
  const csvFileName = `export-${new Date().toISOString()}.csv`;

  return (
    <Fab
      disabled={orders.length < 1}
      variant="extended"
      color="primary"
      className={classes.downloadButton}
    >
      <GetAppIcon style={{ marginRight: '5px' }} />
      <CSVLink
        filename={csvFileName}
        data={csvData}
        separator=";"
        style={{ color: 'white' }}
      >
        .csv Download
      </CSVLink>
    </Fab>
  );
};

export default DownloadButton;
