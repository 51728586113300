import React from 'react';

import {
  getOrderArticleCountByDateRange,
  IOrderArticleCounts,
} from '../api/Order';

interface IReturn {
  data: IOrderArticleCounts[];
  loading: boolean;
  error: string;
}

const useOrderArticleCountByDateRange = (from: string, to: string): IReturn => {
  const [data, setData] = React.useState<IOrderArticleCounts[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        // const start = from.startOf('day').toString();
        // const end = to.endOf('day').toString();
        const res = await getOrderArticleCountByDateRange({
          from,
          to,
        });
        if (res.data?.getOrderArticleCountByDateRange?.data) {
          setError('');
          setData(res.data?.getOrderArticleCountByDateRange?.data);
        } else if (res.data?.getOrderArticleCountByDateRange?.error) {
          setData([]);
          setError(res.data?.getOrderArticleCountByDateRange?.error.message);
        }
      } catch (err) {
        setError('Daten konnten nicht geladen werden');
      }
      setLoading(false);
    };
    fetch();
  }, [from, to]);

  return {
    data,
    loading,
    error,
  };
};

export default useOrderArticleCountByDateRange;
