import React from 'react';
import {
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';

import useStyles from './ForgotPasswordForm.css';
import { useTrackedState } from '../../store/Auth';
import useAuthActions from '../../hooks/useAuthActions';

const ForgotPasswordForm = (): JSX.Element => {
  const classes = useStyles();
  const { isAuthenticating } = useTrackedState();
  const { sendConfirmationCode, resetAuthState } = useAuthActions();
  const [email, setEmail] = React.useState('');

  const sendConfCode = async () => {
    sendConfirmationCode(email);
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <UpdateIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Passwort zurücksetzen
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="email"
          label="Email"
          type="email"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          id="email"
          autoComplete="email"
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isAuthenticating || !email}
          onClick={sendConfCode}
        >
          {isAuthenticating ? (
            <CircularProgress size={25} />
          ) : (
            'Passwort zurücksetzen'
          )}
        </Button>
        <Grid container className={classes.altButtons}>
          <Grid item xs>
            <Button
              disabled={isAuthenticating}
              color="secondary"
              size="small"
              variant="text"
              onClick={resetAuthState}
            >
              Abbrechen
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
