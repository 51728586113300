import { IArticle, IModifier } from '../api/Article';
import { IArticleGroup } from '../components/appOrder/types';

interface IHasId {
  id: string;
}

export const getTotalPrice = (gross: number): number => {
  const total = gross / 100;
  return Math.round(total * 100) / 100;
};

export const removeDuplicates = <T extends IHasId>(items: T[]): T[] => {
  const ids = items.map((item) => item.id);
  const filtered = items.filter(
    (item, index) => !ids.includes(item.id, index + 1)
  );
  return filtered;
};

export const getSingleArticlePrice = (gross: number): number => {
  const price = gross / 100.0;
  return Math.round(price * 100) / 100;
};

export const groupArticlesByGroupName = (
  articles: IArticle[]
): IArticleGroup[] => {
  const groups: IArticleGroup[] = [];
  articles.forEach((article) => {
    const index = groups.findIndex(
      (group) => group.groupname === article.articleGroupName
    );
    if (index === -1) {
      groups.push({
        groupname: article.articleGroupName,
        articles: [article],
      });
    } else {
      groups[index].articles.push(article);
    }
  });

  // sort by group name
  groups.sort((a, b) => {
    const aName = a.groupname.toUpperCase();
    const bName = b.groupname.toUpperCase();
    const extras = 'EXTRAS';
    if (aName === extras && bName === extras) {
      return aName < bName ? -1 : 1;
    }

    if (aName === extras) {
      return 1;
    }

    if (bName === extras) {
      return -1;
    }

    return aName < bName ? -1 : 1;
  });

  return groups;
};

export const getSingleArticleModifiersPrice = (
  modifiers: IModifier[]
): number => {
  let price = 0.0;
  modifiers.forEach((mod) => {
    price += mod.grossPrice / 100.0;
  });
  return Math.round(price * 100) / 100;
};

export const getSingleArticlePriceWithModifiers = (
  article: IArticle
): number => {
  let price = getSingleArticlePrice(article.grossPrice);
  price += getSingleArticleModifiersPrice(article.modifiers);
  return Math.round(price * 100) / 100;
};

export const calculateTotalPrice = (
  articles: IArticle[],
  discount?: number,
  tip?: number
): number => {
  let totalPrice = 0;
  articles.forEach((article) => {
    const price = getSingleArticlePriceWithModifiers(article);
    totalPrice += price;
  });
  let total = Math.round(totalPrice * 100) / 100;

  if (discount) {
    total -= discount / 100;
  }

  if (tip) {
    total += tip / 100;
  }

  const dispalyPrice = Math.round(total * 100) / 100;
  return dispalyPrice > 0 ? dispalyPrice : 0;
};

export const getMenuCountFromItems = (items: IArticle[]): number => {
  let count = 0;
  items.forEach((item) => {
    if (item.hasThermobox) {
      count += 1;
    }
  });

  return count;
};
