import React from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Chip,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { IArticle } from '../../api/Article';
import ArticleDetailsPanel from './ArticleDetailsPanel';
import useStyles from './AppArticleManagement.css';

interface IProps {
  article: IArticle;
}

const ArticleRow = ({ article }: IProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <TableRow className={classes.rowRoot}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={article.isDeprecated}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {article.name}
          {article.isDeprecated && (
            <Chip
              color="default"
              label="entfernt"
              size="small"
              style={{ marginLeft: '5px' }}
            />
          )}
          {article.isLocked && (
            <Chip
              color="default"
              label="Tagessperre"
              size="small"
              style={{ marginLeft: '5px' }}
            />
          )}
        </TableCell>
        <TableCell align="right">{article.grossPrice / 100}</TableCell>
        <TableCell align="right">
          {((article.grossPrice / 100) * article.tax) / 10000}
        </TableCell>
        <TableCell align="right">{article.allergens}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ArticleDetailsPanel article={article} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ArticleRow;
