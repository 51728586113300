import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { deDE } from '@material-ui/core/locale';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        // main: '#53A548',
        main: '#0D671B',
      },
      secondary: {
        main: '#0D671B',
        // main: '#246A73',
        // main: '#9E9B40',
        // main: '#0D4A81',
      },
    },
  },
  deDE
);

export default responsiveFontSizes(theme);
