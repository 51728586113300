import React from 'react';

import NotificationBar from '../notificationBar/NotificationBar';
import routes from '../router/routes';
import ScrollToTopButton from '../scrollToTopButton/ScrollToTopButton';
import TopBar from '../topBar/TopBar';

interface IProps {
  children: React.ReactNode;
}

const Layout = ({ children }: IProps): JSX.Element => (
  <>
    <TopBar routes={routes} />
    <NotificationBar />
    <ScrollToTopButton />
    {children}
  </>
);

export default Layout;
