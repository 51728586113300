import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.getContrastText(theme.palette.grey[600]),
    // width: '100%',
    padding: '20px',
    zIndex: 1201,
  },
  logoArea: {
    // backgroundImage: `url(${Logo})`,
    // border: '1px solid red',
    display: 'flex',
    padding: '10px',
  },
  copyRightArea: {
    display: 'flex',
  },
  listItem: {
    textAlign: 'center',
  },
}));

export default useStyles;
