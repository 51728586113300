import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from '@material-ui/core';

import { IArticle, IModifier } from '../../api/Article';
import useCartActions from './useCartActions';
import { getSingleArticlePriceWithModifiers } from '../../util/util';

interface IProps {
  item: IArticle;
  open: boolean;
  close(): void;
  imageUrl: string;
  addDisabled: boolean;
}

const AddItemDialog = ({
  item,
  open,
  close,
  imageUrl,
  addDisabled = false,
}: IProps): JSX.Element => {
  const initialItem = { ...item, modifiers: [] };
  const [newItem, setNewItem] = React.useState<IArticle>(initialItem);
  const { addItem } = useCartActions();
  const theme = useTheme();

  const handleToggleModifier = (modifier: IModifier) => {
    const modIndex = newItem.modifiers.findIndex(
      (mod) => mod.id === modifier.id
    );
    if (modIndex < 0) {
      setNewItem({
        ...newItem,
        modifiers: [...newItem.modifiers, modifier],
      });
    } else {
      const cloned = [...newItem.modifiers];
      cloned.splice(modIndex, 1);
      setNewItem({
        ...newItem,
        modifiers: cloned,
      });
    }
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="lg">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <img src={imageUrl} alt={item.name} />
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: '30px' }}>
            <Typography variant="h4" align="center" gutterBottom>
              {item.name}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              color="textSecondary"
              gutterBottom
            >
              {item.description}
            </Typography>
            <Divider />
            <Typography
              variant="body2"
              align="left"
              color="textSecondary"
              gutterBottom
            >
              {item.allergens
                ? `Allergene: ${item.allergens}`
                : 'keine Allergene angegeben'}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              color="textSecondary"
              gutterBottom
            >
              {item.hasThermobox && (
                <>
                  Artikel wird in einer <strong>Thermobox</strong> geliefert
                </>
              )}
            </Typography>
            {item.modifiers.length > 0 && (
              <div
                style={{
                  margin: '10px',
                  padding: '15px',
                  border: `1px solid ${theme.palette.primary.light}`,
                  borderRadius: '2px',
                }}
              >
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                  Extras hinzufügen:
                </Typography>
                <List dense>
                  {item.modifiers.map((modifier) => (
                    <ListItem key={`${item.id}-modifier${modifier.modifierId}`}>
                      <ListItemText
                        primary={modifier.name}
                        secondary={`+ €${modifier.grossPrice / 100.0}`}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggleModifier(modifier)}
                          checked={
                            newItem.modifiers.findIndex(
                              (mod) => mod.id === modifier.id
                            ) !== -1
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            <Divider />
            <Typography variant="h6" align="right" color="primary">
              <strong>
                Preis: € {getSingleArticlePriceWithModifiers(newItem)}
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setNewItem(initialItem);
            close();
          }}
          color="secondary"
        >
          Abbrechen
        </Button>
        <Button
          disabled={addDisabled}
          color="primary"
          onClick={() => {
            addItem(newItem);
            setNewItem(initialItem);
            close();
          }}
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemDialog;
