/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const triggerArticleSync = /* GraphQL */ `
  mutation TriggerArticleSync {
    triggerArticleSync {
      data
      error {
        message
      }
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      data {
        id
        companyName
        address
        zip
        mobileNumber
        contactPerson
        contactMail
        discount
        tableId
        discountArticleId
        employeeOrderLimit
        orderSortTime
        comment
      }
      error {
        message
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      data {
        id
        companyName
        address
        zip
        mobileNumber
        contactPerson
        contactMail
        discount
        tableId
        discountArticleId
        employeeOrderLimit
        orderSortTime
        comment
      }
      error {
        message
      }
    }
  }
`;
export const setMenu = /* GraphQL */ `
  mutation SetMenu($input: SetMenuInput!) {
    setMenu(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const setNews = /* GraphQL */ `
  mutation SetNews($input: NewsInput!) {
    setNews(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const createBatchOrder = /* GraphQL */ `
  mutation CreateBatchOrder($input: CreateOrderInput!) {
    createBatchOrder(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const addThermobox = /* GraphQL */ `
  mutation AddThermobox($input: ChangeThermoboxCountInput) {
    addThermobox(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const returnThermobox = /* GraphQL */ `
  mutation ReturnThermobox($input: ChangeThermoboxCountInput) {
    returnThermobox(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      data {
        id
        email
        name
        surname
        mobileNumber
        address
        zip
        companyId
        createdAt
        newsletter
        hasReadAgb
        isCompanyAdmin
        canOrderOnBill
      }
      error {
        message
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      data {
        id
        email
        name
        surname
        mobileNumber
        address
        zip
        companyId
        createdAt
        newsletter
        hasReadAgb
        isCompanyAdmin
        canOrderOnBill
      }
      error {
        message
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
