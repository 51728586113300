import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import App from '../app/App';
import Philosophy from '../philosophy/Philosophy';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';
import Gastro from '../gastro/Gastro';
import Gdpr from '../gdpr/Gdpr';
import Home from '../home/Home';
import Imprint from '../imprint/Imprint';
import InterviewDetails from '../interviews/InterviewDetailView';
import Interviews from '../interviews/Interviews';
import Layout from '../layout/Layout';
import News from '../news/News';
import NotFoundPage from '../notFoundPage/NotFoundPage';
import PostPayment from '../postPayment/PostPayment';
import MenuPlan from '../publicMenuPlan/MenuPlan';
import routes from './routes';

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {
            // create a route for each app route
            Object.values(routes.app).map((route) => (
              <Route
                key={`app-route: ${route}`}
                exact
                path={route}
                component={App}
              />
            ))
          }
          <Route exact path={routes.contact} component={Contact} />
          <Route exact path={routes.gastro} component={Gastro} />
          <Route exact path={routes.menu} component={MenuPlan} />
          <Route exact path={routes.news} component={News} />
          <Route exact path={routes.interviews} component={Interviews} />
          <Route exact path={routes.philosophy} component={Philosophy} />
          <Route
            path={`${routes.interviews}/:id`}
            component={InterviewDetails}
          />
          <Route exact path={routes.imprint} component={Imprint} />
          <Route exact path={routes.gdpr} component={Gdpr} />
          <Route exact path={routes.postPayment} component={PostPayment} />
          <Route exact path={routes.home} component={Home} />
          <Route component={NotFoundPage} />
        </Switch>
        <Footer />
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
