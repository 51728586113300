/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getArticleById = /* GraphQL */ `
  query GetArticleById($input: GetArticleByIdInput!) {
    getArticleById(input: $input) {
      data {
        id
        isMainDish
        hasThermobox
        isLocked
        isDeprecated
        imageId
        maximumOrders
        melzerId
        name
        description
        allergens
        grossPrice
        tax
        articleGroupMelzerId
        articleGroupName
        articleGroupFromHour
        articleGroupToHour
        modifiers {
          id
          name
          description
          allergens
          grossPrice
          tax
          isActive
          sort
          modifierId
          modifierName
          modifierMin
          modifierMax
          modifierIgnorePrice
        }
      }
      error {
        message
      }
    }
  }
`;
export const getArticleByMelzerId = /* GraphQL */ `
  query GetArticleByMelzerId($input: GetArticleByMelzerIdInput!) {
    getArticleByMelzerId(input: $input) {
      data {
        id
        isMainDish
        hasThermobox
        isLocked
        isDeprecated
        imageId
        maximumOrders
        melzerId
        name
        description
        allergens
        grossPrice
        tax
        articleGroupMelzerId
        articleGroupName
        articleGroupFromHour
        articleGroupToHour
        modifiers {
          id
          name
          description
          allergens
          grossPrice
          tax
          isActive
          sort
          modifierId
          modifierName
          modifierMin
          modifierMax
          modifierIgnorePrice
        }
      }
      error {
        message
      }
    }
  }
`;
export const getArticlesByOrderId = /* GraphQL */ `
  query GetArticlesByOrderId($input: GetAllArticlesByOrderIdInput!) {
    getArticlesByOrderId(input: $input) {
      data {
        id
        isMainDish
        hasThermobox
        isLocked
        isDeprecated
        imageId
        maximumOrders
        melzerId
        name
        description
        allergens
        grossPrice
        tax
        articleGroupMelzerId
        articleGroupName
        articleGroupFromHour
        articleGroupToHour
        modifiers {
          id
          name
          description
          allergens
          grossPrice
          tax
          isActive
          sort
          modifierId
          modifierName
          modifierMin
          modifierMax
          modifierIgnorePrice
        }
      }
      error {
        message
      }
    }
  }
`;
export const getAllArticles = /* GraphQL */ `
  query GetAllArticles {
    getAllArticles {
      data {
        id
        isMainDish
        hasThermobox
        isLocked
        isDeprecated
        imageId
        maximumOrders
        melzerId
        name
        description
        allergens
        grossPrice
        tax
        articleGroupMelzerId
        articleGroupName
        articleGroupFromHour
        articleGroupToHour
        modifiers {
          id
          name
          description
          allergens
          grossPrice
          tax
          isActive
          sort
          modifierId
          modifierName
          modifierMin
          modifierMax
          modifierIgnorePrice
        }
      }
      error {
        message
      }
    }
  }
`;
export const getCompanyById = /* GraphQL */ `
  query GetCompanyById($input: GetCompanyByIdInput!) {
    getCompanyById(input: $input) {
      data {
        id
        companyName
        address
        zip
        mobileNumber
        contactPerson
        contactMail
        discount
        tableId
        discountArticleId
        employeeOrderLimit
        orderSortTime
        comment
      }
      error {
        message
      }
    }
  }
`;
export const getCompanyByName = /* GraphQL */ `
  query GetCompanyByName($input: GetCompanyByNameInput!) {
    getCompanyByName(input: $input) {
      data {
        id
        companyName
        address
        zip
        mobileNumber
        contactPerson
        contactMail
        discount
        tableId
        discountArticleId
        employeeOrderLimit
        orderSortTime
        comment
      }
      error {
        message
      }
    }
  }
`;
export const getAllCompanies = /* GraphQL */ `
  query GetAllCompanies {
    getAllCompanies {
      data {
        id
        companyName
        address
        zip
        mobileNumber
        contactPerson
        contactMail
        discount
        tableId
        discountArticleId
        employeeOrderLimit
        orderSortTime
        comment
      }
      error {
        message
      }
    }
  }
`;
export const getMenusByDateRange = /* GraphQL */ `
  query GetMenusByDateRange($input: GetMenusByDateRangeInput!) {
    getMenusByDateRange(input: $input) {
      data {
        menuType
        date
        description
        allergens
      }
      error {
        message
      }
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews {
    getNews {
      data {
        id
        date
        title
        content
      }
      error {
        message
      }
    }
  }
`;
export const getOrderById = /* GraphQL */ `
  query GetOrderById($input: GetOrderByIdInput!) {
    getOrderById(input: $input) {
      data {
        id
        orderState
        isBatchOrder
        companyId
        userId
        companyName
        userName
        tip
        orderType
        orderDate
        deliveryDate
        thermoBoxes
        comment
        discount
        discountArticleId
        tableId
        paymentId
        name
        surname
        address
        zip
        phoneNumber
        articles {
          id
          isMainDish
          hasThermobox
          isLocked
          isDeprecated
          imageId
          maximumOrders
          melzerId
          name
          description
          allergens
          grossPrice
          tax
          articleGroupMelzerId
          articleGroupName
          articleGroupFromHour
          articleGroupToHour
          modifiers {
            id
            name
            description
            allergens
            grossPrice
            tax
            isActive
            sort
            modifierId
            modifierName
            modifierMin
            modifierMax
            modifierIgnorePrice
          }
        }
        invoiceUrl
      }
      error {
        message
      }
    }
  }
`;
export const getOrdersByDeliveryDate = /* GraphQL */ `
  query GetOrdersByDeliveryDate($input: GetOrdersByDeliveryDateInput!) {
    getOrdersByDeliveryDate(input: $input) {
      data {
        id
        orderState
        isBatchOrder
        companyId
        userId
        companyName
        userName
        tip
        orderType
        orderDate
        deliveryDate
        thermoBoxes
        comment
        discount
        discountArticleId
        tableId
        paymentId
        name
        surname
        address
        zip
        phoneNumber
        articles {
          id
          isMainDish
          hasThermobox
          isLocked
          isDeprecated
          imageId
          maximumOrders
          melzerId
          name
          description
          allergens
          grossPrice
          tax
          articleGroupMelzerId
          articleGroupName
          articleGroupFromHour
          articleGroupToHour
          modifiers {
            id
            name
            description
            allergens
            grossPrice
            tax
            isActive
            sort
            modifierId
            modifierName
            modifierMin
            modifierMax
            modifierIgnorePrice
          }
        }
        invoiceUrl
      }
      error {
        message
      }
    }
  }
`;
export const getOrdersByUserId = /* GraphQL */ `
  query GetOrdersByUserId($input: GetOrdersByUserIdInput!) {
    getOrdersByUserId(input: $input) {
      data {
        id
        orderState
        isBatchOrder
        companyId
        userId
        companyName
        userName
        tip
        orderType
        orderDate
        deliveryDate
        thermoBoxes
        comment
        discount
        discountArticleId
        tableId
        paymentId
        name
        surname
        address
        zip
        phoneNumber
        articles {
          id
          isMainDish
          hasThermobox
          isLocked
          isDeprecated
          imageId
          maximumOrders
          melzerId
          name
          description
          allergens
          grossPrice
          tax
          articleGroupMelzerId
          articleGroupName
          articleGroupFromHour
          articleGroupToHour
          modifiers {
            id
            name
            description
            allergens
            grossPrice
            tax
            isActive
            sort
            modifierId
            modifierName
            modifierMin
            modifierMax
            modifierIgnorePrice
          }
        }
        invoiceUrl
      }
      error {
        message
      }
    }
  }
`;
export const getOrdersByCompanyId = /* GraphQL */ `
  query GetOrdersByCompanyId($input: GetOrdersByCompanyId!) {
    getOrdersByCompanyId(input: $input) {
      data {
        id
        orderState
        isBatchOrder
        companyId
        userId
        companyName
        userName
        tip
        orderType
        orderDate
        deliveryDate
        thermoBoxes
        comment
        discount
        discountArticleId
        tableId
        paymentId
        name
        surname
        address
        zip
        phoneNumber
        articles {
          id
          isMainDish
          hasThermobox
          isLocked
          isDeprecated
          imageId
          maximumOrders
          melzerId
          name
          description
          allergens
          grossPrice
          tax
          articleGroupMelzerId
          articleGroupName
          articleGroupFromHour
          articleGroupToHour
          modifiers {
            id
            name
            description
            allergens
            grossPrice
            tax
            isActive
            sort
            modifierId
            modifierName
            modifierMin
            modifierMax
            modifierIgnorePrice
          }
        }
        invoiceUrl
      }
      error {
        message
      }
    }
  }
`;
export const getThermoboxCounts = /* GraphQL */ `
  query GetThermoboxCounts {
    getThermoboxCounts {
      data {
        ownerId
        ownerName
        email
        phone
        quantity
      }
      error {
        message
      }
    }
  }
`;
export const getOrdersByDateRangeAndCompanyId = /* GraphQL */ `
  query GetOrdersByDateRangeAndCompanyId(
    $input: GetOrdersByDateRangeAndCompanyIdInput!
  ) {
    getOrdersByDateRangeAndCompanyId(input: $input) {
      data {
        id
        orderState
        isBatchOrder
        companyId
        userId
        companyName
        userName
        tip
        orderType
        orderDate
        deliveryDate
        thermoBoxes
        comment
        discount
        discountArticleId
        tableId
        paymentId
        name
        surname
        address
        zip
        phoneNumber
        articles {
          id
          isMainDish
          hasThermobox
          isLocked
          isDeprecated
          imageId
          maximumOrders
          melzerId
          name
          description
          allergens
          grossPrice
          tax
          articleGroupMelzerId
          articleGroupName
          articleGroupFromHour
          articleGroupToHour
          modifiers {
            id
            name
            description
            allergens
            grossPrice
            tax
            isActive
            sort
            modifierId
            modifierName
            modifierMin
            modifierMax
            modifierIgnorePrice
          }
        }
        invoiceUrl
      }
      nextToken {
        pk
        sk
        typename
        GSITYPESK
      }
    }
  }
`;
export const getOrderArticleCountByDateRange = /* GraphQL */ `
  query GetOrderArticleCountByDateRange(
    $input: GetOrderArticleCountByDateRangeInput!
  ) {
    getOrderArticleCountByDateRange(input: $input) {
      data {
        date
        counts {
          count
          articleName
        }
      }
      error {
        message
      }
    }
  }
`;
export const getOrdersByDateRange = /* GraphQL */ `
  query GetOrdersByDateRange($input: GetOrdersByDateRangeInput!) {
    getOrdersByDateRange(input: $input) {
      data {
        id
        orderState
        isBatchOrder
        companyId
        userId
        companyName
        userName
        tip
        orderType
        orderDate
        deliveryDate
        thermoBoxes
        comment
        discount
        discountArticleId
        tableId
        paymentId
        name
        surname
        address
        zip
        phoneNumber
        articles {
          id
          isMainDish
          hasThermobox
          isLocked
          isDeprecated
          imageId
          maximumOrders
          melzerId
          name
          description
          allergens
          grossPrice
          tax
          articleGroupMelzerId
          articleGroupName
          articleGroupFromHour
          articleGroupToHour
          modifiers {
            id
            name
            description
            allergens
            grossPrice
            tax
            isActive
            sort
            modifierId
            modifierName
            modifierMin
            modifierMax
            modifierIgnorePrice
          }
        }
        invoiceUrl
      }
      nextToken {
        pk
        sk
        typename
        GSITYPESK
      }
    }
  }
`;
export const getUploadUrl = /* GraphQL */ `
  query GetUploadUrl($input: UploadFileInput!) {
    getUploadUrl(input: $input) {
      data {
        uploadUrl
        key
      }
      error {
        message
      }
    }
  }
`;
export const getDownloadUrl = /* GraphQL */ `
  query GetDownloadUrl($input: DownloadFileInput!) {
    getDownloadUrl(input: $input) {
      data
      error {
        message
      }
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById($input: GetUserByIdInput!) {
    getUserById(input: $input) {
      data {
        id
        email
        name
        surname
        mobileNumber
        address
        zip
        companyId
        createdAt
        newsletter
        hasReadAgb
        isCompanyAdmin
        canOrderOnBill
      }
      error {
        message
      }
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($input: GetUserByEmailInput!) {
    getUserByEmail(input: $input) {
      data {
        id
        email
        name
        surname
        mobileNumber
        address
        zip
        companyId
        createdAt
        newsletter
        hasReadAgb
        isCompanyAdmin
        canOrderOnBill
      }
      error {
        message
      }
    }
  }
`;
export const getUsersByCompanyId = /* GraphQL */ `
  query GetUsersByCompanyId($input: GetUsersByCompanyIdInput!) {
    getUsersByCompanyId(input: $input) {
      data {
        id
        email
        name
        surname
        mobileNumber
        address
        zip
        companyId
        createdAt
        newsletter
        hasReadAgb
        isCompanyAdmin
        canOrderOnBill
      }
      error {
        message
      }
    }
  }
`;
