import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  adminViewRoot: {
    backgroundColor: theme.palette.background.default,
  },
  addFab: {
    position: 'fixed',
    bottom: '15px',
    right: '70px',
  },
  skeletonRoot: {
    display: 'grid',
    gridGap: '1px',
  },
  companyUserList: {
    paddingLeft: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderTop: `2px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      borderTop: 'none',
      borderLeft: `2px solid ${theme.palette.divider}`,
    },
  },
  detailsIcon: {
    marginRight: '5px',
    fontSize: '20px',
    border: '1px solid red',
  },
}));

export default useStyles;
