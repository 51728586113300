import React from 'react';

import { triggerSync } from '../../api/Article';
import useNotificationActions from '../../hooks/useNotificationActions';

interface IReturn {
  sync(): void;
  loading: boolean;
}

const useArticleSync = (): IReturn => {
  const [loading, setLoading] = React.useState(false);
  const { show } = useNotificationActions();

  const sync = async () => {
    setLoading(true);
    try {
      const res = await triggerSync();
      if (res.errors || res.data?.triggerArticleSync?.error) {
        show(
          'Error',
          'Artikel können derzeit nicht synchronisiert werden',
          'error'
        );
      }

      if (res.data?.triggerArticleSync?.data) {
        show('Erfolg', 'Artikel werden gesynct.', 'success');
      }
    } catch (error) {
      show(
        'Error',
        'Artikel können derzeit nicht synchronisiert werden',
        'error'
      );
    }
    setLoading(false);
  };

  return { sync, loading };
};

export default useArticleSync;
