import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <>
      <div>Seite nicht gefunden</div>
      <Link to="/">Zur Startseite</Link>
    </>
  );
};

export default NotFoundPage;
