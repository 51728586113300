import React from 'react';
import { Slide, Grid, Typography } from '@material-ui/core';

import Spacer from '../util/Spacer';
import useStyles from './Gastro.css';

const Gastro: React.FC = (): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Slide in direction="down" timeout={500}>
        <div className={classes.hero} />
      </Slide>
      <div className={classes.root}>
        <Grid container>
          <Slide in direction="right" timeout={500}>
            <Grid item xs={12} md={7} className={classes.textAreaLeft}>
              <Typography
                variant="h6"
                color="primary"
                align="center"
                gutterBottom
              >
                Der perfekte Arbeitstag? Wenn die Work-Life-Balance bereits
                frühmorgens beginnt.
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                align="justify"
                gutterBottom
              >
                Beispielsweise mit einem Eierfrühstück – schon ab 8.00 Uhr. Auf
                den Tisch kommen nur saisonale und vorzugsweise regionale
                Produkte. Frisch zubereitet nach traditionellen Rezepten und
                neuen Ideen zu drei Mittagsmenüs (Menü 2 immer vegetarisch)
                ergänzt mit einer à la carte-Auswahl. Mittags wird zwischen
                11.00 und 14.00 Uhr aufgetischt. Der Pizzaofen bleibt
                durchgehend warm. Steirische Spitzenweine begeistern auch beim
                After Business Drink durch Auswahl und in Begleitung
                kulinarischer Stärkung.
              </Typography>
              <Spacer height={20} />
              <Typography
                variant="h6"
                color="primary"
                align="center"
                gutterBottom
              >
                Das Beste daran?
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                align="justify"
                gutterBottom
              >
                Restaurantqualität ohne Zeitverlust. Vom Büro lässt sich die
                Menüauswahl online zur Abholung vorbestellen. Registrierte
                Unternehmenspartner werden beliefert. Der gute Geschmack der
                Gastgeber bestimmt die kulinarische Linie. Die langjährige
                Branchen-Erfahrung prägt die Partnerschaft mit ausgewählten
                Lieferanten, die gleichbleibend gute Qualität und die
                konsequente Bedürfniserfüllung der Gäste.
              </Typography>
              <Spacer height={20} />
            </Grid>
          </Slide>
          <Slide in direction="left" timeout={500}>
            <Grid item xs={12} md={5} className={classes.imgArea1} />
          </Slide>
          <Slide in direction="up" timeout={1000}>
            <Grid item xs={12} className={classes.priceHeading}>
              <Typography
                variant="h4"
                color="primary"
                align="center"
                gutterBottom
              >
                Informationen zu Allergenen
              </Typography>
            </Grid>
          </Slide>
          <Slide in direction="left" timeout={500}>
            <Grid item xs={12} className={classes.textAreaCenter}>
              <Typography
                variant="body1"
                color="textSecondary"
                align="justify"
                gutterBottom
                style={{ whiteSpace: 'pre-line' }}
              >
                {`
                  A Gluten
                  Nicht verabreichen: Glutenhaltige Getreide und Erzeugnisse aus Weizen, Roggen, Gerste, Hafer, Dinkel, Kamut und Hybridsaaten, Emmer, Einkorn, Grünkern Oft zu finden in: Mehl, Brösel, Gebäck, Brot, Teigwaren, Kuchen, Backerbsen, Desserts

                  B Krebstiere
                  Nicht verabreichen: Langusten, Shrimps, Krebse, Hummer, Garnelen, Scampi, Erzeugnisse aus diesen Tieren. Oft zu finden in: Würzpasten, Feinkostsalaten, asiatischen Zubereitungen, Sashimi, Surimi, Bouillabaisse, Paella
                  
                  C Eier
                  Nicht verabreichen: Erzeugnisse aus Eiern, Vollei, Eigelb, Eiklar, Trockenei, Eipulver, Flüssigei, Eiprotein, E222 - Lecithin aus Eibasis, E 1105 Lysozym aus Eibasis Oft zu finden in: Eierteigwaren, Mayonnaisen, Kuchen, Torten, Paltatschinken, Panaden, Desserts
                  
                  D Fisch
                  Nicht verabreichen: Fische, Kaviar, Fischpaste, Anchovipaste, Fischgelatine, Fischsauce Oft zu finden in: Sauce, Würzpasten, Fonds, Brotaufstrichen, Suppen, Surimi, Pasteten, Sardellenwurst, Vitello tonnato
                  
                  E Erdnüsse
                  Nicht verabreichen: Erdnüsse, Erdnussbutter, Erdnussöl, Erdnusslikör Oft zu finden in: Cerealien und Müsli, Kuchen, Schokoladen, Brotaufstriche, aromatisierter Kaffee, Feinkostsalate, Marinaden
                  
                  F Soja
                  Nicht verabreichen: Sojasprossen, Sojabohnen, Edamame, Miso, Sojapaste, Sojasauce, Sojaöl, Sojapulver, Lecithin E222 (Soja), Tofu Oft zu finden in: Desserts, Schokocremen, Drinks, Diätprodukten, Kaffeeweisser, Müsli, Aufstrichen, Brotwaren, Gebäck, Kuchen, Margarinen, Eis, Feinkostsalaten, Mayonnaisen, Dressings, Saucen
                  
                  G Milch
                  Nicht verabreichen: Milch, Milchprodukte einschließlich Laktose  (Milchzucker) Oft zu finden in: Saucen, Aufläufen, Gratins, Brot, Gebäck, Dressings, Schokolade, Würsten, Suppen
                  
                  H Schalenfrüchte
                  Nicht verabreichen: Walnüsse, Haselnüsse, Pekanüsse, Macadamia, Queenslandnüsse, Mandeln, Pistazien, Cashewkerne, Paranüsse Oft zu finden in: Müsli, Cerealien, Kuchen, Likör, Pasteten, Pestos, Schokoladen. Marzipan
                  
                  L Sellerie
                  Nicht verabreichen: Sellerie (Blatt,- Stauden-, Stangen, Knollen-,) Selleriesamen, Selleriesaft, Selleriepulver Oft zu finden in: Wurst, Fleischzubereitungen, Suppen, Eintöpfe, Suppengewürz, Fertiggerichte, Bouillons, eingelegtes Gemüse
                  
                  M Senf
                  Nicht verabreichen: Senf und Senfkörner, alle Produkte aus Senf (Senfpulver, Senföl, Senfsprossen) Oft zu finden in: Fleischerzeugnisse, Ketchup, Mayonnaise, Sauergemüse, Einlegegemüse
                  
                  N Sesam
                  Nicht verabreichen: Sesamsamen, Sesammehl, Sesamöl, Sesampaste, Sesamsalz Oft zu finden in: Brot, Gebäck, Müsli, Humus, Marinaden, Feinkostsalate, Falafel, vegetarische Gerichte
                  
                  O Schwefeldioxid und Sulfite
                  Nicht verabreichen: mit Schwefel behandeltes Trockenobst (z.B. Rosinen) getrocknete Pilze, getrocknetes Gemüse, Tomatenpürree, Wein (E220, E221, E222, E223, E224, E226, E227, E228) Oft zu finden: Saucen, Suppen, Feinkostsalaten, Fruchtsäften, Chips und andere trockene Kartoffelprodukte, Sauerkraut
                  
                  P Lupine
                  Nicht verabreichen: Lupinenmehl, Lupinenisolat Lupinenprotein, Lupinenpaste (Konzentrat) Oft zu finden in: Pizza, Kuchen, Brot, Gebäck, Snacks, fettreduzierte Fleischerzeugnisse, Glutenfreie Produkte, Fleischersatz, Flüssigwürze
                  
                  R Weichtiere
                  Nicht verabreichen: Muscheln, Austern, Schnecken, Tintenfisch, Oktopus, Calamares Oft zu finden in: asiatischen Würzpasten, Paella, Suppen, Feinkostsalaten, Saucen, Marinaden
                `}
              </Typography>
            </Grid>
          </Slide>
          <Slide in direction="up" timeout={1000}>
            <Grid item xs={12} className={classes.priceHeading}>
              <Typography
                variant="h4"
                color="primary"
                align="center"
                gutterBottom
              >
                Informationspflicht zur Lebensmittelherkunft
              </Typography>
            </Grid>
          </Slide>
          <Slide in direction="left" timeout={500}>
            <Grid item xs={12} className={classes.textAreaCenter}>
              <Typography
                variant="body1"
                color="textSecondary"
                align="justify"
                gutterBottom
                style={{ whiteSpace: 'pre-line' }}
              >
                <a
                  href="/documents/Informationspflicht_Lebensmittelherkunft.pdf"
                  download
                >
                  Hier können Sie die Informationspflicht zur
                  Lebensmittelherkunft downloaden.
                </a>
              </Typography>
            </Grid>
          </Slide>
        </Grid>
      </div>
    </>
  );
};

export default Gastro;
