import React from 'react';
import { createContainer } from 'react-tracked';
import { ICompany } from '../api/Company';
import { IUser } from '../api/User';

export enum RoleEnum {
  GUEST = 'GUEST',
  USER = 'USER',
  COMPANYADMIN = 'COMPANYADMIN',
  ADMIN = 'ADMIN',
}

export interface IAuthState {
  user?: string;
  userDetails?: IUser;
  companyDetails?: ICompany;
  role: RoleEnum;
  isAuthenticating: boolean;
  passwordResetNeeded: boolean;
  forgotPassword: boolean;
  forgotPasswordCodeSent: boolean;
  tempMail?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tempCognitoUser?: any;
}

export type AuthAction =
  | { type: 'loadingStart' }
  | { type: 'loadingEnd' }
  | { type: 'activateGuestMode' }
  | { type: 'forgotPassword' }
  | { type: 'forgotPasswordCodeSent'; tempMail: string }
  | {
      type: 'signIn';
      payload: {
        user: string;
        role: RoleEnum;
        userDetails: IUser;
        companyDetails: ICompany;
      };
    }
  | { type: 'signOut' }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: 'newPwRequired'; tempCognitoUser: any }
  | { type: 'newPwCompleted'; newPw: string }
  | { type: 'resetPwConfirmed' };

const initialState: IAuthState = {
  role: RoleEnum.GUEST,
  passwordResetNeeded: false,
  isAuthenticating: false,
  forgotPassword: false,
  forgotPasswordCodeSent: false,
};

const authReducer = (state: IAuthState, action: AuthAction): IAuthState => {
  switch (action.type) {
    case 'loadingStart':
      return {
        ...state,
        isAuthenticating: true,
      };

    case 'loadingEnd':
      return {
        ...state,
        isAuthenticating: false,
      };

    case 'activateGuestMode':
      return {
        ...state,
        user: 'GAST',
        role: RoleEnum.GUEST,
        isAuthenticating: false,
      };

    case 'signIn':
      return {
        ...state,
        user: action.payload.user,
        userDetails: action.payload.userDetails,
        companyDetails: action.payload.companyDetails,
        role: action.payload.role,
        isAuthenticating: false,
      };

    case 'signOut':
      return {
        ...initialState,
      };

    case 'forgotPassword':
      return {
        ...state,
        forgotPassword: true,
        forgotPasswordCodeSent: false,
        isAuthenticating: false,
      };

    case 'forgotPasswordCodeSent':
      return {
        ...state,
        forgotPasswordCodeSent: true,
        tempMail: action.tempMail,
        isAuthenticating: false,
      };

    case 'resetPwConfirmed':
      return {
        ...state,
        forgotPasswordCodeSent: false,
        forgotPassword: false,
        tempMail: '',
        isAuthenticating: false,
      };

    case 'newPwRequired':
      return {
        ...state,
        passwordResetNeeded: true,
        user: '',
        tempCognitoUser: action.tempCognitoUser,
        isAuthenticating: false,
      };

    case 'newPwCompleted':
      return {
        ...state,
        passwordResetNeeded: false,
        tempCognitoUser: null,
        isAuthenticating: false,
      };

    default:
      return state;
  }
};

const useValue = () => React.useReducer(authReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
