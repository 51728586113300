import React from 'react';

import AppNav from './AppNav';
import useStyles from './AppNav.css';

interface IProps {
  children: React.ReactNode;
}

const AppLayout = ({ children }: IProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.navContainer}>
      <AppNav />
      <div className={classes.contentContainer}>{children}</div>
    </div>
  );
};

export default AppLayout;

/*
const AppLayout = ({ children }: IProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={2}
        component={Paper}
        elevation={6}
        square
        className={classes.navContainer}
      >
        <AppNav />
      </Grid>
      <Grid item xs={12} sm={10} className={classes.contentContainer}>
        {children}
      </Grid>
    </Grid>
  );
}; */
