import React from 'react';
import {
  ListItem,
  ListItemText,
  Chip,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { IUser } from '../../api/User';
import DeleteUserDialog from './DeleteUserDialog';
import useStyles from './CompanyUserList.css';
import { DeleteUserInput } from '../../api/API';
import UpdateUserDialog from './UpdateUserDialog';

interface IProps {
  user: IUser;
  deleteUser(input: DeleteUserInput): Promise<void>;
  updateUser(input: IUser): Promise<void>;
}

const CompanyUserListItem = ({
  user,
  deleteUser,
  updateUser,
}: IProps): JSX.Element => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <ListItem button onClick={() => setUpdateDialogOpen(true)}>
        <ListItemText
          primary={
            <>
              {`${user.name} ${user.surname}`}
              {user.isCompanyAdmin && (
                <Chip
                  className={classes.adminChip}
                  label="Admin"
                  size="small"
                  color="primary"
                />
              )}
              {user.canOrderOnBill && (
                <Chip
                  className={classes.adminChip}
                  label="Berechtigt zur Bestellung auf Rechnung"
                  size="small"
                  color="primary"
                />
              )}
            </>
          }
          secondary={user.email}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => setDeleteDialogOpen(true)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <DeleteUserDialog
        open={deleteDialogOpen}
        close={() => setDeleteDialogOpen(false)}
        input={{ id: user.id, email: user.email }}
        deleteUsr={deleteUser}
      />
      <UpdateUserDialog
        open={updateDialogOpen}
        close={() => setUpdateDialogOpen(false)}
        input={user}
        update={updateUser}
      />
    </>
  );
};

export default CompanyUserListItem;
