import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { IOrder } from '../../api/Order';
import OrderTableRow from './OrderTableRow';
import { removeDuplicates } from '../../util/util';

interface IProps {
  orders: IOrder[];
  expandLvl2: boolean;
}

const getTableWidth = (mobile: boolean, sm: boolean, md: boolean) => {
  if (mobile) {
    return 'calc(100vw - 20px)';
  }

  if (sm) {
    return 'calc(100vw - 240px)';
  }

  if (md) {
    return 'calc(100vw - 320px)';
  }

  return '100vw';
};

const OrderTable = ({ orders, expandLvl2 }: IProps): JSX.Element => {
  const filtered = removeDuplicates<IOrder>(orders);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const tWidth = getTableWidth(mobile, sm, md);

  return (
    <TableContainer component={Paper} style={{ maxWidth: tWidth }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Company</TableCell>
            <TableCell align="right">User</TableCell>
            <TableCell align="right">Anzahl</TableCell>
            <TableCell align="right">Bestelltyp</TableCell>
            <TableCell align="right">Gesamtpreis (€)</TableCell>
            <TableCell align="right">Bestellstatus</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filtered.map((order) => {
            return (
              <OrderTableRow
                key={`order-table-row${order.id}`}
                order={order}
                articleAmount={order.articles.length}
                expandLvl2={expandLvl2}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
