import { DateTime } from 'luxon';
import { IArticle } from '../../api/Article';

const holidays22 = [
  '01-01',
  '01-06',
  '05-01',
  '05-18',
  '05-29',
  '06-08',
  '08-15',
  '10-26',
  '11-01',
  '12-08',
  '12-25',
  '12-26',
];

export const isInValidOrderRange = (orderTime: DateTime): boolean => {
  const { hour, weekday } = orderTime;

  // check if order is beween mon and fri
  if (weekday > 5) {
    return false;
  }

  // check if order is before 13:30 and after 11:00
  if (hour < 11 || hour > 14) {
    return false;
  }

  return true;
};

const getNextMondayEleven = (now: DateTime): DateTime => {
  const nextWeek = now.plus({ weeks: 1 });
  const mondayNextWeek = nextWeek.set({
    weekday: 1,
    hour: 11,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return mondayNextWeek;
};

const isBeforeMenuDeadline = (now: DateTime) => now.get('hour') < 9;

const isBeforeClosingDeadline = (now: DateTime) => {
  const { hour, minute } = now;

  if (hour >= 14) {
    return false;
  }

  if (hour === 13 && minute > 30) {
    return false;
  }

  return true;
};

const isBeforeCompanyDeadline = (time: DateTime) => {
  if (time.hour > 13) {
    return false;
  }
  return true;
};

const plusThirtyMinutes = (now: DateTime) => now.plus({ minutes: 30 });

const atElevenSameDay = (now: DateTime) =>
  now.set({ hour: 11, minute: 0, second: 0, millisecond: 0 });

const atElevenNextDay = (now: DateTime) =>
  now.plus({ days: 1 }).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });

export const isWeekend = (date: DateTime): boolean => {
  return date.get('weekday') === 7 || date.get('weekday') === 6;
};

export const getGuestOrderMinDate = (
  now: DateTime,
  containsMenu: boolean
): DateTime => {
  const { weekday } = now;

  // if day is SUNDAY or SATURDAY, return next monday 11:00
  if (isWeekend(now)) {
    return getNextMondayEleven(now);
  }

  // if day is friday, check if order is in timeframe, else return monday
  if (weekday === 5) {
    // if menu, order has to be taken before 9
    if (containsMenu) {
      if (isBeforeMenuDeadline(now)) {
        return atElevenSameDay(now);
      }
      // else next monday
      return getNextMondayEleven(now);
    }

    // no menu, order has to be be before 13:30
    if (isBeforeClosingDeadline(now)) {
      return plusThirtyMinutes(now);
    }
    // else next monday
    return getNextMondayEleven(now);
  }

  // during regular weekdays
  if (containsMenu) {
    if (isBeforeMenuDeadline(now)) {
      return atElevenSameDay(now);
    }
    // else next day
    return atElevenNextDay(now);
  }

  // no menu
  if (isBeforeClosingDeadline(now)) {
    return plusThirtyMinutes(now);
  }
  return atElevenNextDay(now);
};

export const getCompanyOrderMinDate = (now: DateTime): DateTime => {
  const { weekday } = now;

  // if day is SATURDAY or FRIDAY, return next monday 11:00
  if (weekday === 6 || weekday === 5) {
    return getNextMondayEleven(now);
  }

  // if day is SUNDAY, check if is before 11
  if (weekday === 7) {
    if (isBeforeCompanyDeadline(now)) {
      return getNextMondayEleven(now);
    }
    // else next tuesday
    const monday = getNextMondayEleven(now);
    const tuesday = monday.plus({ days: 1 });
    return tuesday;
  }

  // during regular weekdays
  if (isBeforeCompanyDeadline(now)) {
    return atElevenNextDay(now);
  }
  // else day after tomorrow
  const tomorrowPlusOne = atElevenNextDay(now).plus({ days: 1 });
  return tomorrowPlusOne;
};

export const getMinDate = (
  containsMenu: boolean,
  guestOrder: boolean
): DateTime => {
  const now = DateTime.now();
  if (guestOrder) {
    return getGuestOrderMinDate(now, containsMenu);
  }
  return getCompanyOrderMinDate(now);
};

export const orderContainsMenu = (items: IArticle[]): boolean => {
  const orders = items.filter((item) => item.hasThermobox);
  return orders.length !== 0;
};

export const isHoliday = (day: DateTime): boolean => {
  return holidays22.includes(day.toString().substr(5, 5));
};
