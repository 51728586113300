import { DateTime } from 'luxon';

export const getHoursFromISO = (isoTime: string): string => {
  const time = DateTime.fromISO(isoTime);
  return time.toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const getReadableDateFromISO = (isoTime: string): string => {
  const time = DateTime.fromISO(isoTime);
  return time.toLocaleString({ locale: 'de' });
};
