import React from 'react';
import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import Logo from '../../static/images/ccgmplogo.png';
import useStyles from './Footer.css';
import { menuItems } from '../fullScreenMenu/FullScreenMenu';

const Footer = (): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const isApp = location.pathname.includes('/app/');

  if (isApp) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          // justify="center"
          // alignItems="center"
          xs={12}
          sm={4}
          className={classes.logoArea}
        >
          <img src={Logo} alt="Logo" height="150px" />
        </Grid>
        <Grid
          item
          // justify="center"
          // alignItems="center"
          xs={12}
          sm={4}
          className={classes.logoArea}
        >
          <List dense>
            {menuItems.map((item) => (
              <ListItem key={`ListItem${item.route}`}>
                <ListItemText
                  primary={<Link href={item.route}>{item.name}</Link>}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          // justify="center"
          // alignItems="center"
          xs={12}
          sm={4}
          className={classes.logoArea}
        >
          <List dense>
            <ListItem key="FooterListItemdatenschutz">
              <ListItemText
                primary={<Link href="/datenschutz">Datenschutz</Link>}
              />
            </ListItem>
            <ListItem key="FooterListItemimprint">
              <ListItemText
                primary={<Link href="/impressum">Impressum</Link>}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
          <Typography
            color="textSecondary"
            align="center"
            variant="body2"
            style={{ marginTop: '10px' }}
          >
            {`© CCG Meetingpoint GmbH & Co KG ${new Date().getFullYear()}`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
