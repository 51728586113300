import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useStyles from './PostPayment.css';

const successMsg =
  'Ihre Bestellung wurde erfolgreich aufgegeben! Sie erhalten in Kürze eine Bestätigungsmail.';
const errorMsg =
  'Bei der Bezahlung ist ein Fehler aufgetreten. Bitte versuchen Sie die Bestellung erneut durchzuführen oder kontaktieren Sie uns persönlich.';

const PostPayment = (): JSX.Element => {
  const query = new URLSearchParams(window.location.search);
  const success = query.get('success') === 'true';
  const msg = success ? successMsg : errorMsg;
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        color={success ? 'primary' : 'error'}
      >
        {msg}
      </Typography>
      <Button
        fullWidth
        variant="text"
        color="primary"
        size="large"
        onClick={() => history.push('/')}
      >
        Zurück zur App
      </Button>
    </div>
  );
};

export default PostPayment;
