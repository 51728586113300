import React from 'react';
import { Skeleton } from '@material-ui/lab';

import useStyles from './SkeletonList.css';

interface IProps {
  // eslint-disable-next-line react/require-default-props
  tiles?: boolean;
  // eslint-disable-next-line react/require-default-props
  amount?: number;
  // eslint-disable-next-line react/require-default-props
  width?: number;
  // eslint-disable-next-line react/require-default-props
  height?: number;
}

const SkeletonList = ({
  tiles = false,
  amount = 6,
  width = 210,
  height = 250,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const SkeletonItem = () => (
    <Skeleton
      variant="rect"
      width={tiles ? width : '100%'}
      height={tiles ? height : 50}
      className={tiles ? classes.tileItem : classes.listItem}
    />
  );

  return (
    <div className={tiles ? classes.tiles : classes.list}>
      {[...Array(amount)].map(() => (
        <SkeletonItem key={`skeletonitem${Math.random()}`} />
      ))}
    </div>
  );
};

export default SkeletonList;
