import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useTrackedState as useArticleState } from '../../store/Articles';
import { useTrackedState as useAuthState } from '../../store/Auth';
import SkeletonList from '../skeletonList/SkeletonList';

import CardBanner from './CardBanner';
import ArticleGroup from './ArticleGroup';
import { groupArticlesByGroupName } from '../../util/util';
import { IArticleGroup } from './types';

const fingerfoodGroup: IArticleGroup = {
  groupname: 'Fingerfood',
  articles: [
    {
      allergens: 'auf Anfrage',
      articleGroupFromHour: '',
      articleGroupMelzerId: '',
      articleGroupName: '',
      articleGroupToHour: '',
      description:
        'Fingerfood auf Anfrage (03135/93031). \n4 Werktage im Voraus. Preis pro Person ab 18,00€. Bestellung ab 10 Personen möglich.',
      grossPrice: 1800,
      hasThermobox: false,
      maximumOrders: 0,
      id: 'fingerfood-offline-article',
      imageId: 'fingerfood.png',
      isDeprecated: false,
      isLocked: true,
      isMainDish: false,
      melzerId: '',
      modifiers: [],
      name: 'Fingerfood warm/kalt',
      tax: 0,
    },
  ],
};

const AppOrder = (): JSX.Element => {
  const { articles, loading, error } = useArticleState();
  const { user } = useAuthState();

  if (loading) {
    return <SkeletonList tiles />;
  }

  if (error) {
    return (
      <Typography variant="body2" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <>
      <div style={{ padding: 20 }}>
        <Grid container spacing={5}>
          <Typography variant="caption" color="textSecondary">
            Bei allen Bildern handelt es sich um Symoblfotos.{' '}
            <a
              href="/documents/Informationspflicht_Lebensmittelherkunft.pdf"
              download
            >
              Hier können Sie die Informationspflicht zur Lebensmittelherkunft
              downloaden.
            </a>
          </Typography>
          {groupArticlesByGroupName(articles).map(
            (group): JSX.Element => (
              <ArticleGroup
                addDisabled={false}
                group={group}
                isGuest={user === 'GAST'}
                key={`order-group-${group.groupname}`}
              />
            )
          )}
          <ArticleGroup
            addDisabled
            group={fingerfoodGroup}
            isGuest={user === 'GAST'}
            key="order-group-fingerfood"
          />
        </Grid>
      </div>
      <CardBanner />
    </>
  );
};

export default AppOrder;
