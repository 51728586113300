/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { IconButton, LinearProgress, Typography } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Skeleton } from '@material-ui/lab';

import useStyles from './ImageInput.css';
import useFileUpload from '../../hooks/useFileUpload';
import useFileDownload from '../../hooks/useFileDownload';
import Theme from '../../theme/Theme';
import useNotificationActions from '../../hooks/useNotificationActions';

interface IProps {
  imageKey: string;
  maxFileSize: number;
  label: string;
  id: string;
  loading: boolean;
  updateCallback(key: string): Promise<void>;
  isGuest: boolean;
}

const ImageUploadInput = ({
  imageKey,
  maxFileSize,
  label,
  id,
  updateCallback,
  loading,
  isGuest,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const { show } = useNotificationActions();
  const { url, loading: imgLoading } = useFileDownload({
    key: imageKey,
    isGuest,
  });
  const { uploading, upload } = useFileUpload();

  const handleFileInputChange = async (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (ev.target.files) {
      const newFile = ev.target.files[0];

      // abort when file is over maxsize
      if (newFile.size > maxFileSize * 1000) {
        show(
          'Datei zu groß',
          `Maximale Dateigröße beträgt ${maxFileSize}kB`,
          'error'
        );
      } else {
        // upload file
        const res = await upload(newFile);
        if (res) {
          // save file key to config
          await updateCallback(res);
        }
      }
    }
  };

  return (
    <>
      {imgLoading || uploading || loading ? (
        <Skeleton variant="rect" width="100%" height="120px" />
      ) : (
        <>
          <Typography variant="caption">{`${label} (max. ${maxFileSize}KB)`}</Typography>
          <div
            style={{
              backgroundImage: `url(${url})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: Theme.palette.grey[300],
              height: '120px',
              position: 'relative',
            }}
          >
            <input
              accept="image/*"
              type="file"
              id={`imageUpload-${id}`}
              className={classes.input}
              onChange={handleFileInputChange}
            />
            <label htmlFor={`imageUpload-${id}`}>
              <IconButton
                color="primary"
                className={classes.uploadButton}
                aria-label="upload picture"
                component="span"
              >
                <AddAPhotoIcon />
              </IconButton>
            </label>
            {uploading && <LinearProgress />}
          </div>
        </>
      )}
    </>
  );
};

export default ImageUploadInput;
