import React from 'react';
import { Fab, makeStyles, Theme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv';

import {
  getCsvDataFormatFromUsers,
  IUserWithCompanyName,
} from '../../util/csvParser';
import { ICompany } from '../../api/Company';
import { getUsersByCompanyId } from '../../api/User';

interface IProps {
  companies: ICompany[];
}

const useStyles = makeStyles((theme: Theme) => ({
  downloadButton: {
    position: 'fixed',
    right: '70px',
    bottom: '75px',
    [theme.breakpoints.up('sm')]: {
      right: '350px',
      bottom: '15px',
    },
  },
}));

const DownloadButton = ({ companies }: IProps): JSX.Element => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [users, setUsers] = React.useState<IUserWithCompanyName[]>([]);
  const csvData = getCsvDataFormatFromUsers(users);
  const csvFileName = `export-${new Date().toISOString()}.csv`;

  React.useEffect(() => {
    const fetchAllUsers = async () => {
      setLoading(true);
      try {
        const promises = companies.map((comp) => {
          return getUsersByCompanyId({ companyId: comp.id });
        });
        const res = await Promise.all(promises);
        res.forEach((companyUsers) => {
          const usersRes = companyUsers.data?.getUsersByCompanyId?.data;
          if (usersRes) {
            usersRes.forEach((user) => {
              if (user) {
                setUsers((prev) => [
                  ...prev,
                  {
                    ...user,
                    canOrderOnBill: user.canOrderOnBill
                      ? user.canOrderOnBill
                      : false,
                    companyName:
                      companies.find((c) => c.id === user.companyId)
                        ?.companyName || '',
                  },
                ]);
              }
            });
          } else {
            setError(true);
          }
        });
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    fetchAllUsers();
  }, [companies]);

  return (
    <Fab
      disabled={users.length < 1 || loading || error}
      variant="extended"
      color="primary"
      className={classes.downloadButton}
    >
      <GetAppIcon style={{ marginRight: '5px' }} />
      <CSVLink
        filename={csvFileName}
        data={csvData}
        separator=";"
        style={{ color: 'white' }}
      >
        .csv Download
      </CSVLink>
    </Fab>
  );
};

export default DownloadButton;
