import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import React from 'react';
import { useTrackedState } from '../../store/Companies';
import CompanyAdminView from '../appOrderManagement/CompanyAdminView';
// import CompanyAdminView from '../appOrderManagement/CompanyAdminView';
import useCompanyActions from '../appUserManagement/useCompanyActions';
import AllCompaniesView from './AllCompView';

const AppCompanyReport = (): JSX.Element => {
  const { fetch } = useCompanyActions();
  const { companies, loading } = useTrackedState();
  const [selectedComp, setSelectedComp] = React.useState('');
  const [allComps, setAllComps] = React.useState(false);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const handleCompChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedComp(event.target.value as string);
  };

  return (
    <Box>
      <Box component={Paper} p={3} marginBottom={1}>
        <FormGroup>
          <FormControlLabel
            labelPlacement="top"
            control={
              <Checkbox
                checked={allComps}
                onChange={(ev) => setAllComps(ev.target.checked)}
              />
            }
            label={
              <Typography color="primary">
                <b>ALLE</b> Firmen auswählen
              </Typography>
            }
          />
        </FormGroup>
        <Typography align="center" variant="body1" color="primary" gutterBottom>
          <b>ODER</b> Wählen Sie hier die Firma aus:
        </Typography>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="company-select-label">Firma</InputLabel>
          <Select
            disabled={loading || allComps}
            labelId="company-select-label"
            id="copmany-select"
            value={allComps ? '' : selectedComp}
            label="Firma"
            onChange={handleCompChange}
          >
            {companies.map((comp) => (
              <MenuItem value={comp.id}>{comp.companyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {allComps ? (
        <AllCompaniesView companies={companies} />
      ) : (
        <CompanyAdminView companyId={selectedComp} companies={companies} />
      )}
    </Box>
  );
};

export default AppCompanyReport;
