import React from 'react';
import { DateTime } from 'luxon';

import { GraphQLResult } from '@aws-amplify/api';
import { useDispatch } from '../store/Orders';
import {
  getOrdersByDeliveryDate,
  IOrder,
  getOrdersByDateRangeAndCompanyId,
  getOrdersByDateRange,
  deleteOrder as removeOrder,
} from '../api/Order';
import {
  GetOrdersByDateRangeAndCompanyIdInput,
  GetOrdersByDateRangeAndCompanyIdQuery,
  GetOrdersByDateRangeInput,
  GetOrdersByDateRangeQuery,
  GsiTypeCompositeTokenInput,
  OrderStateEnum,
} from '../api/API';
import useNotificationActions from './useNotificationActions';

interface IReturn {
  fetchByDate(date: DateTime, isRefetch: boolean): void;
  fetchByDateRangeAndCompany(
    input: GetOrdersByDateRangeAndCompanyIdInput
  ): void;
  fetchByDateRange(input: GetOrdersByDateRangeInput): void;
  sortByCompany(orders: IOrder[]): IGroupedOrders;
  getDeliveryView(orders: IOrder[]): IDeliveryViewOrders;
  deleteOrder(orderId: string): Promise<void>;
}

export interface IGroupedOrders {
  [key: string]: IOrder[];
}

export interface IDeliveryViewOrderDetails {
  user: string;
  extras: string[];
  comment?: string;
}

export interface IDeliveryViewGroupedOrders {
  [key: string]: IDeliveryViewOrderDetails[];
}

export interface IDeliveryViewOrders {
  [companyName: string]: IDeliveryViewGroupedOrders;
}

const useOrderActions = (): IReturn => {
  const dispatch = useDispatch();
  const { show } = useNotificationActions();

  const fetchByDate = React.useCallback(
    async (date: DateTime, isRefetch: boolean) => {
      if (!isRefetch) {
        dispatch({ type: 'startLoading' });
      }
      try {
        const res = await getOrdersByDeliveryDate({
          deliveryDate: date.toString(),
        });
        if (res.data?.getOrdersByDeliveryDate?.data) {
          // filter unpaid orders
          const newOrders = res.data.getOrdersByDeliveryDate.data.filter(
            (o) => o?.orderState !== OrderStateEnum.UNPAID
          );
          dispatch({
            type: 'setOrders',
            orders: newOrders as IOrder[],
          });
        }
      } catch (error) {
        dispatch({
          type: 'setError',
          error: 'Bestellungen konnten nicht geladen werden',
        });
      }
    },
    [dispatch]
  );

  const fetchByDateRangeAndCompany = React.useCallback(
    async (input: GetOrdersByDateRangeAndCompanyIdInput) => {
      dispatch({ type: 'startLoading' });
      try {
        let allOrders: IOrder[] = [];
        let allFetched = false;
        let errorWhileFetching = false;
        let fetchToken: GsiTypeCompositeTokenInput | null = null;
        while (!allFetched) {
          // eslint-disable-next-line no-await-in-loop
          const res: GraphQLResult<GetOrdersByDateRangeAndCompanyIdQuery> = await getOrdersByDateRangeAndCompanyId(
            {
              ...input,
              nextToken: fetchToken,
            }
          );

          if (res.errors) {
            errorWhileFetching = true;
            allFetched = true;
          }

          if (res.data?.getOrdersByDateRangeAndCompanyId?.data) {
            // filter unpaid orders
            const newOrders = res.data.getOrdersByDateRangeAndCompanyId.data.filter(
              (o) => o?.orderState !== OrderStateEnum.UNPAID
            );
            allOrders = [...allOrders, ...(newOrders as IOrder[])];
          }

          if (res.data?.getOrdersByDateRangeAndCompanyId?.nextToken) {
            fetchToken = res.data?.getOrdersByDateRangeAndCompanyId?.nextToken;
          } else {
            allFetched = true;
          }
        }
        if (errorWhileFetching) {
          dispatch({
            type: 'setError',
            error: 'Bestellungen konnten nicht geladen werden',
          });
        } else {
          dispatch({
            type: 'setOrders',
            orders: allOrders,
          });
        }
      } catch (error) {
        dispatch({
          type: 'setError',
          error: 'Bestellungen konnten nicht geladen werden',
        });
      }
    },
    [dispatch]
  );

  const fetchByDateRange = React.useCallback(
    async (input: GetOrdersByDateRangeInput) => {
      dispatch({ type: 'startLoading' });
      try {
        let allOrders: IOrder[] = [];
        let allFetched = false;
        let errorWhileFetching = false;
        let fetchToken: GsiTypeCompositeTokenInput | null = null;
        while (!allFetched) {
          // eslint-disable-next-line no-await-in-loop
          const res: GraphQLResult<GetOrdersByDateRangeQuery> = await getOrdersByDateRange(
            {
              ...input,
              nextToken: fetchToken,
            }
          );

          if (res.errors) {
            errorWhileFetching = true;
            allFetched = true;
          }

          if (res.data?.getOrdersByDateRange?.data) {
            // filter unpaid orders
            const newOrders = res.data.getOrdersByDateRange.data.filter(
              (o) => o?.orderState !== OrderStateEnum.UNPAID
            );
            allOrders = [...allOrders, ...(newOrders as IOrder[])];
          }

          if (res.data?.getOrdersByDateRange?.nextToken) {
            fetchToken = res.data?.getOrdersByDateRange?.nextToken;
          } else {
            allFetched = true;
          }
        }
        if (errorWhileFetching) {
          dispatch({
            type: 'setError',
            error: 'Bestellungen konnten nicht geladen werden',
          });
        } else {
          dispatch({
            type: 'setOrders',
            orders: allOrders,
          });
        }
      } catch (error) {
        dispatch({
          type: 'setError',
          error: 'Bestellungen konnten nicht geladen werden',
        });
      }
    },
    [dispatch]
  );

  const getDeliveryView = (orders: IOrder[]): IDeliveryViewOrders => {
    const deliveryView: IDeliveryViewOrders = {};
    orders.forEach((order) => {
      const company = order.companyName;
      if (!deliveryView[company]) {
        deliveryView[company] = {};
      }
      order.articles.forEach((article) => {
        // if articlename is not in list, add articlename
        if (!deliveryView[company][article.name]) {
          deliveryView[company][article.name] = [];
        }
        deliveryView[company][article.name].push({
          user: order.userName,
          extras: article.modifiers.map((mod) => mod.name),
          comment: order.comment,
        });
      });
    });
    return deliveryView;
  };

  const sortByCompany = (orders: IOrder[]): IGroupedOrders => {
    const grouped: IGroupedOrders = {};
    orders.forEach((order) => {
      let groupName = order.companyName;
      if (order.isBatchOrder) {
        groupName = `${groupName} (Sammelbestellung)`;
      }
      if (!grouped[groupName]) {
        grouped[groupName] = [];
      }
      grouped[groupName].push(order);
    });
    return grouped;
  };

  const deleteOrder = async (orderId: string) => {
    try {
      await removeOrder({ orderId });
      dispatch({ type: 'deleteOrder', orderId });
      show('Gelöscht', 'Bestellung wurde entfernt', 'success');
    } catch (error) {
      show('Error', 'Bestellung konnte nicht gelöscht werden', 'error');
    }
  };

  return {
    fetchByDate,
    fetchByDateRangeAndCompany,
    fetchByDateRange,
    getDeliveryView,
    sortByCompany,
    deleteOrder,
  };
};

export default useOrderActions;
