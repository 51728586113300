/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Gdpr = (): JSX.Element => {
  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom>
        Datenschutzerklärung
      </Typography>
      <Typography
        color="textSecondary"
        variant="body1"
        style={{ whiteSpace: 'pre-line' }}
      >
        {`
        Wir haben diese Datenschutzerklärung (Fassung 05.03.2021-111671706) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
        
        Datenschutzerklärungen klingen für gewöhnlich sehr technisch. Diese Version soll Ihnen hingegen die wichtigsten Dinge so einfach und klar wie möglich beschreiben. Soweit es möglich ist, werden technische Begriffe leserfreundlich erklärt. Außerdem möchten wir vermitteln, dass wir mit dieser Website nur dann Informationen sammeln und verwenden, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.
        Wenn trotzdem Fragen bleiben, möchten wir Sie bitten den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen, oder uns einfach eine E-Mail zu schreiben. Unsere Kontaktdaten finden Sie im Impressum.
        
        Automatische Datenspeicherung
        Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Website. Diese gesammelten Daten sollten möglichst sparsam und nur mit Begründung gesammelt werden werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf Ihrer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.
        
        Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver – das ist der Computer auf dem diese Webseite gespeichert ist –  aus Gründen der Betriebssicherheit, zur Erstellung von Zugriffsstatistik usw. in der Regel automatisch Daten wie
        
        die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B. https://www.beispielwebsite.de/beispielunterseite.html/)
        Browser und Browserversion (z. B. Chrome 87)
        das verwendete Betriebssystem (z. B. Windows 10)
        die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/)
        den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)
        Datum und Uhrzeit
        in Dateien, den sogenannten Webserver-Logfiles.
        
        
        In der Regel werden diese Dateien zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.
        
        Kurz gesagt: Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht weiter!
        
        Speicherung persönlicher Daten
        Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
        
        Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        
        Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
        
        Rechte laut Datenschutzgrundverordnung
        Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen Datenschutzgesetzes (DSG) grundsätzlich die folgende Rechte zu:
        
        Recht auf Berichtigung (Artikel 16 DSGVO)
        Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
        Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
        Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
        Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
        Widerspruchsrecht (Artikel 21 DSGVO)
        Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie unter https://www.dsb.gv.at/ finden.
        
        TLS-Verschlüsselung mit https
        TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“) um Daten abhörsicher im Internet zu übertragen.
        Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist – niemand kann “mithören”.
        
        Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen.
        Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol  links oben im Browser links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.
        Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach “Hypertext Transfer Protocol Secure wiki” um gute Links zu weiterführenden Informationen zu erhalten.
        
        Google Fonts Datenschutzerklärung
        Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
        
        Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.
        
        Was sind Google Fonts?
        Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google Ihren Nutzern kostenlos zu Verfügung stellen.
        
        Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.
        
        Warum verwenden wir Google Fonts auf unserer Webseite?
        Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.
        
        Welche Daten werden von Google gespeichert?
        Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.
        
        Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.
        
        Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
        
        Wie lange und wo werden die Daten gespeichert?
        Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
        
        Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
        
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf https://support.google.com/?hl=de&tid=111671706 kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
        
        Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=111671706. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.
        
        Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf https://www.google.com/intl/de/policies/privacy/ nachlesen.
        
        Stripe Datenschutzerklärung
        Wir verwenden auf unserer Website ein Zahlungstool des amerikanischen Technologieunternehmens und Online-Bezahldienstes Stripe. Für Kunden innerhalb von der EU ist Stripe Payments Europe (Europe Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland) verantwortlich. Das heißt, wenn Sie sich für Stripe als Zahlungsart entscheiden, wird Ihre Zahlung über Stripe Payments abgewickelt. Dabei werden Daten, die für den Zahlungsvorgang nötig sind, an Stripe weitergeleitet und gespeichert. In dieser Datenschutzerklärung geben wir Ihnen einen Überblick über diese Datenverarbeitung und Speicherung durch Stripe und erklären, warum wir Stripe auf unserer Website verwenden.
        
        Was ist Stripe?
        Das Technologieunternehmen Stripe bietet Zahlungslösungen für Online-Bezahlungen an. Mit Stripe ist es möglich Kredit- und Debitkartenzahlungen in unserem Webshop zu akzeptieren. Stripe übernimmt den gesamten Zahlungsvorgang. Ein großer Vorteil von Stripe ist etwa, dass Sie während des Zahlungsvorgangs nie unsere Website bzw. den Shop verlassen müssen und die Zahlungsabwicklung sehr schnell erfolgt.
        
        Warum verwenden wir Stripe für unsere Website?
        Wir wollen natürlich mit unserer Website und unserem eingebundenen Onlineshop das bestmögliche Service bietet, damit Sie sich auf unserer Seite wohl fühlen und unsere Angebote nutzen. Wir wissen, dass Ihre Zeit kostbar ist und daher speziell Zahlungsabwicklungen schnell und reibungslos funktionieren müssen. Neben unseren anderen Zahlungsanbietern haben wir mit Stripe einen Partner gefunden, der eine sichere und schnelle Zahlungsabwicklung gewährleistet.
        
        Welche Daten werden von Stripe gespeichert?
        Wenn Sie sich für Stripe als Zahlungsart entscheiden, werden auch personenbezogene Daten von Ihnen an Stripe übermittelt und dort gespeichert. Dabei handelt es sich um Transaktionsdaten. Zu diesen Daten zählen etwa die Zahlungsmethode (also Kreditkarten- Debitkarten oder Kontonummer), Bankleitzahl, Währung, der Betrag und das Datum der Zahlung. Bei einer Transaktion kann weiters Ihr Name, Ihre E-Mail-Adresse, Ihre Rechnungs- oder Versandadresse und manchmal auch Ihr Transaktionsverlauf übermittelt werden. Diese Daten sind zur Authentifizierung nötig. Weiters kann Stripe zur Betrugsabwehr, der Finanzberichterstattung und um die eigenen Dienste vollständig anbieten zu können, auch neben technischen Daten zu Ihrem Gerät (wie IP-Adresse)  Name, Adresse, Telefonnummer und Ihr Land erfassen.
        
        Stripe verkauft keine Ihrer Daten an unabhängige Dritte, wie etwa Marketingagenturen oder andere Unternehmen, die mit dem Stripe-Unternehmen nichts zu tun haben. Die Daten können aber etwa an interne Abteilungen, einer beschränkten Anzahl externer Stripe-Partner oder zur Einhaltung gesetzlicher Vorschriften weitergeleitet werden. Stripe verwendet zur Erfassung von Daten auch Cookies. Hier finden Sie eine Auswahl an Cookies, die Stripe während des Zahlungsprozesses setzen kann:
        
        Name: m
        Wert: edd716e9-d28b-46f7-8a55-e05f1779e84e040456111671706-5
        Verwendungszweck: Dieses Cookie erscheint, wenn Sie die Zahlungsmethode auswählen. Es speichert und erkennt, ob Sie über einen PC, ein Tablet oder ein Smartphone auf unsere Website zugreifen.
        Ablaufdatum: nach 2 Jahren
        
        Name: __stripe_mid
        Wert: fc30f52c-b006-4722-af61-a7419a5b8819875de9111671706-1
        Verwendungszweck: Um eine Kreditkartentransaktion durchführen zu können, wird dieses Cookie benötigt. Dazu speichert das Cookie Ihre Sitzungs-ID.
        Ablaufdatum: nach einem Jahr
        
        Name: __stripe_sid
        Wert: 6fee719a-c67c-4ed2-b583-6a9a50895b122753fe
        Verwendungszweck: Auch dieses Cookie speichert Ihre ID und wird für den Zahlungsprozess auf unserer Website durch Stripe verwendet.
        Ablaufdatum: nach Ablauf der Sitzung
        
        Wie lange und wo werden die Daten gespeichert?
        Personenbezogene Daten werden grundsätzlich für die Dauer der Diensterbringung gespeichert. Das heißt, die Daten werden so lange gespeichert, bis wir die Zusammenarbeit mit Stripe auflösen. Um allerdings die gesetzlichen und behördlichen Pflichten zu erfüllen kann Stripe auch über die Dauer der Diensterbringung personenbezogene Daten speichern. Da Stripe ein weltweit tätiges Unternehmen ist, können die Daten auch in jedem Land, wo Stripe Dienstleistungen anbietet, gespeichert werden. So können auch Daten außerhalb Ihres Landes, zum Beispiel in den USA gespeichert werden.
        
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        Stripe ist nach wie vor Teilnehmer des EU-U.S. Privacy Shield Framework, wodurch bis 16. Juli 2020 der korrekte und sichere Datentransfer persönlicher Daten geregelt wurde. Nachdem der Europäische Gerichtshof das Abkommen für ungültig erklärt hat, stützt sich das Unternehmen nun nicht mehr auf dieses Abkommen, handelt aber noch nach den Prinzipien des Privacy Shields.
        
        Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen können Sie auch jederzeit das Stripe-Team über https://support.stripe.com/contact/email kontaktieren.
        
        Cookies, die Stripe für ihre Funktionen verwenden, können Sie in Ihrem Browser löschen, deaktivieren oder verwalten. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachten Sie aber, dass dann eventuell der Zahlungsvorgang nicht mehr funktioniert. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        
        Chrome: Cookies in Chrome löschen, aktivieren und verwalten
        
        Safari: Verwalten von Cookies und Websitedaten mit Safari
        
        Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
        
        Internet Explorer: Löschen und Verwalten von Cookies
        
        Microsoft Edge: Löschen und Verwalten von Cookies
        
        Wir haben Ihnen nun einen allgemeinen Überblick über die Verarbeitung und Speicherung der Daten durch Stripe gegeben. Wenn Sie noch mehr und noch genauere Informationen einholen wollen, dient Ihnen die ausführliche Stripe-Datenschutzerklärung unter https://stripe.com/at/privacy als gute Quelle.
        
        Quelle: Erstellt mit dem Datenschutz Generator von AdSimple
        `}
      </Typography>
    </Box>
  );
};

export default Gdpr;
