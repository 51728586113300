import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import React from 'react';

import { DeleteUserInput } from '../../api/API';

interface IProps {
  input: DeleteUserInput;
  open: boolean;
  deleteUsr(input: DeleteUserInput): Promise<void>;
  close(): void;
}

const DeleteUserDialog = ({
  input,
  open,
  close,
  deleteUsr,
}: IProps): JSX.Element => {
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);
    await deleteUsr(input);
    setLoading(false);
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogContent>
        <DialogContentText id="alert-delete-user">
          User <b>{input.email}</b> löschen?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={loading} onClick={close}>
          Abbrechen
        </Button>
        <Button color="primary" disabled={loading} onClick={handleDelete}>
          {loading ? <CircularProgress size={20} /> : 'Löschen'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;
