import brauL from '../../static/images/interviews/reiningL.png';
import brauP from '../../static/images/interviews/brauP.png';
import donauP from '../../static/images/interviews/donauP.jpg';
import donauL from '../../static/images/interviews/donauL.jpg';
import polzP from '../../static/images/interviews/polzP.jpg';
import polzL from '../../static/images/interviews/polzL.jpg';
import poschP from '../../static/images/interviews/poschP.jpg';
import poschL from '../../static/images/interviews/poschL.jpg';
import sabathiL from '../../static/images/interviews/sabathiL.jpg';
import sabathiP from '../../static/images/interviews/sabathiP.jpg';
import schoenP from '../../static/images/interviews/schoenP.jpg';
import schoenL from '../../static/images/interviews/schoenL.jpg';
import schwarzL from '../../static/images/interviews/schwarzL.jpg';
import schwarzP from '../../static/images/interviews/schwarzP.jpg';
import teisslL from '../../static/images/interviews/teisslL.jpg';
import teisslP from '../../static/images/interviews/teisslP.jpg';
import tinnauerL from '../../static/images/interviews/tinnauerL.jpg';
import tinnauerP from '../../static/images/interviews/tinnauerP.jpg';
import tombergerL from '../../static/images/interviews/tombergerL.jpg';
import tombergerP from '../../static/images/interviews/tombergerP.jpg';
import zueggP from '../../static/images/interviews/zueggP.jpg';
import zueggL from '../../static/images/interviews/zueggL.jpg';
import zweytickP from '../../static/images/interviews/zweytickP.jpg';
import zweytickL from '../../static/images/interviews/zweytickL.jpg';

export interface IDialog {
  question: string;
  answer: string;
}

export interface IInterview {
  route: string;
  title: string;
  profession: string;
  dialog: IDialog[];
  quote: string;
  thanksTo: string;
  thanksAddress: string;
  imgLandscape?: string;
  imgPortrait?: string;
}

interface IInterviewData {
  [key: string]: IInterview;
}

export const data: IInterviewData = {
  // BRAUUNION
  brauunion: {
    route: 'brauunion',
    title: 'Reininghaus - Brauunion',
    profession: 'Bierlieferant',
    dialog: [
      {
        question:
          'Was hat man euch zum Reininghaus Jahrgangspils noch nie gefragt?',
        answer:
          'Schmeckt das überhaupt? – meine persönliche Erklärung: Reininghaus bürgt für Qualität!',
      },
      {
        question: 'Welche Hopfensorten werden bevorzugt dafür gebraut?',
        answer:
          'Es wird ausschließlich Celeja-Hopfen aus dem Raum Leutschach in der Südsteiermark verarbeitet.',
      },
      {
        question: 'Wer ist für den Hopfenanbau verantwortlich?',
        answer:
          'Wir arbeiten schon seit Jahren mit ausgewählten Hopfenbauern aus der Region Leutschach zusammen.',
      },
      {
        question:
          'Warum ist ausgerechnet der „Leutschacher Raum“ für euer Jahrgangspils ideal?',
        answer:
          'Die klimatischen Einflüsse durch warme Tage und kühle Nächte sowie die gegebenen Bodenverhältnisse unterstützen die einzigartigen gewünschten Hofpenaromen.',
      },
      {
        question: 'Was macht das Jahrgangspils so besonders?',
        answer:
          'Es ist regional sortentypisch und jährlich witterungsbedingt ein individuelles Pils mit Charakter.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über euch oder das Jahrgangspils wissen sollten?',
        answer:
          'Am Gaumen präsentiert sich speziell der Jahrgang 2020 mit einem schlanken Malzkörper, der einen ausgewogenen Kontrapunkt zur sehr deutlichen Hopfenbittere setzt.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Ein edles Getränk verlangt ein edles Glas – dafür hat Reininghaus eigens das Pilsglas mit Stil entwickelt.',
      },
    ],
    quote: 'Jeder Jahrgang ein Charakterpils!',
    thanksTo: 'Friedrich Wallner, Brauunion',
    thanksAddress:
      'Reininghaus Jahrgangspils – Brauunion, Leutschach Südsteiermark, Graz',
    imgLandscape: brauL,
    imgPortrait: brauP,
  },
  // DONABAUM
  donabaum: {
    route: 'donabaum',
    title: 'Weingut Johann Donabaum',
    profession: 'Weinlieferant',
    dialog: [
      {
        question:
          'Was hat man dich zu deinen Weinen/deinem Weingut noch nie gefragt?',
        answer:
          'Zu meinen Weinen hat man mich schon nach ziemlich allem befragt – vom möglichen Sauerwurmbefall, über analytische Werte bis hin zur Lagerfähigkeit – alles war schon mal dabei. Zum Weingut: Es hat mich noch nie jemand gefragt was ich verlange falls ich mal verkaufe ;-)!',
      },
      {
        question:
          'Welche Jahreszeit ist für euch im Keller und Weingarten die größte Herausforderung und warum?',
        answer:
          'Mit Sicherheit die Lesezeit. Mitte September bis Mitte November heißt es konzentriert sein. Wenn uns in dieser Phase ein Fehler passiert dann brauchen wir genau ein Jahr um ihn auszubessern. Bei Köchen geht das schneller.',
      },
      {
        question:
          'Nach welchen Kriterien, wann und wo fällt bereits die Entscheidung für Steinfeder, Federspiel oder Smaragd?',
        answer:
          'Die Wachauer Winzer setzen seit jeher auf Ihre Lagen. Smaragdweine können nur aus den besten Lagen erzeugt werden. Ein Federspielwein wächst auch in Weingärten entlang der Donau. Aber ein guter Smaragdwein braucht die Mineralität einer großen Lage. Mit diesen 3 Kategorien wollen wir vor allem die Stilistik unserer Weine betonen. Ein Kunde wird sich keinen kräftigen Wein erwarten, wenn er ein Federspiel bestellt, Diese Weine sollen frisch, elegant und trinkanimierend sein. Bei den Smaragdweinen sucht man allerdings sehr wohl Tiefgang, Lagerfähigkeit und den Ausdruck der Riede.',
      },
      {
        question:
          'Worauf wird bei euch das höchste Augenmerk im Weingarten gelegt?',
        answer:
          'Die Qualität des Weines entsteht im Weingarten – Ein Satz der überall so zu lesen ist, aber er stimmt. Dementsprechend qualitätsorientiert arbeiten wir. Rebschnitt, Ausdünnung, Laubarbeit, alles dient dazu um höchste Qualität zu erzeugen. In den letzten Jahren haben wir aber auch vermehrt unter unsere Rebstöcke geschaut. Der Boden ist eine wichtige Basis für die Qualität der Trauben. Dementsprechend haben wir hier Analysen gemacht um das Bodenleben und die Balance wiederherzustellen zum können.',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer:
          'Eine Besonderheit ist vor allem unser Gebiet. Der Spitzer Graben ist das kühlste Gebiet der Wachau. Durch die Klimaerwärmung hat unser Gebiet am stärksten profitiert. Die Weine des Spitzer Grabens zeigen auch in warmen Jahrgängen Finesse und Eleganz.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über dich und/oder deine Weine wissen sollten?',
        answer:
          'Meine Weine sind vielleicht nicht in der Jugend die lautesten dafür altern sie aber umso famoser. Mit dem Zubau eines neuen Lagerkellers wollen wir das auch in Zukunft unseren Kunden zeigen und kleine Mengen an perfekt gereiften Smaragd Weinen nach 5 oder 10 Jahren Lagerung wieder auf den Markt bringen.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Bei uns am Weingut gibt es auch eine Ferienwohnung. Ideal um die Wachau und die Gegend zu erkunden. Tipp: Eine Wanderung von Spitz nach Mühldorf am Marivino Wanderweg, quer durch die Weinberge um die ganze Schönheit zu erfahren.',
      },
    ],
    quote:
      'Großer Wein ist einzigartig – Unverkennbar wie die Landschaft die ihn prägt, markant wie der Boden auf dem er wächst und unnachahmlich wie eine Handschrift die der Winzer dem Wein durch Gefühl und Intuition verleiht',
    thanksTo: 'Johann, Winzer',
    thanksAddress: 'Weingut Johann Donabaum, Spitz, Wachau',
    imgLandscape: donauL,
    imgPortrait: donauP,
  },
  // POLZ
  polz: {
    route: 'polz',
    title: 'Weingut Polz GmbH',
    profession: 'Weinlieferant',
    dialog: [
      {
        question:
          'Was hat man dich zu deinen Weinen/deinem Weingut noch nie gefragt?',
        answer: 'Ob ich nicht lieber Rotwein machen würde…',
      },
      {
        question:
          'Wie würdest du euren Weinstil, eure Weinphilosophie beschreiben?',
        answer:
          'Präzise, saftig, glasklar. Es ist ein Streben nach Exzellenz durch konsequentes, handwerkliches Tun.',
      },
      {
        question: 'Worauf achtet ihr bei den Weingartenarbeiten?',
        answer:
          'Höchste Prämisse ist ein nachhaltiges Bewirtschaften eines wertvollen Ökosystems. Keine Herbizide, keine Pestizide und auf dem Weg in eine biologisch-organische Bewirtschaftung.',
      },
      {
        question:
          'Wie würdest du in Stichworten den Prozess von der Rebe bis in die Flasche während 3 Weinjahren beschreiben?',
        answer:
          'Am Beispiel eines Lagenweins: Sanfter Rebschnitt – händische Ertragsreduktion – idealer Lesezeitpunkt – schonende Traubenverarbeitung – möglichst langer Hefekontakt (bis zu über 18 Monaten) – wenn möglich unfiltriert aber klar auf die Flasche – wieder Zeit bis zur idealen Trinkreife',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer:
          'Das Besondere jedes einzelnen Weinguts sind 2 Faktoren: Die Herkunft (Reben und Weingärten) und die Menschen. Das kann niemand kopieren!',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über dich und/oder deine Weine wissen sollten?',
        answer:
          'Dass man sich auf die Weine des Weingut Polz verlassen kann. In der Jugend die Frische, in der Reife die Tiefe!',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Neugierig bleiben und viele unterschiedliche Weine probieren. Es ist und bleibt ein wunderbares Abenteuer!!!',
      },
    ],
    quote: 'Es ist nichts riskanter als nichts zu riskieren!',
    thanksTo: 'Erich Polz, Weinbauer',
    thanksAddress: 'Weingut Polz GmbH, Grassnitzberg, Südsteiermark',
    imgLandscape: polzL,
    imgPortrait: polzP,
  },
  // POSCH
  posch: {
    route: 'posch',
    title: 'Familie Daniela und Peter Posch',
    profession: 'Hendllieferanten',
    dialog: [
      {
        question:
          'Ihr arbeitet generationenübergreifend – wie funktioniert das?',
        answer:
          'Zum Glück sehr gut - indem jede einzelne Fähigkeit altersunabhängig geschätzt und eingebracht wird. Daraus wird altbewährtes mit neuen Ideen verknüpft und weiterentwickelt.',
      },
      {
        question: 'Ihr pflegt Hybrid-Hendel – was heißt das?',
        answer:
          'Hybriden sind geschlechtsneutral. Um die hohe Qualität zu gewährleisten entnehmen wir unsere Hybriden zwischen der 6ten und 9ten Woche vor der Geschlechtsreife – diese beginnt ab der 13ten Woche.',
      },
      {
        question:
          'Welche Rolle spielt für euch das Thema Gesundheit in Verbindung mit eurem Betrieb?',
        answer:
          'Für uns ist es schon seit vielen Jahren selbstverständlich ohne Antibiotika auszukommen. Geflügel ist dennoch eine empfindliche Tierart. Um vor Krankheiten zu schützen arbeiten wir mit fermentierten Kräuterextrakten welche über das Futter gesprüht oder dem Trinkwasser beigegeben werden. Diese sind z.B. Oreganao, Thymian, Salbei, Vitame D, E, Kurkuma, Selen, etc. um nur einige zu nennen.',
      },
      {
        question: 'Was versteht ihr unter Nachhaltigkeit?',
        answer:
          'Von der Region für die Region – Vernetzung untereinander und diese ständig intensivieren und weiter aufbauen.',
      },
      {
        question: 'Was sehen Sie als Besonderheit?',
        answer:
          'Als Bauern in der Südsteiermark durch Verlässlich- und Persönlichkeit, Lebensmittelsicherheit gewährleisten zu können.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über euch/eure Produkte wissen sollten?',
        answer:
          'Unsere Hühner wachsen in fortschrittlichen Ställen mit reichlich Freiraum und viel Tageslicht auf. Stress und Tiertransporte kennen unsere Hühner nicht! Aufzucht, Schlachtung und Verarbeitung erfolgen am eigenen Hof in der Südsteiermark',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer: 'Eine selbstgemachte Hühnersuppe hilft immer!',
      },
    ],
    quote: '100% glückliche und gesunde Hühner.',
    thanksTo: 'Daniela',
    thanksAddress: 'Posch-Hendl, Triftweg 7, 8451 Heimschuh',
    imgLandscape: poschL,
    imgPortrait: poschP,
  },
  // SABATHI
  sabathi: {
    route: 'sabathi',
    title: 'Weingut Hannes Sabathi',
    profession: 'Weinlieferant',
    dialog: [
      {
        question:
          'Was hat man dich zu deinen Weinen/deinem Weingut noch nie gefragt?',
        answer:
          'Schuhgröße 😉 Da fällt mir wirklich nichts ein – es gibt nichts was mir schon gefragt wurde😉',
      },
      {
        question:
          'Welche Rebsorten sind für dich eine Herausforderung und warum?',
        answer:
          'Gelber Muskateller, er findet super Bedingungen in der Südsteiermark, ist aber eine Diva im Weingarten. Nicht nur die Rehe und Wespen mögen ihn – er ist auf den Regen sehr emfpindlich. Hier eine alljährliche hohe gesunde Reife zu bekommen ist immer eine Herausforderung.',
      },
      {
        question:
          'Wonach entscheidest du einen alten Rebstock durch einen neuen zu ersetzten und was beachtest du dabei?',
        answer:
          'Für mich hat ein alter Rebstock oberste Priorität – es werden nur Weingärten regeneriert wenn die Sorte nicht passt. Ansonsten wird immer die gleiche Sorte nachgesetzt und wir tun alles um den alten Weingarten zu erhalten.',
      },
      {
        question:
          'Was heißt für euch Perfektion – wie geht ihr am Weingut damit um?',
        answer:
          'Perfektion ist der Gleichklang von „Weingarten – Natur“ und „Keller – Mensch“.',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer:
          'Das wir in der Südsteiermark mit charaktervollen verschiedenen Böden von den Urmeeren gesegnet worden sind. Diese Vorkommen wollen wir ins Glas bringen.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über dich und/oder deine Weine wissen sollten?',
        answer:
          'Die Reinheit des Bodens einzufangen, den Charakter der aus ihm spricht zu portraitieren, das ist mein Verständnis von charaktervollem Wein.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Sauvignon Blanc Ried Kranachberg – die Lage wo ich aufgewachsen bin, als Kind in der Erde gegraben habe und somit für mich sehr besonders ist.',
      },
    ],
    quote:
      'Ein Leben ist zu kurz um guten Wein zu machen - ich versuche es trotzdem.',
    thanksTo: 'Hannes, Weinbauer',
    thanksAddress: 'Weingut Hannes Sabathi, Kranachberg, Südsteiermark',
    imgLandscape: sabathiL,
    imgPortrait: sabathiP,
  },
  // SCHÖNBERGER
  schoenberger: {
    route: 'schoenberger',
    title: 'Weingut Schönberger',
    profession: 'Weinlieferant',
    dialog: [
      {
        question:
          'Was hat man dich zu deinen Weinen/deinem Weingut noch nie gefragt?',
        answer:
          'Was ich am liebsten im Weingarten mache? Antwort: Weintrauben naschen',
      },
      {
        question: 'Welche Rebsorten findet man in deinen Weingärten?',
        answer:
          'Viel Blaufränkisch. Grüner Veltliner, Neuburger, Chardonnay, Pinot Blanc, Sauvignon Blanc.',
      },
      {
        question:
          'Worauf achtest du bei der Auswahl deiner Fässer für die Weinreifung?',
        answer:
          'Gute Holzqualität, das heißt lange luftgetrocknetes Holz (Eiche), gute Verarbeitung, wenig Holzgeschmack. Wir verwenden sehr wenig neue Fässer und pflegen unsere alten Fässer.',
      },
      {
        question:
          'Was heißt für euch Nachhaltigkeit und Klimaschutz – wie geht ihr am Weingut damit um?',
        answer:
          'Es heißt für uns bio-dynamische Bewirtschaftung unsere Weingärten und das seit Jahrzenten. Begrünung im Weingarten, keine Bewässerung.',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer:
          'Dass wir unseren Weinen lange Zeit geben um zu reifen. Wenig in den Wein eingreifen.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über dich und/oder deine Weine wissen sollten?',
        answer:
          'Sie schmecken solo sehr gut aber zum Essen schmecken sie noch besser!',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Unsere 3 Lagen Blaufränkisch. Alte Haid, Kräften, Lehmgrube. Sie zeigen sehr schön welchen Einfluss der Boden auf den Wein hat.',
      },
    ],
    quote: 'Guter Wein und gutes Essen gehört einfach zusammen!',
    thanksTo: 'Günther',
    thanksAddress:
      'Weingut Schönberger, Mörbisch (Weinbaugebiet Leithaberg DAC), Burgenland',
    imgLandscape: schoenL,
    imgPortrait: schoenP,
  },
  // SCHWARZBAUER
  schwarzbauer: {
    route: 'schwarzbauer',
    title: 'Familie Schwarzbauer',
    profession: 'Rind- Schwein- und Kürbiskernlieferanten',
    dialog: [
      {
        question:
          'Wie lange wird euer Betrieb schon als Familienbetrieb geführt?',
        answer:
          'Unser Betrieb wird in dritter Generation seit 1994 als Milchviehbetrieb in Hengsberg bei Wildon geführt.',
      },
      {
        question: 'Was wird verarbeitet?',
        answer:
          'Wir verarbeiten Rind- und Schweinefleisch zu köstlichen Produkten.',
      },
      {
        question: 'Welche Rassen haltet ihr am Hof?',
        answer: 'Wir pflegen Fleckvieh (Rinder) und Edelsauen (Schweine).',
      },
      {
        question: 'Wie wachsen die Tiere auf?',
        answer: 'Sie wachsen am Hof und den umliegenden Weiden auf.',
      },
      {
        question: 'Was sehen Sie als Besonderheit?',
        answer:
          'Die Tiere werden mit selbst angebauten Futtermitteln versorgt. Die Schlachtung und Verarbeitung erfolgt stressfrei und ohne Transportwege direkt am Hof.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über euch wissen sollten?',
        answer:
          'Wir erzeugen bestes, mehrfach prämiertes steierisches Kürbiskernöl g.g.A. Der Anbau und die Ernte der Ölkürbisse sowie die Trocknung der Kürbiskerne erfolgen in Eigenleistung. Alle sechs Wochen werden unsere Kerne bei der Ölmühle Birnstingl in Hitzendorf zu frischem Öl verpresst.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          '2016 haben wir unsere Produktpalette nochmals erweitert. Es sind viele vegane Produkte, Säfte, Marmeladen und Süßspeisen dazu gekommen. Diese werden unter dem Namen „Doris bäckt was schmeckt“ vermarktet. Alle Produkte werden in familiärer Handarbeit und mit viel Liebe und Sorgfalt hergestellt.',
      },
    ],
    quote: 'Handarbeit schmeckt man!',
    thanksTo: 'Doris',
    thanksAddress: 'Familie Schwarzbauer, Kehlsdorf 20, 8411 Hengsberg',
    imgLandscape: schwarzL,
    imgPortrait: schwarzP,
  },
  // TEISSL
  teissl: {
    route: 'teissl',
    title: 'Sulmtaler Frischei Teissl',
    profession: 'Eierlieferant',
    dialog: [
      {
        question: 'Wie lange gibt es euren Betrieb schon?',
        answer:
          'Mehrere Generationen. Wie bei uns in der Gegend üblich waren auch wir früher eine gemischte Landwirtschaft mit Kühen, Schweinen und Hühnern.',
      },
      {
        question: 'Wann hat die Spezialisierung stattgefunden?',
        answer:
          '1984 in 2ter Generation als Selbstvermarkter haben wir uns auf die Freiland- und Bodenhaltung konzentriert.',
      },
      {
        question: 'Wo liegt euer Hof?',
        answer:
          'Im „Naturpark südsteirisches Weinland“ im Sulmtal in der Steiermark.',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer:
          'Der klimatische Einfluss und die gesunden Böden bzw. Wiesen garantieren ein natürliches Wachstum. Die Fütterung der Hühner für das frische Ei wird unter strengen Auflagen des AMA-Gütesiegels mit vorzugsweise regionalem Getreide gewährleistet.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über euch wissen sollten?',
        answer:
          'Nur persönliches Engagement kann die pünktlichen Frischelieferungen sicherstellen.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Achten Sie immer woher ihr Frühstücks-, Sonntags-, tägliches Ei kommt – es lohnt sich.',
      },
    ],
    quote: 'Die Nähe garantiert die Frische!',
    thanksTo: 'Remo',
    thanksAddress: 'Sulmtaler Frischei Teissl, Triftweg 1, 8451 Heimschuh',
    imgLandscape: teisslL,
    imgPortrait: teisslP,
  },
  // TINNAUER
  tinnauer: {
    route: 'tinnauer',
    title: 'Fruchtbrennerei Franz Tinnauer',
    profession: 'Edelbrände',
    dialog: [
      {
        question: 'Was hat man dich zu eurer Fruchtbrennerei noch nie gefragt?',
        answer: 'Man hat mich noch nie nicht befragt ;-)',
      },
      {
        question:
          'Welche Obstsorten bevorzugt ihr für die klassische Edelbrandgewinnung und mit welchen experimentiert ihr?',
        answer:
          'Vorwiegend arbeiten wir mit unserem eigenen heimischen Obst. Das heißt wir bauen unser Obst selbst an, haben selbst in der Hand wieviel der Baum trägt und können so die Qualität produzieren die uns wichtig ist. Vorwiegend sind das Rote Williamsbirne, Williamsbirne, versch. Äpfel, Zwetschken, Kriecherl, Rote Weingartenpfirsiche. Wir experimentieren aber auch gerne mit exotischen Produkten, wie die Yuzu (ostasiatische Zitrusfrucht).',
      },
      {
        question:
          'Spielt die Herkunft und der Boden auf dem das Obst wächst für die Gewinnung von Edeldestillaten ebenso eine große Rolle wie im Weinbau – worauf wird geachtet?',
        answer:
          'Ja, auf jeden Fall. Beim Obst ist der Boden mindestens genauso wichtig wie im Weinbau. So ist das Obst auch in jedem Jahr anders. Da spielen Wetter, Boden und Klima eine sehr große Rolle.',
      },
      {
        question: 'Wie verträgt sich hochprozentiges mit der Gesundheit?',
        answer:
          'Edelbrände sind basisch und haben schon einmal den Vorteil gegenüber Wein, dass sie nicht sauer sind. Zudem kann ein Schluck hochwertiger Brand VOR der Mahlzeit durchaus die Verdauung anregen und die Magensäfte vorab aktivieren. So hat man gleich Appetit und verträgt zum Beispiel fettiges Essen etwas leichter.',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer:
          'Die Besonderheit liegt für mich darin, dass es für mich etwas Magisches hat, aus hochwertigstem Obst, ein solch aromatisches Konzentrat in klarer Form zu zaubern.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über dich und/oder deine Brände wissen sollten?',
        answer:
          'Wir sind ein Familienbetrieb der auf höchste Qualität setzt und sich stehst versucht weiter zu entwickeln. Neues auszuprobieren gehört da natürlich dazu. Seit mittlerweile drei Jahren haben wir im Obstbau zahlreiche Unterstützung durch tausende fleißiger Bienen. Somit gibt es herrlich himmlischen Honig und wir haben in der Blütezeit die perfekten Helfer in den Obstanlagen.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Für all jene die sich mit puren hochprozentigen Bränden schwertun, kann ich herrlich erfrischende Drinks mit edlen Bränden empfehlen. So wie zum Beispiel Williamsbirnenbrand mit Tonic und Eiswürfel.',
      },
    ],
    quote:
      'Aus einwandfreien gesunden Obst, kann qualitativ und geschmacklich hochwertiger Edelbrand entstehen.',
    thanksTo: 'Monika Tinnauer, Jungunternehmerin',
    thanksAddress: 'Fruchtbrennerei Franz Tinnauer, Gamlitzberg, Südsteiermark',
    imgLandscape: tinnauerL,
    imgPortrait: tinnauerP,
  },
  // TOMBERGER
  tomberger: {
    route: 'tomberger',
    title: 'Tomberger Kühlkost',
    profession: 'Kühlkost',
    dialog: [
      {
        question: 'Wann bevorzugst du selbst Frischeprodukte vor Tiefkühlkost?',
        answer:
          'Frischeprodukte machen nur dann Sinn, wenn Sie saisonal, regional und nachhaltig angeboten werden.',
      },
      {
        question: 'Wie erklärst du Kindern die Vorzüge von Tiefkühlkost?',
        answer:
          'Das Tiefkühlen von Lebensmittel ist die Urform des Haltbarmachens. Ohne chemische Prozesse zu durchlaufen kann das Produkt (egal welches - Fisch, Fleisch oder Gemüse,) nach mehreren Monaten bedenkenlos verspeist werden, so kann Lebensmittelverschwendung vorgebeugt werden. Die sogenannte Tiefkühlkost entsteht durch Schockfrostung, blitzartiges einfrieren bei mindestens -40°C.',
      },
      {
        question: 'Wie gesund ist Tiefkühlkost? ',
        answer:
          'Das Schockfrosten stoppt den Zellstoffwechsel bei verderblichen Lebensmitteln. So können sich Mikroorganismen nicht vermehren und das Tiefkühlprodukt bleibt hygienisch einwandfrei ohne Unterbrechung der Tiefkühlkette.',
      },
      {
        question:
          'Welche Prozesse – egal ob bei Beschaffung oder Verarbeitung – sind dir heilig?',
        answer:
          'Vom Anbau oder Aufzucht zur Verarbeitung, über die Lagerung und den Transport bis hin zur Zubereitung in der Küche – „heilig“ ist der sorgsame Umgang mit Lebensmittel.',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer:
          'Die Besonderheit bei tiefgekühlten Lebensmitteln ist die Qualität. Ein Beispiel ist die Gemüseernte, vom Feld in den Schockfroster in unter einer Stunde, so bleiben alle Inhaltsstoffe (Vitamine, Mineralien und Co.) zur Gänze erhalten, anders als bei nicht saisonalen/regionalen Produkten, die meist halbreif geerntet werden und/oder schon 2 Tage im Lager liegen und durch den Zellstoffwechsel die gesunden und nahrhaften Inhaltsstoffe verlieren. Ein weiteres Beispiel ist Fisch. Die Verarbeitung von Tiefkühl-Fisch findet meist direkt nach dem Einholen der Netze am Schiff statt. Der Fisch wird entschuppt, ausgenommen, filetiert, schockgefrostet und verpackt, sodass er am Zielhafen bereit für die Verladung ist.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über dich und/oder deine Brände wissen sollten?',
        answer:
          'Die kulinarischen Ansprüche sind in den vergangenen 15 Jahren sehr gewachsen, von der asiatischen Garnelen-Pfanne, über das Dry-Aged Tomahawk Steak, von veganen Strudelvarianten, zu exotischen Früchte-Bowls bis hin zu heimischen Wild-Klassikern ist alles begehrt und das ist auch gut so! Durch unser 1.800 m² Tiefkühllager in Kalsdorf, einer lückenlosen Temperaturaufzeichnung der Einlagerung, des Transports und einem Temperatur-Ausdruck bei der Lieferung garantieren wir mit unseren fachlich geschulten Mitarbeitern/innen die hohe Qualität unserer Tiefkühl-Lebensmittel.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer: 'Bestellt die Karte rauf und runter, ich weiß hier schmeckt’s!',
      },
    ],
    quote:
      'Der Handel mit Tiefkühl-Lebensmittel ist nicht unser Geschäft, weil wir es sagen, sondern weil wir es leben.',
    thanksTo: 'Matthias Tomberger, Geschäftsführer und Tiefkühlspezialist',
    thanksAddress: 'Tomberger Kühlkost GmbH, Kalsdorf bei Graz',
    imgLandscape: tombergerL,
    imgPortrait: tombergerP,
  },
  // ZUEGG
  zuegg: {
    route: 'zuegg',
    title: 'Obsthof Zuegg',
    profession: 'Fruchtsäfte',
    dialog: [
      {
        question: 'Welche Obstsorten werden bei euch verarbeitet?',
        answer:
          'Eigentlich fast alles was in der Steiermark wächst. Apfel, Birne, Trauben, Pfirsich, Marille, Weichsel, Aronia, Johannisbeere, Holunder, Himbeere, Stachelbeere, usw.',
      },
      {
        question:
          'Wie erklärst du Kindern den Unterschied zwischen Fruchtsäften und Fruchtnektaren?',
        answer:
          'In den Fruchtsäften ist zu 100% Fruchtsaft. Bei Nektar, zb: Pfirsich, muss man 50% Wasser und etwas Zucker und Zitronensäure dazugeben damit es gut trinkbar ist und auch nicht zu dickflüssig. Man würde reines Pfirsichmark nicht in die Flasche bekommen.',
      },
      {
        question:
          'Worauf muss im Laufe einen Jahres alles geachtet werden – welche Arbeitsschritte sind erforderlich?',
        answer:
          'Es fängt beim Bäume schneiden im Winter an. Wenn notwendig Frostberegnen, weiters die Hagelschutznetze aufmachen, Pflanzenschutz betreiben, eventuell Bewässern, laufende Kontrollen ob mit den Pflanzen alles in Ordnung ist. Zum Schluss wird dann geerntet und verkauft.',
      },
      {
        question:
          'Wie verhält es sich mit der Haltbarkeit von Säften und Nektaren – Zucker und Gesundheit?',
        answer:
          'Die Fruchtsäfte werden durch schonendes Erhitzen haltbargemacht und halten dadurch einige Jahre. Man kann die Produkte weit über das Mindesthaltbarkeitsdatum konsumieren.',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer:
          'Wir haben eine sehr große Produktpalette und wir verarbeiten alles selbst und versuchen alles in top Qualität zu produzieren. Wir haben angefangen von Fruchtsäften, Nektaren, Sirupe, Marmeladen, Chutneys, Wein, Most, Frizzante bis Pestos in unserem Hofladen zu verkaufen.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über dich und/oder deine Weine wissen sollten?',
        answer:
          'Alle Produkte sind ohne Konzentrate oder Geschmacksverstärker produziert.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Alle Produkte sind in höchster Qualität zu fairen Preisen zu haben.',
      },
    ],
    quote:
      '„Das Durchschnittliche gibt der Welt ihren Bestand, das Außergewöhnliche ihren Wert.“- Oscar Wilde',
    thanksTo: 'Christian Zuegg, Obstbauer',
    thanksAddress: 'Obsthof-Zuegg, Eckberg, Gamlitz Südsteiermark',
    imgLandscape: zueggL,
    imgPortrait: zueggP,
  },
  // ZWEYTICK
  zweytick: {
    route: 'zweytick',
    title: 'Weingut Ewald Zweytick',
    profession: 'Weinlieferant',
    dialog: [
      {
        question:
          'Was hat man dich zu deinen Weinen/deinem Weingut noch nie gefragt?',
        answer:
          'Ob ich nicht lieber mit Axl Rose (Guns n‘ Roses) auf der Bühne stehen würde.',
      },
      {
        question:
          'Was verbirgt sich hinter Tosca, November Rain, Don’t Cry und Heavens Door?',
        answer: 'Grauburgunder, Morillon und zwei Sauvignon Blancs.',
      },
      {
        question:
          'Worauf achtest du bei der Auswahl und Pflanzung eines neuen Rebstocks?',
        answer:
          'Für welche Lage (Bodenverhältnisse), Rebsorte – Herkunft (Gesundheit), klimatische Bedingungen für das Wachstum und welchen Wein ich später daraus machen möchte.',
      },
      {
        question:
          'Unter welchen Einflüssen wachsen deine Rebstöcke bzw. Trauben?',
        answer:
          'Ich kenne, pflege und hege jeden einzelnen Rebstock in meinen Weingärten. Die Beobachtung der Witterung und das Gespür dafür lässt mich meistens zeitnah reagieren und tun was die Rebstöcke für ein optimales Wachstum benötigen (z.B. Rebschnitt, Laubarbeit, etc.).',
      },
      {
        question: 'Was siehst du als Besonderheit?',
        answer: 'Im großen Kreislauf der Natur mitspielen zu dürfen.',
      },
      {
        question:
          'Was gibt es sonst noch was unsere Gäste über dich und/oder deine Weine wissen sollten?',
        answer:
          'Ich bin ein Fan der Vielfalt. Egal ob es sich um einen leichten sommerlichen Welschriesling handelt oder einen kräftigen älteren Sauvignon blanc – reif muss der Wein sein.',
      },
      {
        question: 'Was dürfen wir als „Tipp“ an unsere Gäste weitergeben?',
        answer:
          'Trinkt was euch schmeckt unabhängig von Bewertungen und Beschreibungen. Verlasst euch auf euren persönlichen Geschmack – der ist immer richtig.',
      },
    ],
    quote: 'Ich mache den Wein der mir schmeckt, den Rest verkaufe ich.',
    thanksTo: 'Ewald',
    thanksAddress:
      'Weingut Ewald Zweytick, Ratsch an der Weinstraße, Südsteiermark',
    imgLandscape: zweytickL,
    imgPortrait: zweytickP,
  },
};
