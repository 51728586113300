import React from 'react';

import { getDownloadUrl } from '../api/Storage';
import { useTrackedState } from '../store/ImageCache';
import useImageCacheActions from './useImageCacheActions';
import Logo from '../static/images/ccgmplogo.png';

interface IProps {
  key: string;
  isGuest: boolean;
}

interface IReturn {
  url: string;
  loading: boolean;
}

const useFileDownload = ({ key, isGuest }: IProps): IReturn => {
  const { images: cached } = useTrackedState();
  const { addImage } = useImageCacheActions();
  const [loading, setLoading] = React.useState(false);
  const [url, setUrl] = React.useState('');

  React.useEffect(() => {
    const fetch = async () => {
      if (!url) {
        setLoading(true);
        if (key) {
          if (cached[key]) {
            setUrl(cached[key]);
          } else {
            const res = await getDownloadUrl({ key }, isGuest);
            if (res.data?.getDownloadUrl?.data) {
              addImage(key, res.data.getDownloadUrl.data);
              setUrl(res.data.getDownloadUrl.data);
            }
          }
        } else {
          setUrl(Logo);
        }
        setLoading(false);
      }
    };
    fetch();
  }, [key, isGuest, cached, addImage, url]);

  return {
    loading,
    url,
  };
};

export default useFileDownload;
