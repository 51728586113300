import { IArticle } from '../../api/Article';
import useNotificationActions from '../../hooks/useNotificationActions';
import { useTrackedState as useCartState, useDispatch } from '../../store/Cart';

interface IReturn {
  addItem(newItem: IArticle): void;
  removeItem(toRemoveId: string): void;
  removeAllItems(toRemoveId: string): void;
  clearCart(): void;
}

const useCartActions = (): IReturn => {
  const dispatch = useDispatch();
  const { items } = useCartState();
  const { show } = useNotificationActions();

  const addItem = (newItem: IArticle): void => {
    dispatch({ type: 'startLoading' });
    dispatch({ type: 'addItem', newItem });
    show(
      'Hinzugefügt',
      `${newItem.name} wurde zum Warenkorb hinzugefügt`,
      'success'
    );
  };

  const removeItem = (toRemoveId: string) => {
    dispatch({ type: 'startLoading' });
    const newItems = [...items];
    const toRemove = newItems.find((item) => item.id === toRemoveId);
    if (toRemove) {
      const index = newItems.indexOf(toRemove);
      if (index > -1) {
        newItems.splice(index, 1);
        dispatch({ type: 'setItems', newItems });
        show('Artikel entfernt', `Artikel wurde entfent`, 'info');
      }
    }
  };

  const removeAllItems = (toRemoveId: string) => {
    dispatch({ type: 'removeAllItems', toRemoveId });
    show('Artikel entfernt', `Artikel wurde entfent`, 'info');
  };

  const clearCart = () => {
    dispatch({ type: 'clearCart' });
  };

  return {
    addItem,
    removeItem,
    removeAllItems,
    clearCart,
  };
};

export default useCartActions;
