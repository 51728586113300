import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cardContent: {
    minHeight: '300px',
  },
  menuTypeLabel: {
    marginTop: '10px',
    fontWeight: 'bold',
  },
  navButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
