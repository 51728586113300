import API, { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import {
  GetAllArticlesByOrderIdInput,
  GetAllArticlesQuery,
  TriggerArticleSyncMutation,
  UpdateArticleInput,
  UpdateArticleMutation,
} from './API';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import callGraphQL from './GraphQLOperation';

export interface IModifier {
  allergens: string;
  description: string;
  grossPrice: number;
  id: string;
  isActive: boolean;
  modifierId: string;
  modifierIgnorePrice: boolean;
  modifierMax: number;
  modifierMin: number;
  modifierName: string;
  name: string;
  sort: number;
  tax: number;
}

export interface IArticle {
  allergens: string;
  articleGroupFromHour: string;
  articleGroupMelzerId: string;
  articleGroupName: string;
  articleGroupToHour: string;
  description: string;
  grossPrice: number;
  hasThermobox: boolean;
  maximumOrders: number;
  id: string;
  imageId: string;
  isDeprecated: boolean;
  isLocked: boolean;
  isMainDish: boolean;
  melzerId: string;
  modifiers: IModifier[];
  name: string;
  tax: number;
}

export const getAllArticles = async (
  guest: boolean
): Promise<GraphQLResult<GetAllArticlesQuery>> => {
  return (await API.graphql({
    query: queries.getAllArticles,
    authMode: guest
      ? GRAPHQL_AUTH_MODE.AWS_IAM
      : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<GetAllArticlesQuery>;
};

export const getArticlesByOrderId = (
  input: GetAllArticlesByOrderIdInput
): Promise<GraphQLResult<GetAllArticlesByOrderIdInput>> => {
  return callGraphQL<GetAllArticlesByOrderIdInput>(
    queries.getArticlesByOrderId,
    {
      variables: { input },
    }
  );
};

export const updateArticle = (
  input: UpdateArticleInput
): Promise<GraphQLResult<UpdateArticleMutation>> => {
  return callGraphQL<UpdateArticleMutation>(mutations.updateArticle, {
    variables: { input },
  });
};

export const triggerSync = (): Promise<
  GraphQLResult<TriggerArticleSyncMutation>
> => {
  return callGraphQL<TriggerArticleSyncMutation>(mutations.triggerArticleSync);
};
