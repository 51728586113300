import * as authApi from '../api/Auth';
import { getUserByEmail, IUser } from '../api/User';
import { getCompanyByCompanyId, ICompany } from '../api/Company';
import { RoleEnum, useDispatch } from '../store/Auth';
import useNotificationActions from './useNotificationActions';

interface IReturn {
  checkSession(): Promise<void>;
  signIn(email: string, password: string): Promise<void>;
  signOut(): Promise<void>;
  activateGuestMode(): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  completeNewPw(newPw: string, cognitoUser: any): Promise<void>;
  sendConfirmationCode(email: string): Promise<void>;
  confirmResetPw(code: string, newPw: string, userMail: string): Promise<void>;
  resetAuthState(): Promise<void>;
  forgotPassword(): void;
}

const useAuthActions = (): IReturn => {
  const dispatch = useDispatch();
  const { show: showNotification } = useNotificationActions();

  const getUserDetails = async (email: string): Promise<IUser> => {
    const res = await getUserByEmail({ email });
    return res.data?.getUserByEmail?.data as IUser;
  };

  const getUserCompanyDetails = async (id: string): Promise<ICompany> => {
    const res = await getCompanyByCompanyId({ id });
    return res.data?.getCompanyById?.data as ICompany;
  };

  const getUserRole = (groups: string[]): RoleEnum => {
    const userGroups = groups as string[];
    if (userGroups.includes('ccg-mp-company-admin-group')) {
      return RoleEnum.COMPANYADMIN;
    }
    if (userGroups.includes('ccg-mp-admin-group')) {
      return RoleEnum.ADMIN;
    }
    return RoleEnum.USER;
  };

  const activateGuestMode = () => {
    dispatch({ type: 'loadingStart' });
    dispatch({ type: 'activateGuestMode' });
  };

  const signIn = async (email: string, password: string) => {
    dispatch({ type: 'loadingStart' });
    try {
      const res = await authApi.signin(email, password);
      // check if new pw is required
      if (
        res.challengeName &&
        res.challengeName.includes('NEW_PASSWORD_REQUIRED')
      ) {
        dispatch({ type: 'newPwRequired', tempCognitoUser: res });
        showNotification(
          'Willkommen!',
          'Bitte ändern Sie Ihr Passwort!',
          'success'
        );
      } else if (res.attributes.email) {
        // get role according to user groups
        const role = getUserRole(
          res.signInUserSession.accessToken.payload['cognito:groups'] || 'user'
        );

        // fetch user details
        const userDetails = await getUserDetails(res.attributes.email);
        const companyDetails = await getUserCompanyDetails(
          userDetails.companyId
        );
        dispatch({
          type: 'signIn',
          payload: {
            user: res.attributes.email,
            userDetails,
            companyDetails,
            role,
          },
        });

        showNotification('Hallo!', 'Login erfolgreich', 'success');
      }
    } catch (err) {
      showNotification('Error', 'Email oder Passwort inkorrekt', 'error');
      dispatch({ type: 'loadingEnd' });
    }
  };

  const checkSession = async (): Promise<void> => {
    dispatch({ type: 'loadingStart' });
    try {
      const res = await authApi.getCurrentUser();
      if (res.attributes.email) {
        // get role according to user groups
        const role = getUserRole(
          res.signInUserSession.accessToken.payload['cognito:groups'] || 'user'
        );

        // fetch user details
        const userDetails = await getUserDetails(res.attributes.email);
        // fetch comapny details
        const companyDetails = await getUserCompanyDetails(
          userDetails.companyId
        );
        dispatch({
          type: 'signIn',
          payload: {
            user: res.attributes.email,
            userDetails,
            companyDetails,
            role,
          },
        });
      }
    } catch (error) {
      dispatch({ type: 'signOut' });
    }
  };

  const signOut = async (): Promise<void> => {
    dispatch({ type: 'loadingStart' });
    try {
      await authApi.signOut();
      dispatch({ type: 'signOut' });
      showNotification('Bis bald!', 'Erfolgreich abgemeldet', 'success');
    } catch (error) {
      dispatch({ type: 'loadingEnd' });
      showNotification('Error', 'Ein Fehler ist aufgetreten', 'error');
    }
  };

  const resetAuthState = async (): Promise<void> => {
    dispatch({ type: 'signOut' });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const completeNewPw = async (newPw: string, cognitoUser: any) => {
    dispatch({ type: 'loadingStart' });
    try {
      await authApi.completeNewPw(cognitoUser, newPw);
      dispatch({ type: 'newPwCompleted', newPw });
      checkSession();
      showNotification('Hallo!', 'Login erfolgreich', 'success');
    } catch (error) {
      if (error.name.includes('InvalidPasswordException')) {
        dispatch({ type: 'loadingEnd' });
        showNotification('Error', 'Passwort erfüllt Kriterien nicht', 'error');
      } else {
        showNotification('Error', 'Ein Fehler ist aufgetreten', 'error');
        dispatch({ type: 'signOut' });
      }
    }
  };

  const confirmResetPw = async (
    code: string,
    newPw: string,
    userMail: string
  ) => {
    dispatch({ type: 'loadingStart' });
    try {
      await authApi.confirmPwReset(userMail, code, newPw);
      dispatch({ type: 'resetPwConfirmed' });
      showNotification('Passwort geändert', 'Willkommen zurück!', 'success');
      checkSession();
    } catch (error) {
      dispatch({ type: 'loadingEnd' });
      if (error.name.includes('InvalidPasswordException')) {
        showNotification(
          'Error',
          'Passwort entspricht nicht den Kriterien',
          'error'
        );
      }
      showNotification('Error', 'Ein Fehler ist aufgetreten', 'error');
    }
  };

  const forgotPassword = () => {
    dispatch({ type: 'loadingStart' });
    dispatch({ type: 'forgotPassword' });
  };

  const sendConfirmationCode = async (email: string) => {
    dispatch({ type: 'loadingStart' });
    try {
      await authApi.sendConfirmationCode(email);
      dispatch({ type: 'forgotPasswordCodeSent', tempMail: email });
      showNotification(
        'Code versendet!',
        `Der Code wurde an ${email} gesendet`,
        'success'
      );
    } catch (error) {
      dispatch({ type: 'loadingEnd' });
      showNotification('Error', 'Ein Fehler ist aufgetreten', 'error');
    }
  };

  return {
    checkSession,
    signIn,
    signOut,
    activateGuestMode,
    completeNewPw,
    sendConfirmationCode,
    confirmResetPw,
    resetAuthState,
    forgotPassword,
  };
};

export default useAuthActions;
