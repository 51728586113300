import React from 'react';
import axios from 'axios';

import { getUploadUrl } from '../api/Storage';
import useNotificationActions from './useNotificationActions';

interface IReturn {
  upload(file: File): Promise<string | null>;
  uploading: boolean;
}

const useFileUpload = (): IReturn => {
  const [uploading, setUploading] = React.useState(false);
  const { show } = useNotificationActions();

  /**
   * @description - takes a file, generates a signed upload url and then uploads the file
   * @param file - the file to upload to the storage bucket
   * @returns - the key to the file
   */
  const upload = async (file: File) => {
    setUploading(true);
    try {
      const res = await getUploadUrl({ fileName: file.name });
      if (res.data?.getUploadUrl?.data) {
        await axios.put(res.data.getUploadUrl.data.uploadUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });
        setUploading(false);
        return res.data.getUploadUrl.data.key;
      }
      setUploading(false);
    } catch (error) {
      show('Error', 'Bild konnte nicht hochgeladen werden', 'error');
    }
    setUploading(false);
    return null;
  };

  return {
    upload,
    uploading,
  };
};

export default useFileUpload;
