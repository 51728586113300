import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import {
  DownloadFileInput,
  GetDownloadUrlQuery,
  GetUploadUrlQuery,
  UploadFileInput,
} from './API';
import * as queries from './graphql/queries';
import call from './GraphQLOperation';

export const getUploadUrl = (
  input: UploadFileInput
): Promise<GraphQLResult<GetUploadUrlQuery>> => {
  return call<GetUploadUrlQuery>(queries.getUploadUrl, {
    variables: { input },
  });
};

export const getDownloadUrl = async (
  input: DownloadFileInput,
  isGuest?: boolean
): Promise<GraphQLResult<GetDownloadUrlQuery>> => {
  return call<GetDownloadUrlQuery>(queries.getDownloadUrl, {
    variables: { input },
    authMode: isGuest
      ? GRAPHQL_AUTH_MODE.AWS_IAM
      : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
};
