import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core';

import { IThermoBoxCount } from '../../api/Thermobox';

interface IProps {
  box: IThermoBoxCount;
  open: boolean;
  close(): void;
  returnBoxes(quantity: number, owner: string): Promise<void>;
  addBoxes(quantity: number, owner: string): Promise<void>;
}

const ChangeBoxCountDialog = ({
  box,
  open,
  close,
  addBoxes,
  returnBoxes,
}: IProps): JSX.Element => {
  const [amount, setAmount] = React.useState(box.quantity);

  React.useEffect(() => setAmount(box.quantity), [box.quantity]);

  const handleReturnBoxes = () => {
    returnBoxes(box.quantity - amount, box.ownerId);
    close();
  };

  const handleAddBoxes = () => {
    addBoxes(amount - box.quantity, box.ownerId);
    close();
  };

  const handleSubmit = () => {
    if (amount < box.quantity) {
      handleReturnBoxes();
    } else {
      handleAddBoxes();
    }
    close();
  };

  const getBoxAmount = (): string => {
    if (amount === box.quantity) {
      return '';
    }

    if (amount < box.quantity) {
      return `(- ${box.quantity - amount})`;
    }

    // else
    return `(+ ${amount - box.quantity})`;
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogContent>
        <Typography>
          Geben Sie die Gesamtanzahl an Boxen für
          <b> {box.ownerName} </b>
          an.
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <TextField
            style={{ margin: '5px', width: '100px' }}
            name="amount"
            size="small"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            label="Gesamtanzahl"
            fullWidth
            variant="outlined"
            value={amount}
            onChange={(ev) => setAmount(+ev.target.value)}
          />
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button
              onClick={() =>
                setAmount((prev) => (prev - 1 < 0 ? prev : prev - 1))
              }
            >
              −
            </Button>
            <Button onClick={() => setAmount((prev) => prev + 1)}>＋</Button>
          </ButtonGroup>
        </Box>
        <DialogActions>
          <Button onClick={() => close()}>Abbrechen</Button>
          <Button disabled={amount < 0} color="primary" onClick={handleSubmit}>
            Speichern {getBoxAmount()}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeBoxCountDialog;
