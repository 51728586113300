import { makeStyles, Theme } from '@material-ui/core';

import gastro_pano from '../../static/images/g1.jpg';
import gastro_wine from '../../static/images/g3.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '20px',
  },
  textAreaLeft: {
    paddingTop: '30px',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingBottom: '30px',
  },
  textAreaCenter: {
    paddingTop: '10px',
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingBottom: '30px',
  },
  hero: {
    backgroundImage: `url(${gastro_pano})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '350px',
  },
  imgArea1: {
    backgroundImage: `url(${gastro_wine})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    minHeight: '300px',
  },
  imgArea2: {
    backgroundImage: `url(${gastro_pano})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    minHeight: '200px',
  },
  priceHeading: {
    marginTop: '30px',
    marginBottom: '20px',
  },
  card: {
    marginTop: '30px',
    marginBottom: '30px',
    marginLeft: '10%',
    marginRight: '10%',
  },
  priceCard: {
    marginTop: '30px',
    marginBottom: '30px',
    marginLeft: '5%',
    marginRight: '5%',
    minHeight: '300px',
    [theme.breakpoints.up('lg')]: {
      minHeight: '400px',
    },
  },
}));

export default useStyles;
