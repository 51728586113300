/* eslint-disable no-useless-escape */
import React from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { CreateUserInput } from '../../api/API';
import * as regex from '../../util/regex';

interface IProps {
  open: boolean;
  close(): void;
  save(input: CreateUserInput): Promise<void>;
  companyId: string;
}

const AddUserDialog = ({
  open,
  close,
  companyId,
  save,
}: IProps): JSX.Element => {
  const { register, errors, handleSubmit } = useForm<CreateUserInput>();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (data: CreateUserInput) => {
    setLoading(true);
    await save({
      ...data,
      companyId,
      hasReadAgb: false,
      newsletter: false,
    });
    setLoading(false);
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>User hinzufügen</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>Legen Sie einen neuen User an:</DialogContentText>
          <TextField
            disabled={loading}
            autoFocus
            margin="dense"
            id="email"
            name="email"
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.email,
                message: 'Bitte gültige Email Adresse eingeben',
              },
            })}
            helperText={errors.email?.message}
            error={!!errors.email}
            label="Email"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="name"
            name="name"
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: {
                value: 2,
                message: 'Min. 2 Zeichen',
              },
            })}
            helperText={errors.name?.message}
            error={!!errors.name}
            label="Vorname"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="surname"
            name="surname"
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: {
                value: 2,
                message: 'Min. 2 Zeichen',
              },
            })}
            helperText={errors.surname?.message}
            error={!!errors.surname}
            label="Nachname"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="mobileNumber"
            name="mobileNumber"
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.austrianPhone,
                message: 'Bitte geben Sie eine gültige Telefonnummer an',
              },
            })}
            helperText={errors.mobileNumber?.message}
            error={!!errors.mobileNumber}
            label="Telefonnummer"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="address"
            name="address"
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: 3,
            })}
            helperText={errors.address?.message}
            error={!!errors.address}
            label="Adresse"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="zip"
            name="zip"
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.austrianZip,
                message: 'Bitte geben Sie eine gültige Postleitzahl an',
              },
            })}
            helperText={errors.zip?.message}
            error={!!errors.zip}
            label="Postleitzahl"
            fullWidth
          />
          <FormControlLabel
            label="Adminrechte"
            control={<Checkbox name="isCompanyAdmin" inputRef={register()} />}
          />
          <br />
          <FormControlLabel
            label="Zum Bestellen auf Rechnung berechtigt"
            control={<Checkbox name="canOrderOnBill" inputRef={register()} />}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={close} color="primary">
            Abbrechen
          </Button>
          <Button disabled={loading} type="submit" color="primary">
            {loading ? <CircularProgress size={20} /> : 'Speichern'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserDialog;
