import React from 'react';
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  useScrollTrigger,
  Box,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import KitchenIcon from '@material-ui/icons/Kitchen';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useLocation, useHistory } from 'react-router-dom';
import DateRangeIcon from '@material-ui/icons/DateRange';

import useStyles from './AppNav.css';
import routes from '../router/routes';
import { useTrackedState, RoleEnum } from '../../store/Auth';
import useAuthActions from '../../hooks/useAuthActions';

const guestItems = [
  {
    label: 'Jetzt bestellen',
    route: routes.app.order,
    icon: ShoppingCartIcon,
  },
  {
    label: 'Menüplan',
    route: routes.app.menuManagement,
    icon: RestaurantMenuIcon,
  },
];

const userItems = [...guestItems];

const companyAdminItems = [
  ...userItems,
  {
    label: 'User',
    route: routes.app.userManagement,
    icon: PeopleIcon,
  },
  {
    label: 'Bestellungen',
    route: routes.app.orderOverview,
    icon: AssignmentIcon,
  },
  /* 
  {
    label: 'Reporting',
    route: routes.app.reporting,
    icon: EqualizerIcon,
  },
  */
];

const adminItems = [
  ...companyAdminItems,
  {
    label: 'Artikel',
    route: routes.app.articleManagement,
    icon: KitchenIcon,
  },
  {
    label: 'Thermoboxen',
    route: routes.app.boxManagement,
    icon: AllInboxIcon,
  },
  {
    label: '7-Tages Planung',
    route: routes.app.weeklyManagement,
    icon: DateRangeIcon,
  },
  {
    label: 'Firmenreport',
    route: routes.app.companyReport,
    icon: BarChartIcon,
  },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getItemsFromRole = (role: RoleEnum) => {
  switch (role) {
    case RoleEnum.GUEST:
      return guestItems;
    case RoleEnum.USER:
      return userItems;
    case RoleEnum.COMPANYADMIN:
      return companyAdminItems;
    case RoleEnum.ADMIN:
      return adminItems;
    default:
      return guestItems;
  }
};

const AppNav = (): JSX.Element => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { user, role } = useTrackedState();
  const { signOut } = useAuthActions();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const scrollTrigger = useScrollTrigger();

  return (
    <Box displayPrint="none">
      <Drawer
        anchor={isMobile ? 'top' : 'left'}
        variant="persistent"
        open={!isMobile || (!scrollTrigger && isMobile)}
        className={classes.drawer}
      >
        <div className={classes.drawerContent}>
          <List dense={isMobile}>
            <ListItem>
              <Grid container alignItems="center" direction="column">
                <Grid item>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="caption" style={{ color: 'grey' }}>
                    {`Angemeldet als:\n ${user}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    onClick={signOut}
                  >
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
            {getItemsFromRole(role).map((item) => (
              <ListItem
                key={`app-menu-${item.route}`}
                button
                selected={pathname === item.route}
                onClick={() => push(item.route)}
              >
                <ListItemIcon>
                  <item.icon color="primary" />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </Box>
  );
};

export default AppNav;
