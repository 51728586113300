import React from 'react';
import { createContainer } from 'react-tracked';

import { IMenu } from '../api/Menu';

interface IMenuState {
  menus: IMenu[];
  loading: boolean;
  error: string;
}

type MenuAction =
  | { type: 'startLoading' }
  | { type: 'stopLoading' }
  | { type: 'setError'; error: string }
  | { type: 'setMenus'; menus: IMenu[] }
  | { type: 'updateMenu'; updated: IMenu };

const initialState: IMenuState = {
  menus: [],
  loading: false,
  error: '',
};

const menuReducer = (state: IMenuState, action: MenuAction): IMenuState => {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        loading: true,
      };

    case 'stopLoading':
      return {
        ...state,
        loading: false,
      };

    case 'setError':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'setMenus':
      return {
        ...state,
        loading: false,
        error: '',
        menus: action.menus,
      };

    case 'updateMenu': {
      const index = state.menus.findIndex(
        (menu) =>
          menu.menuType === action.updated.menuType &&
          menu.date === action.updated.date
      );
      if (index === -1) {
        return {
          ...state,
          loading: false,
          error: '',
          menus: [...state.menus, action.updated],
        };
      }
      const newMenus = [...state.menus];
      newMenus[index] = action.updated;
      return {
        ...state,
        loading: false,
        error: '',
        menus: newMenus,
      };
    }

    default:
      return state;
  }
};

const useValue = () => React.useReducer(menuReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
