/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@material-ui/core';

import { IArticle } from '../../api/Article';
import useStyles from './AppCheckout.css';
import OrderListItem from './OrderListItem';
import Theme from '../../theme/Theme';
import { getSingleArticleModifiersPrice } from '../../util/util';
import { IArticleAggregation } from './types';

interface IProps {
  items: IArticle[];
  isGuest: boolean;
  discount: number | undefined;
  editView: boolean;
}

const aggregate = (items: IArticle[]) => {
  const aggregated: IArticleAggregation[] = [];
  items.forEach((item) => {
    const aggregatedItem = aggregated.find((agg) => agg.id === item.id);
    if (aggregatedItem) {
      aggregatedItem.quantity += 1;
      aggregatedItem.modifiersPrice += getSingleArticleModifiersPrice(
        item.modifiers
      );
    } else {
      aggregated.push({
        ...item,
        quantity: 1,
        modifiersPrice: getSingleArticleModifiersPrice(item.modifiers),
      });
    }
  });
  return aggregated;
};

const OrderItemList = ({
  items,
  isGuest,
  discount,
  editView,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const aggItems = aggregate(items);
  return (
    <List
      component={Paper}
      className={classes.orderList}
      style={{
        border: editView ? 'none' : `2px solid ${Theme.palette.primary.main}`,
      }}
      elevation={editView ? 4 : 0}
      dense={!editView}
    >
      {aggItems.map((item, index) => (
        <>
          <OrderListItem
            item={item}
            key={`orderlist${item.id}${index}`}
            isGuest={isGuest}
            editView={editView}
          />
          {index !== aggItems.length - 1 && <Divider />}
        </>
      ))}
      {!isGuest && (
        <>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Firmenzuschuss"
              secondary={`€ ${discount ? discount / 100 : 0}`}
            />
          </ListItem>
        </>
      )}
    </List>
  );
};

export default OrderItemList;
