import React from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import { NewsInput } from '../../api/API';
import useNotificationActions from '../../hooks/useNotificationActions';
import { INews, setNews } from '../../api/News';

interface IProps {
  open: boolean;
  close(): void;
  news: INews;
  refetch(): void;
}

const SetNewsDialog = ({ open, close, news, refetch }: IProps): JSX.Element => {
  const [newNews, setNewNews] = React.useState<NewsInput>({
    title: news.title,
    content: news.content,
    date: news.date,
  });
  const [loading, setLoading] = React.useState(false);
  const { show } = useNotificationActions();

  const saveNews = async () => {
    setLoading(true);
    try {
      await setNews({
        title: newNews.title,
        content: newNews.content,
        date: new Date().toISOString(),
      });
      show('Gespeichert', 'News wurden gespeichert', 'success');
      refetch();
    } catch (error) {
      show('Error', 'News konnten nicht gespeichert werden', 'error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>News bearbeiten</DialogTitle>
      <DialogContent>
        <TextField
          disabled={loading}
          margin="normal"
          id="title"
          name="title"
          label="Titel"
          fullWidth
          variant="outlined"
          value={newNews.title}
          onChange={(ev) =>
            setNewNews((prev) => ({ ...prev, title: ev.target.value }))
          }
        />
        <TextField
          disabled={loading}
          margin="normal"
          id="content"
          name="content"
          label="Inhalt"
          fullWidth
          variant="outlined"
          multiline
          value={newNews.content}
          onChange={(ev) =>
            setNewNews((prev) => ({ ...prev, content: ev.target.value }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} disabled={loading}>
          Abbrechen
        </Button>
        <Button color="primary" onClick={saveNews} disabled={loading}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetNewsDialog;
