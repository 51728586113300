import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import routes from '../router/routes';

interface IProps {
  open: boolean;
  close: () => void;
  handleConfirm: () => void;
}

const GdprDialog = ({ open, close, handleConfirm }: IProps): JSX.Element => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Datenschutzeinstellungen</DialogTitle>
      <DialogContent>
        Bitte bestätigen Sie unsere{' '}
        <a href={routes.gdpr} target="_blank" rel="noopener noreferrer">
          Datenschutzrichtlinien
        </a>
        , um unsere App in vollem Umfang nutzen zu können.
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleConfirm}>
          Bestätigen
        </Button>
        <Button variant="text" onClick={close}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GdprDialog;
