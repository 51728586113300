import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '90vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'left',
  },
}));

export default useStyles;
