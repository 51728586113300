import React from 'react';
import { createContainer } from 'react-tracked';

import { ICompany } from '../api/Company';

interface ICompaniesState {
  companies: ICompany[];
  loading: boolean;
  error: string;
}

type CompanyAction =
  | { type: 'startLoading' }
  | { type: 'setError'; error: string }
  | { type: 'setCompanies'; companies: ICompany[] }
  | { type: 'updateCompany'; newCompany: ICompany };

const initialState: ICompaniesState = {
  companies: [],
  loading: false,
  error: '',
};

const companyReducer = (
  state: ICompaniesState,
  action: CompanyAction
): ICompaniesState => {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        loading: true,
      };

    case 'setError':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'setCompanies':
      return {
        ...state,
        loading: false,
        error: '',
        companies: action.companies,
      };

    case 'updateCompany':
      return {
        ...state,
        loading: false,
        companies: state.companies.map((item) =>
          item.id === action.newCompany.id ? action.newCompany : item
        ),
      };

    default:
      return state;
  }
};

const useValue = () => React.useReducer(companyReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
