import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import LuxonUtils from '@material-ui/pickers/adapter/luxon';

import Root from './components/root/Root';
import { Provider as ArticleProvider } from './store/Articles';
import { Provider as AuthProvider } from './store/Auth';
import { Provider as NotificationProvider } from './store/Notification';
import { Provider as CartProvider } from './store/Cart';
import { Provider as ImageCacheProvider } from './store/ImageCache';
import { Provider as MenuProvider } from './store/Menu';

import ccgMpTheme from './theme/Theme';

import '@fontsource/roboto';
import './globalReset.css';

// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={ccgMpTheme}>
      <LocalizationProvider locale="de-DE" dateAdapter={LuxonUtils}>
        <ImageCacheProvider>
          <NotificationProvider>
            <AuthProvider>
              <CartProvider>
                <ArticleProvider>
                  <MenuProvider>
                    <Root />
                  </MenuProvider>
                </ArticleProvider>
              </CartProvider>
            </AuthProvider>
          </NotificationProvider>
        </ImageCacheProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
