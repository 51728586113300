import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  skeletonRoot: {
    display: 'grid',
    gridGap: '2px',
  },
  adminChip: {
    marginLeft: '5px',
  },
}));

export default useStyles;
