import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AppMenuManagement from '../appMenuManagement/AppMenuManagement';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    minHeight: '80vh',
  },
}));

const MenuPlan = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" align="center">
        Hier finden Sie immer die aktuellen Menüpläne.
      </Typography>
      <Typography variant="body2" align="center">
        Verwenden Sie die Pfeile um die Woche zu wechseln
      </Typography>
      <AppMenuManagement />
    </div>
  );
};

export default MenuPlan;
