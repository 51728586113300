/* eslint-disable no-useless-escape */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

import * as regex from '../../util/regex';
import { CreateCompanyInput } from '../../api/API';
import useCompanyActions from './useCompanyActions';

interface IProps {
  open: boolean;
  close(): void;
}

const AddCompanyDialog = ({ open, close }: IProps): JSX.Element => {
  const { register, handleSubmit, errors } = useForm<CreateCompanyInput>();
  const [loading, setLoading] = React.useState(false);

  const { add } = useCompanyActions();

  const onSubmit = async (data: CreateCompanyInput) => {
    setLoading(true);
    await add({
      ...data,
      discount: data.discount * 100,
      employeeOrderLimit: data.employeeOrderLimit * 100,
    });
    setLoading(false);
    close();
  };

  return (
    <Dialog fullWidth open={open} onClose={close}>
      <DialogTitle id="form-dialog-title">Unternehmen hinzufügen</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Legen Sie ein neues Unternehmen an:
          </DialogContentText>

          <TextField
            disabled={loading}
            autoFocus
            margin="dense"
            id="name"
            name="companyName"
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: {
                value: 3,
                message: 'Min. 3 Zeichen',
              },
            })}
            helperText={errors.companyName?.message}
            error={!!errors.companyName}
            label="Firmenname"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="address"
            label="Adresse"
            fullWidth
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: 3,
            })}
            helperText={errors.address?.message}
            error={!!errors.address}
            name="address"
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="zip"
            label="Postleitzahl"
            fullWidth
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.austrianZip,
                message: 'Bitte geben Sie eine gültige Postleitzahl an',
              },
            })}
            helperText={errors.zip?.message}
            error={!!errors.zip}
            name="zip"
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="contactperson"
            label="Kontaktperson"
            fullWidth
            name="contactPerson"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.contactPerson?.message}
            error={!!errors.contactPerson}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            name="contactMail"
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.email,
                message: 'Bitte gültige Email Adresse eingeben',
              },
            })}
            helperText={errors.contactMail?.message}
            error={!!errors.contactMail}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="tel"
            label="Kontaktnummer"
            type="tel"
            fullWidth
            name="mobileNumber"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.mobileNumber?.message}
            error={!!errors.mobileNumber}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="tableId"
            label="TischId"
            fullWidth
            name="tableId"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.tableId?.message}
            error={!!errors.tableId}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="discountArticleId"
            label="Firmenzuschuss-ArtikelId"
            fullWidth
            name="discountArticleId"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.discountArticleId?.message}
            error={!!errors.discountArticleId}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="discount"
            type="number"
            label="Firmenzuschuss"
            fullWidth
            name="discount"
            inputRef={register({
              required: 'Pflichtfeld',
              min: {
                value: 0,
                message: 'Zuschuss kann nicht weniger als 0 betragen',
              },
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            helperText={errors.discount?.message}
            error={!!errors.discount}
          />

          <TextField
            disabled={loading}
            margin="dense"
            id="employeeOrderLimit"
            type="number"
            label="Bestelllimit für Angestellte"
            fullWidth
            name="employeeOrderLimit"
            inputRef={register({
              required: 'Pflichtfeld',
              min: {
                value: 0,
                message: 'Bestelllimit kann nicht weniger als 0 betragen',
              },
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            helperText={errors.employeeOrderLimit?.message}
            error={!!errors.employeeOrderLimit}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="orderSortTime"
            type="time"
            label="Lieferzeit"
            fullWidth
            name="orderSortTime"
            inputRef={register({
              required: 'Pflichtfeld',
            })}
            helperText={errors.orderSortTime?.message}
            error={!!errors.orderSortTime}
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="comment"
            label="Kommentar"
            fullWidth
            name="comment"
            inputRef={register()}
            helperText={errors.comment?.message}
            error={!!errors.comment}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={close} color="primary">
            Abbrechen
          </Button>
          <Button disabled={loading} type="submit" color="primary">
            {loading ? <CircularProgress size={20} /> : 'Speichern'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCompanyDialog;
