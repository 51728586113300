/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdateArticleInput = {
  id: string;
  isMainDish: boolean;
  hasThermobox: boolean;
  isLocked: boolean;
  imageId: string;
  maximumOrders: number;
};

export type UpdateArticleResponse = {
  __typename: 'UpdateArticleResponse';
  data?: string | null;
  error?: Error | null;
};

export type Error = {
  __typename: 'Error';
  message: string;
};

export type CreateCompanyInput = {
  companyName: string;
  address: string;
  zip: string;
  mobileNumber: string;
  contactPerson: string;
  contactMail: string;
  discount: number;
  tableId: string;
  discountArticleId: string;
  employeeOrderLimit: number;
  orderSortTime: string;
  comment?: string | null;
};

export type CompanyResult = {
  __typename: 'CompanyResult';
  data?: Company | null;
  error?: Error | null;
};

export type Company = {
  __typename: 'Company';
  id: string;
  companyName: string;
  address: string;
  zip: string;
  mobileNumber: string;
  contactPerson: string;
  contactMail: string;
  discount: number;
  tableId: string;
  discountArticleId: string;
  employeeOrderLimit?: number | null;
  orderSortTime?: string | null;
  comment?: string | null;
};

export type UpdateCompanyInput = {
  id: string;
  companyName: string;
  address: string;
  zip: string;
  mobileNumber: string;
  contactPerson: string;
  contactMail: string;
  discount: number;
  tableId: string;
  discountArticleId: string;
  employeeOrderLimit: number;
  orderSortTime: string;
  comment?: string | null;
};

export type SetMenuInput = {
  menuType: MenuEnum;
  date: string;
  description: string;
  allergens: string;
};

export enum MenuEnum {
  MENU1 = 'MENU1',
  MENU2 = 'MENU2',
  MENU3 = 'MENU3',
  MENU4 = 'MENU4',
  MENU5 = 'MENU5',
  MENU6 = 'MENU6',
  MENU7 = 'MENU7',
  ALT_MENU1 = 'ALT_MENU1',
  ALT_MENU2 = 'ALT_MENU2',
}

export type SetMenuResult = {
  __typename: 'SetMenuResult';
  data?: string | null;
  error?: Error | null;
};

export type NewsInput = {
  date: string;
  title: string;
  content: string;
};

export type SetNewsResult = {
  __typename: 'SetNewsResult';
  data?: string | null;
  error?: Error | null;
};

export type CreateOrderInput = {
  companyId: string;
  userId: string;
  companyName: string;
  userName: string;
  paymentId: string;
  tip: number;
  orderType: OrderTypeEnum;
  deliveryDate: string;
  comment: string;
  discount: number;
  discountArticleId: string;
  tableId: string;
  articles: Array<ArticleInput | null>;
  name: string;
  surname: string;
  address: string;
  zip: string;
  phoneNumber: string;
  isBatch?: boolean | null;
};

export enum OrderTypeEnum {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP',
}

export type ArticleInput = {
  id: string;
  isMainDish: boolean;
  hasThermobox: boolean;
  isLocked: boolean;
  isDeprecated: boolean;
  imageId: string;
  maximumOrders: number;
  melzerId: string;
  name: string;
  description: string;
  allergens: string;
  grossPrice: number;
  tax: number;
  articleGroupMelzerId: string;
  articleGroupName: string;
  articleGroupFromHour: string;
  articleGroupToHour: string;
  modifiers: Array<ModifierInput | null>;
};

export type ModifierInput = {
  id: string;
  name: string;
  description: string;
  allergens: string;
  grossPrice: number;
  tax: number;
  isActive: boolean;
  sort: number;
  modifierId: string;
  modifierName: string;
  modifierMin: number;
  modifierMax: number;
  modifierIgnorePrice: boolean;
};

export type CreateOrderResponse = {
  __typename: 'CreateOrderResponse';
  data?: string | null;
  error?: Error | null;
};

export type ChangeThermoboxCountInput = {
  quantity: number;
  owner: string;
};

export type ChangeThermoboxCountResponse = {
  __typename: 'ChangeThermoboxCountResponse';
  data?: string | null;
  error?: Error | null;
};

export type DeleteOrderInput = {
  orderId: string;
};

export type DeleteOrderResponse = {
  __typename: 'DeleteOrderResponse';
  data?: string | null;
  error?: Error | null;
};

export type CreateUserInput = {
  email: string;
  name: string;
  surname: string;
  mobileNumber: string;
  address: string;
  zip: string;
  companyId: string;
  newsletter: boolean;
  hasReadAgb: boolean;
  isCompanyAdmin: boolean;
  canOrderOnBill: boolean;
};

export type UserResult = {
  __typename: 'UserResult';
  data?: User | null;
  error?: Error | null;
};

export type User = {
  __typename: 'User';
  id: string;
  email: string;
  name: string;
  surname: string;
  mobileNumber: string;
  address: string;
  zip: string;
  companyId: string;
  createdAt: string;
  newsletter: boolean;
  hasReadAgb: boolean;
  isCompanyAdmin: boolean;
  canOrderOnBill?: boolean | null;
};

export type UpdateUserInput = {
  id: string;
  email: string;
  name: string;
  surname: string;
  mobileNumber: string;
  address: string;
  zip: string;
  companyId: string;
  createdAt: string;
  newsletter: boolean;
  hasReadAgb: boolean;
  isCompanyAdmin: boolean;
  canOrderOnBill: boolean;
};

export type DeleteUserInput = {
  id: string;
  email: string;
};

export type DeleteUserResult = {
  __typename: 'DeleteUserResult';
  data?: string | null;
  error?: Error | null;
};

export type GetArticleByIdInput = {
  id: string;
};

export type ArticleResponse = {
  __typename: 'ArticleResponse';
  data?: Article | null;
  error?: Error | null;
};

export type Article = {
  __typename: 'Article';
  id: string;
  isMainDish: boolean;
  hasThermobox: boolean;
  isLocked: boolean;
  isDeprecated: boolean;
  imageId: string;
  maximumOrders: number;
  melzerId: string;
  name: string;
  description: string;
  allergens: string;
  grossPrice: number;
  tax: number;
  articleGroupMelzerId: string;
  articleGroupName: string;
  articleGroupFromHour: string;
  articleGroupToHour: string;
  modifiers: Array<Modifier | null>;
};

export type Modifier = {
  __typename: 'Modifier';
  id: string;
  name: string;
  description: string;
  allergens: string;
  grossPrice: number;
  tax: number;
  isActive: boolean;
  sort: number;
  modifierId: string;
  modifierName: string;
  modifierMin: number;
  modifierMax: number;
  modifierIgnorePrice: boolean;
};

export type GetArticleByMelzerIdInput = {
  melzerId: string;
};

export type GetAllArticlesByOrderIdInput = {
  orderId: string;
};

export type ArticlesResponse = {
  __typename: 'ArticlesResponse';
  data?: Array<Article | null> | null;
  error?: Error | null;
};

export type GetCompanyByIdInput = {
  id: string;
};

export type GetCompanyByNameInput = {
  name: string;
};

export type CompaniesResult = {
  __typename: 'CompaniesResult';
  data?: Array<Company | null> | null;
  error?: Error | null;
};

export type GetMenusByDateRangeInput = {
  from: string;
  to: string;
};

export type MenusResult = {
  __typename: 'MenusResult';
  data?: Array<Menu | null> | null;
  error?: Error | null;
};

export type Menu = {
  __typename: 'Menu';
  menuType: MenuEnum;
  date: string;
  description: string;
  allergens: string;
};

export type NewsResult = {
  __typename: 'NewsResult';
  data?: News | null;
  error?: Error | null;
};

export type News = {
  __typename: 'News';
  id: string;
  date: string;
  title: string;
  content: string;
};

export type GetOrderByIdInput = {
  orderId: string;
};

export type OrderResponse = {
  __typename: 'OrderResponse';
  data?: Order | null;
  error?: Error | null;
};

export type Order = {
  __typename: 'Order';
  id: string;
  orderState: OrderStateEnum;
  isBatchOrder: boolean;
  companyId: string;
  userId: string;
  companyName: string;
  userName: string;
  tip: number;
  orderType: OrderTypeEnum;
  orderDate: string;
  deliveryDate: string;
  thermoBoxes: number;
  comment: string;
  discount: number;
  discountArticleId: string;
  tableId: string;
  paymentId: string;
  name: string;
  surname: string;
  address: string;
  zip: string;
  phoneNumber: string;
  articles: Array<Article>;
  invoiceUrl?: string | null;
};

export enum OrderStateEnum {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  BILL = 'BILL',
  ERROR = 'ERROR',
}

export type GetOrdersByDeliveryDateInput = {
  deliveryDate: string;
};

export type OrdersResponse = {
  __typename: 'OrdersResponse';
  data?: Array<Order | null> | null;
  error?: Error | null;
};

export type GetOrdersByUserIdInput = {
  userId: string;
};

export type GetOrdersByCompanyId = {
  companyId: string;
};

export type ThermoboxCountResponse = {
  __typename: 'ThermoboxCountResponse';
  data?: Array<ThermoboxCount | null> | null;
  error?: Error | null;
};

export type ThermoboxCount = {
  __typename: 'ThermoboxCount';
  ownerId: string;
  ownerName: string;
  email?: string | null;
  phone?: string | null;
  quantity: number;
};

export type GetOrdersByDateRangeAndCompanyIdInput = {
  from: string;
  to: string;
  companyId: string;
  limit?: number | null;
  nextToken?: GsiTypeCompositeTokenInput | null;
};

export type GsiTypeCompositeTokenInput = {
  pk: string;
  sk: string;
  typename: string;
  GSITYPESK: string;
};

export type OrderConnection = {
  __typename: 'OrderConnection';
  data?: Array<Order> | null;
  nextToken?: GsiTypeCompositeToken | null;
};

export type GsiTypeCompositeToken = {
  __typename: 'GsiTypeCompositeToken';
  pk: string;
  sk: string;
  typename: string;
  GSITYPESK: string;
};

export type GetOrderArticleCountByDateRangeInput = {
  from: string;
  to: string;
};

export type OrderArticleCountResponse = {
  __typename: 'OrderArticleCountResponse';
  data?: Array<OrderArticleCounts> | null;
  error?: Error | null;
};

export type OrderArticleCounts = {
  __typename: 'OrderArticleCounts';
  date: string;
  counts: Array<OrderArticleCount>;
};

export type OrderArticleCount = {
  __typename: 'OrderArticleCount';
  count: number;
  articleName: string;
};

export type GetOrdersByDateRangeInput = {
  from: string;
  to: string;
  limit?: number | null;
  nextToken?: GsiTypeCompositeTokenInput | null;
};

export type UploadFileInput = {
  fileName: string;
};

export type UploadResponse = {
  __typename: 'UploadResponse';
  data?: UploadResponseDetails | null;
  error?: Error | null;
};

export type UploadResponseDetails = {
  __typename: 'UploadResponseDetails';
  uploadUrl: string;
  key: string;
};

export type DownloadFileInput = {
  key: string;
};

export type DownloadResponse = {
  __typename: 'DownloadResponse';
  data?: string | null;
  error?: Error | null;
};

export type GetUserByIdInput = {
  id: string;
};

export type GetUserByEmailInput = {
  email: string;
};

export type GetUsersByCompanyIdInput = {
  companyId: string;
};

export type UsersResult = {
  __typename: 'UsersResult';
  data?: Array<User | null> | null;
  error?: Error | null;
};

export type UpdateArticleMutationVariables = {
  input: UpdateArticleInput;
};

export type UpdateArticleMutation = {
  updateArticle?: {
    __typename: 'UpdateArticleResponse';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type TriggerArticleSyncMutation = {
  triggerArticleSync?: {
    __typename: 'UpdateArticleResponse';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput;
};

export type CreateCompanyMutation = {
  createCompany?: {
    __typename: 'CompanyResult';
    data?: {
      __typename: 'Company';
      id: string;
      companyName: string;
      address: string;
      zip: string;
      mobileNumber: string;
      contactPerson: string;
      contactMail: string;
      discount: number;
      tableId: string;
      discountArticleId: string;
      employeeOrderLimit?: number | null;
      orderSortTime?: string | null;
      comment?: string | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput;
};

export type UpdateCompanyMutation = {
  updateCompany?: {
    __typename: 'CompanyResult';
    data?: {
      __typename: 'Company';
      id: string;
      companyName: string;
      address: string;
      zip: string;
      mobileNumber: string;
      contactPerson: string;
      contactMail: string;
      discount: number;
      tableId: string;
      discountArticleId: string;
      employeeOrderLimit?: number | null;
      orderSortTime?: string | null;
      comment?: string | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type SetMenuMutationVariables = {
  input: SetMenuInput;
};

export type SetMenuMutation = {
  setMenu?: {
    __typename: 'SetMenuResult';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type SetNewsMutationVariables = {
  input: NewsInput;
};

export type SetNewsMutation = {
  setNews?: {
    __typename: 'SetNewsResult';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput;
};

export type CreateOrderMutation = {
  createOrder?: {
    __typename: 'CreateOrderResponse';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type CreateBatchOrderMutationVariables = {
  input: CreateOrderInput;
};

export type CreateBatchOrderMutation = {
  createBatchOrder?: {
    __typename: 'CreateOrderResponse';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type AddThermoboxMutationVariables = {
  input?: ChangeThermoboxCountInput | null;
};

export type AddThermoboxMutation = {
  addThermobox?: {
    __typename: 'ChangeThermoboxCountResponse';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type ReturnThermoboxMutationVariables = {
  input?: ChangeThermoboxCountInput | null;
};

export type ReturnThermoboxMutation = {
  returnThermobox?: {
    __typename: 'ChangeThermoboxCountResponse';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput;
};

export type DeleteOrderMutation = {
  deleteOrder?: {
    __typename: 'DeleteOrderResponse';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type CreateUserMutationVariables = {
  input: CreateUserInput;
};

export type CreateUserMutation = {
  createUser?: {
    __typename: 'UserResult';
    data?: {
      __typename: 'User';
      id: string;
      email: string;
      name: string;
      surname: string;
      mobileNumber: string;
      address: string;
      zip: string;
      companyId: string;
      createdAt: string;
      newsletter: boolean;
      hasReadAgb: boolean;
      isCompanyAdmin: boolean;
      canOrderOnBill?: boolean | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
};

export type UpdateUserMutation = {
  updateUser?: {
    __typename: 'UserResult';
    data?: {
      __typename: 'User';
      id: string;
      email: string;
      name: string;
      surname: string;
      mobileNumber: string;
      address: string;
      zip: string;
      companyId: string;
      createdAt: string;
      newsletter: boolean;
      hasReadAgb: boolean;
      isCompanyAdmin: boolean;
      canOrderOnBill?: boolean | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput;
};

export type DeleteUserMutation = {
  deleteUser?: {
    __typename: 'DeleteUserResult';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetArticleByIdQueryVariables = {
  input: GetArticleByIdInput;
};

export type GetArticleByIdQuery = {
  getArticleById?: {
    __typename: 'ArticleResponse';
    data?: {
      __typename: 'Article';
      id: string;
      isMainDish: boolean;
      hasThermobox: boolean;
      isLocked: boolean;
      isDeprecated: boolean;
      imageId: string;
      maximumOrders: number;
      melzerId: string;
      name: string;
      description: string;
      allergens: string;
      grossPrice: number;
      tax: number;
      articleGroupMelzerId: string;
      articleGroupName: string;
      articleGroupFromHour: string;
      articleGroupToHour: string;
      modifiers: Array<{
        __typename: 'Modifier';
        id: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        isActive: boolean;
        sort: number;
        modifierId: string;
        modifierName: string;
        modifierMin: number;
        modifierMax: number;
        modifierIgnorePrice: boolean;
      } | null>;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetArticleByMelzerIdQueryVariables = {
  input: GetArticleByMelzerIdInput;
};

export type GetArticleByMelzerIdQuery = {
  getArticleByMelzerId?: {
    __typename: 'ArticleResponse';
    data?: {
      __typename: 'Article';
      id: string;
      isMainDish: boolean;
      hasThermobox: boolean;
      isLocked: boolean;
      isDeprecated: boolean;
      imageId: string;
      maximumOrders: number;
      melzerId: string;
      name: string;
      description: string;
      allergens: string;
      grossPrice: number;
      tax: number;
      articleGroupMelzerId: string;
      articleGroupName: string;
      articleGroupFromHour: string;
      articleGroupToHour: string;
      modifiers: Array<{
        __typename: 'Modifier';
        id: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        isActive: boolean;
        sort: number;
        modifierId: string;
        modifierName: string;
        modifierMin: number;
        modifierMax: number;
        modifierIgnorePrice: boolean;
      } | null>;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetArticlesByOrderIdQueryVariables = {
  input: GetAllArticlesByOrderIdInput;
};

export type GetArticlesByOrderIdQuery = {
  getArticlesByOrderId?: {
    __typename: 'ArticlesResponse';
    data?: Array<{
      __typename: 'Article';
      id: string;
      isMainDish: boolean;
      hasThermobox: boolean;
      isLocked: boolean;
      isDeprecated: boolean;
      imageId: string;
      maximumOrders: number;
      melzerId: string;
      name: string;
      description: string;
      allergens: string;
      grossPrice: number;
      tax: number;
      articleGroupMelzerId: string;
      articleGroupName: string;
      articleGroupFromHour: string;
      articleGroupToHour: string;
      modifiers: Array<{
        __typename: 'Modifier';
        id: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        isActive: boolean;
        sort: number;
        modifierId: string;
        modifierName: string;
        modifierMin: number;
        modifierMax: number;
        modifierIgnorePrice: boolean;
      } | null>;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetAllArticlesQuery = {
  getAllArticles?: {
    __typename: 'ArticlesResponse';
    data?: Array<{
      __typename: 'Article';
      id: string;
      isMainDish: boolean;
      hasThermobox: boolean;
      isLocked: boolean;
      isDeprecated: boolean;
      imageId: string;
      maximumOrders: number;
      melzerId: string;
      name: string;
      description: string;
      allergens: string;
      grossPrice: number;
      tax: number;
      articleGroupMelzerId: string;
      articleGroupName: string;
      articleGroupFromHour: string;
      articleGroupToHour: string;
      modifiers: Array<{
        __typename: 'Modifier';
        id: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        isActive: boolean;
        sort: number;
        modifierId: string;
        modifierName: string;
        modifierMin: number;
        modifierMax: number;
        modifierIgnorePrice: boolean;
      } | null>;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetCompanyByIdQueryVariables = {
  input: GetCompanyByIdInput;
};

export type GetCompanyByIdQuery = {
  getCompanyById?: {
    __typename: 'CompanyResult';
    data?: {
      __typename: 'Company';
      id: string;
      companyName: string;
      address: string;
      zip: string;
      mobileNumber: string;
      contactPerson: string;
      contactMail: string;
      discount: number;
      tableId: string;
      discountArticleId: string;
      employeeOrderLimit?: number | null;
      orderSortTime?: string | null;
      comment?: string | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetCompanyByNameQueryVariables = {
  input: GetCompanyByNameInput;
};

export type GetCompanyByNameQuery = {
  getCompanyByName?: {
    __typename: 'CompanyResult';
    data?: {
      __typename: 'Company';
      id: string;
      companyName: string;
      address: string;
      zip: string;
      mobileNumber: string;
      contactPerson: string;
      contactMail: string;
      discount: number;
      tableId: string;
      discountArticleId: string;
      employeeOrderLimit?: number | null;
      orderSortTime?: string | null;
      comment?: string | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetAllCompaniesQuery = {
  getAllCompanies?: {
    __typename: 'CompaniesResult';
    data?: Array<{
      __typename: 'Company';
      id: string;
      companyName: string;
      address: string;
      zip: string;
      mobileNumber: string;
      contactPerson: string;
      contactMail: string;
      discount: number;
      tableId: string;
      discountArticleId: string;
      employeeOrderLimit?: number | null;
      orderSortTime?: string | null;
      comment?: string | null;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetMenusByDateRangeQueryVariables = {
  input: GetMenusByDateRangeInput;
};

export type GetMenusByDateRangeQuery = {
  getMenusByDateRange?: {
    __typename: 'MenusResult';
    data?: Array<{
      __typename: 'Menu';
      menuType: MenuEnum;
      date: string;
      description: string;
      allergens: string;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetNewsQuery = {
  getNews?: {
    __typename: 'NewsResult';
    data?: {
      __typename: 'News';
      id: string;
      date: string;
      title: string;
      content: string;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetOrderByIdQueryVariables = {
  input: GetOrderByIdInput;
};

export type GetOrderByIdQuery = {
  getOrderById?: {
    __typename: 'OrderResponse';
    data?: {
      __typename: 'Order';
      id: string;
      orderState: OrderStateEnum;
      isBatchOrder: boolean;
      companyId: string;
      userId: string;
      companyName: string;
      userName: string;
      tip: number;
      orderType: OrderTypeEnum;
      orderDate: string;
      deliveryDate: string;
      thermoBoxes: number;
      comment: string;
      discount: number;
      discountArticleId: string;
      tableId: string;
      paymentId: string;
      name: string;
      surname: string;
      address: string;
      zip: string;
      phoneNumber: string;
      articles: Array<{
        __typename: 'Article';
        id: string;
        isMainDish: boolean;
        hasThermobox: boolean;
        isLocked: boolean;
        isDeprecated: boolean;
        imageId: string;
        maximumOrders: number;
        melzerId: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        articleGroupMelzerId: string;
        articleGroupName: string;
        articleGroupFromHour: string;
        articleGroupToHour: string;
        modifiers: Array<{
          __typename: 'Modifier';
          id: string;
          name: string;
          description: string;
          allergens: string;
          grossPrice: number;
          tax: number;
          isActive: boolean;
          sort: number;
          modifierId: string;
          modifierName: string;
          modifierMin: number;
          modifierMax: number;
          modifierIgnorePrice: boolean;
        } | null>;
      }>;
      invoiceUrl?: string | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetOrdersByDeliveryDateQueryVariables = {
  input: GetOrdersByDeliveryDateInput;
};

export type GetOrdersByDeliveryDateQuery = {
  getOrdersByDeliveryDate?: {
    __typename: 'OrdersResponse';
    data?: Array<{
      __typename: 'Order';
      id: string;
      orderState: OrderStateEnum;
      isBatchOrder: boolean;
      companyId: string;
      userId: string;
      companyName: string;
      userName: string;
      tip: number;
      orderType: OrderTypeEnum;
      orderDate: string;
      deliveryDate: string;
      thermoBoxes: number;
      comment: string;
      discount: number;
      discountArticleId: string;
      tableId: string;
      paymentId: string;
      name: string;
      surname: string;
      address: string;
      zip: string;
      phoneNumber: string;
      articles: Array<{
        __typename: 'Article';
        id: string;
        isMainDish: boolean;
        hasThermobox: boolean;
        isLocked: boolean;
        isDeprecated: boolean;
        imageId: string;
        maximumOrders: number;
        melzerId: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        articleGroupMelzerId: string;
        articleGroupName: string;
        articleGroupFromHour: string;
        articleGroupToHour: string;
        modifiers: Array<{
          __typename: 'Modifier';
          id: string;
          name: string;
          description: string;
          allergens: string;
          grossPrice: number;
          tax: number;
          isActive: boolean;
          sort: number;
          modifierId: string;
          modifierName: string;
          modifierMin: number;
          modifierMax: number;
          modifierIgnorePrice: boolean;
        } | null>;
      }>;
      invoiceUrl?: string | null;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetOrdersByUserIdQueryVariables = {
  input: GetOrdersByUserIdInput;
};

export type GetOrdersByUserIdQuery = {
  getOrdersByUserId?: {
    __typename: 'OrdersResponse';
    data?: Array<{
      __typename: 'Order';
      id: string;
      orderState: OrderStateEnum;
      isBatchOrder: boolean;
      companyId: string;
      userId: string;
      companyName: string;
      userName: string;
      tip: number;
      orderType: OrderTypeEnum;
      orderDate: string;
      deliveryDate: string;
      thermoBoxes: number;
      comment: string;
      discount: number;
      discountArticleId: string;
      tableId: string;
      paymentId: string;
      name: string;
      surname: string;
      address: string;
      zip: string;
      phoneNumber: string;
      articles: Array<{
        __typename: 'Article';
        id: string;
        isMainDish: boolean;
        hasThermobox: boolean;
        isLocked: boolean;
        isDeprecated: boolean;
        imageId: string;
        maximumOrders: number;
        melzerId: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        articleGroupMelzerId: string;
        articleGroupName: string;
        articleGroupFromHour: string;
        articleGroupToHour: string;
        modifiers: Array<{
          __typename: 'Modifier';
          id: string;
          name: string;
          description: string;
          allergens: string;
          grossPrice: number;
          tax: number;
          isActive: boolean;
          sort: number;
          modifierId: string;
          modifierName: string;
          modifierMin: number;
          modifierMax: number;
          modifierIgnorePrice: boolean;
        } | null>;
      }>;
      invoiceUrl?: string | null;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetOrdersByCompanyIdQueryVariables = {
  input: GetOrdersByCompanyId;
};

export type GetOrdersByCompanyIdQuery = {
  getOrdersByCompanyId?: {
    __typename: 'OrdersResponse';
    data?: Array<{
      __typename: 'Order';
      id: string;
      orderState: OrderStateEnum;
      isBatchOrder: boolean;
      companyId: string;
      userId: string;
      companyName: string;
      userName: string;
      tip: number;
      orderType: OrderTypeEnum;
      orderDate: string;
      deliveryDate: string;
      thermoBoxes: number;
      comment: string;
      discount: number;
      discountArticleId: string;
      tableId: string;
      paymentId: string;
      name: string;
      surname: string;
      address: string;
      zip: string;
      phoneNumber: string;
      articles: Array<{
        __typename: 'Article';
        id: string;
        isMainDish: boolean;
        hasThermobox: boolean;
        isLocked: boolean;
        isDeprecated: boolean;
        imageId: string;
        maximumOrders: number;
        melzerId: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        articleGroupMelzerId: string;
        articleGroupName: string;
        articleGroupFromHour: string;
        articleGroupToHour: string;
        modifiers: Array<{
          __typename: 'Modifier';
          id: string;
          name: string;
          description: string;
          allergens: string;
          grossPrice: number;
          tax: number;
          isActive: boolean;
          sort: number;
          modifierId: string;
          modifierName: string;
          modifierMin: number;
          modifierMax: number;
          modifierIgnorePrice: boolean;
        } | null>;
      }>;
      invoiceUrl?: string | null;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetThermoboxCountsQuery = {
  getThermoboxCounts?: {
    __typename: 'ThermoboxCountResponse';
    data?: Array<{
      __typename: 'ThermoboxCount';
      ownerId: string;
      ownerName: string;
      email?: string | null;
      phone?: string | null;
      quantity: number;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetOrdersByDateRangeAndCompanyIdQueryVariables = {
  input: GetOrdersByDateRangeAndCompanyIdInput;
};

export type GetOrdersByDateRangeAndCompanyIdQuery = {
  getOrdersByDateRangeAndCompanyId?: {
    __typename: 'OrderConnection';
    data?: Array<{
      __typename: 'Order';
      id: string;
      orderState: OrderStateEnum;
      isBatchOrder: boolean;
      companyId: string;
      userId: string;
      companyName: string;
      userName: string;
      tip: number;
      orderType: OrderTypeEnum;
      orderDate: string;
      deliveryDate: string;
      thermoBoxes: number;
      comment: string;
      discount: number;
      discountArticleId: string;
      tableId: string;
      paymentId: string;
      name: string;
      surname: string;
      address: string;
      zip: string;
      phoneNumber: string;
      articles: Array<{
        __typename: 'Article';
        id: string;
        isMainDish: boolean;
        hasThermobox: boolean;
        isLocked: boolean;
        isDeprecated: boolean;
        imageId: string;
        maximumOrders: number;
        melzerId: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        articleGroupMelzerId: string;
        articleGroupName: string;
        articleGroupFromHour: string;
        articleGroupToHour: string;
        modifiers: Array<{
          __typename: 'Modifier';
          id: string;
          name: string;
          description: string;
          allergens: string;
          grossPrice: number;
          tax: number;
          isActive: boolean;
          sort: number;
          modifierId: string;
          modifierName: string;
          modifierMin: number;
          modifierMax: number;
          modifierIgnorePrice: boolean;
        } | null>;
      }>;
      invoiceUrl?: string | null;
    }> | null;
    nextToken?: {
      __typename: 'GsiTypeCompositeToken';
      pk: string;
      sk: string;
      typename: string;
      GSITYPESK: string;
    } | null;
  } | null;
};

export type GetOrderArticleCountByDateRangeQueryVariables = {
  input: GetOrderArticleCountByDateRangeInput;
};

export type GetOrderArticleCountByDateRangeQuery = {
  getOrderArticleCountByDateRange?: {
    __typename: 'OrderArticleCountResponse';
    data?: Array<{
      __typename: 'OrderArticleCounts';
      date: string;
      counts: Array<{
        __typename: 'OrderArticleCount';
        count: number;
        articleName: string;
      }>;
    }> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetOrdersByDateRangeQueryVariables = {
  input: GetOrdersByDateRangeInput;
};

export type GetOrdersByDateRangeQuery = {
  getOrdersByDateRange?: {
    __typename: 'OrderConnection';
    data?: Array<{
      __typename: 'Order';
      id: string;
      orderState: OrderStateEnum;
      isBatchOrder: boolean;
      companyId: string;
      userId: string;
      companyName: string;
      userName: string;
      tip: number;
      orderType: OrderTypeEnum;
      orderDate: string;
      deliveryDate: string;
      thermoBoxes: number;
      comment: string;
      discount: number;
      discountArticleId: string;
      tableId: string;
      paymentId: string;
      name: string;
      surname: string;
      address: string;
      zip: string;
      phoneNumber: string;
      articles: Array<{
        __typename: 'Article';
        id: string;
        isMainDish: boolean;
        hasThermobox: boolean;
        isLocked: boolean;
        isDeprecated: boolean;
        imageId: string;
        maximumOrders: number;
        melzerId: string;
        name: string;
        description: string;
        allergens: string;
        grossPrice: number;
        tax: number;
        articleGroupMelzerId: string;
        articleGroupName: string;
        articleGroupFromHour: string;
        articleGroupToHour: string;
        modifiers: Array<{
          __typename: 'Modifier';
          id: string;
          name: string;
          description: string;
          allergens: string;
          grossPrice: number;
          tax: number;
          isActive: boolean;
          sort: number;
          modifierId: string;
          modifierName: string;
          modifierMin: number;
          modifierMax: number;
          modifierIgnorePrice: boolean;
        } | null>;
      }>;
      invoiceUrl?: string | null;
    }> | null;
    nextToken?: {
      __typename: 'GsiTypeCompositeToken';
      pk: string;
      sk: string;
      typename: string;
      GSITYPESK: string;
    } | null;
  } | null;
};

export type GetUploadUrlQueryVariables = {
  input: UploadFileInput;
};

export type GetUploadUrlQuery = {
  getUploadUrl?: {
    __typename: 'UploadResponse';
    data?: {
      __typename: 'UploadResponseDetails';
      uploadUrl: string;
      key: string;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetDownloadUrlQueryVariables = {
  input: DownloadFileInput;
};

export type GetDownloadUrlQuery = {
  getDownloadUrl?: {
    __typename: 'DownloadResponse';
    data?: string | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetUserByIdQueryVariables = {
  input: GetUserByIdInput;
};

export type GetUserByIdQuery = {
  getUserById?: {
    __typename: 'UserResult';
    data?: {
      __typename: 'User';
      id: string;
      email: string;
      name: string;
      surname: string;
      mobileNumber: string;
      address: string;
      zip: string;
      companyId: string;
      createdAt: string;
      newsletter: boolean;
      hasReadAgb: boolean;
      isCompanyAdmin: boolean;
      canOrderOnBill?: boolean | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetUserByEmailQueryVariables = {
  input: GetUserByEmailInput;
};

export type GetUserByEmailQuery = {
  getUserByEmail?: {
    __typename: 'UserResult';
    data?: {
      __typename: 'User';
      id: string;
      email: string;
      name: string;
      surname: string;
      mobileNumber: string;
      address: string;
      zip: string;
      companyId: string;
      createdAt: string;
      newsletter: boolean;
      hasReadAgb: boolean;
      isCompanyAdmin: boolean;
      canOrderOnBill?: boolean | null;
    } | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};

export type GetUsersByCompanyIdQueryVariables = {
  input: GetUsersByCompanyIdInput;
};

export type GetUsersByCompanyIdQuery = {
  getUsersByCompanyId?: {
    __typename: 'UsersResult';
    data?: Array<{
      __typename: 'User';
      id: string;
      email: string;
      name: string;
      surname: string;
      mobileNumber: string;
      address: string;
      zip: string;
      companyId: string;
      createdAt: string;
      newsletter: boolean;
      hasReadAgb: boolean;
      isCompanyAdmin: boolean;
      canOrderOnBill?: boolean | null;
    } | null> | null;
    error?: {
      __typename: 'Error';
      message: string;
    } | null;
  } | null;
};
