import { useDispatch } from '../store/ImageCache';

interface IReturn {
  addImage(key: string, url: string): void;
}

const useImageCacheActions = (): IReturn => {
  const dispatch = useDispatch();

  const addImage = (key: string, url: string): void => {
    dispatch({
      type: 'addImage',
      payload: { key, url },
    });
  };

  return { addImage };
};

export default useImageCacheActions;
