import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';

const Contact = (): JSX.Element => (
  <Box p={10}>
    <Typography variant="h4" gutterBottom>
      Kontakt
    </Typography>
    <Typography
      color="textSecondary"
      variant="body1"
      style={{ whiteSpace: 'pre-line' }}
    >
      {`
          CCG Meetingpoint
          GmbH & Co KG
          
          Am Terminal 1,
          8402 Werndorf

        `}

      <Link href="tel:+43313593031">T +43 (0) 3135 930 31</Link>
      <br />
      <Link href="mailto:reservierung@ccg-meetingpoint.at">
        reservierung@ccg-meetingpoint.at
      </Link>
    </Typography>
  </Box>
);

export default Contact;
