import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { List, Typography } from '@material-ui/core';

import useStyles from './CompanyUserList.css';
import CompanyUserListItem from './CompanyUserListItem';
import useUserActions from './useUserActions';
import { useTrackedState } from '../../store/Users';

interface IProps {
  companyId: string;
}

const CompanyUserList = ({ companyId }: IProps): JSX.Element => {
  const { users, loading, error } = useTrackedState();
  const { fetch, deleteUsr, update } = useUserActions(companyId);
  const classes = useStyles();

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return (
      <div className={classes.skeletonRoot}>
        <Skeleton variant="rect" height={48} />
        <Skeleton variant="rect" height={48} />
        <Skeleton variant="rect" height={48} />
        <Skeleton variant="rect" height={48} />
        <Skeleton variant="rect" height={48} />
        <Skeleton variant="rect" height={48} />
      </div>
    );
  }

  if (error) {
    return (
      <Typography variant="body2" color="error">
        {error}
      </Typography>
    );
  }

  if (users.length === 0) {
    return (
      <>
        <Typography variant="body2">Noch keine User hinzugefügt</Typography>
      </>
    );
  }

  return (
    <List>
      {users.map((user) => (
        <CompanyUserListItem
          user={user}
          deleteUser={deleteUsr}
          updateUser={update}
          key={`cmpnyusrlistitm${user.id}`}
        />
      ))}
    </List>
  );
};

export default CompanyUserList;
