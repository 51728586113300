import React from 'react';
import {
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';

import useStyles from './EnterConfirmationCodeForm.css';
import { useTrackedState } from '../../store/Auth';
import useAuthActions from '../../hooks/useAuthActions';

const EnterConfirmationCodeForm = (): JSX.Element => {
  const classes = useStyles();
  const { isAuthenticating, tempMail } = useTrackedState();
  const { confirmResetPw, resetAuthState } = useAuthActions();
  const [code, setCode] = React.useState('');
  const [newPw, setNewPw] = React.useState('');

  const confirmReset = () => {
    confirmResetPw(code, newPw, tempMail as string);
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <UpdateIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Neues Passwort erstellen
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="code"
          label="Bestätigungscode"
          value={code}
          onChange={(ev) => setCode(ev.target.value)}
          id="code"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="pwd"
          label="Neues Passwort"
          type="password"
          name="pwd"
          autoComplete="new-password"
          value={newPw}
          onChange={(ev) => setNewPw(ev.target.value)}
          autoFocus
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isAuthenticating || !code || !newPw}
          onClick={confirmReset}
        >
          {isAuthenticating ? (
            <CircularProgress size={25} />
          ) : (
            'Passwort zurücksetzen'
          )}
        </Button>
        <Grid container className={classes.altButtons}>
          <Grid item xs>
            <Button
              disabled={isAuthenticating}
              color="secondary"
              size="small"
              variant="text"
              onClick={resetAuthState}
            >
              Abbrechen
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EnterConfirmationCodeForm;
