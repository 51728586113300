import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  itemCardRoot: {},
  cardMedia: {
    height: '150px',
    margin: '12px',
    borderRadius: '5px',
  },
  shoppingCartBanner: {
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: '0px',
    marginLeft: '-10px',
    marginRight: '-10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      bottom: '20px',
      marginLeft: '10px',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      bottom: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
      bottom: '20px',
    },
  },
  shoppingActions: {
    height: '80px',
  },
  bannerPrice: {
    fontSize: 21,
  },
  priceChip: {
    fontSize: 14,
    marginTop: '10px',
    display: 'flex',
  },
  allergensText: {
    fontSize: 13,
  },
  bannerText: {
    fontSize: 20,
  },
}));

export default useStyles;
