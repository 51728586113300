import React from 'react';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Skeleton } from '@material-ui/lab';

import useFileDownload from '../../hooks/useFileDownload';
import useStyles from './AppCheckout.css';
import { getSingleArticlePrice } from '../../util/util';
import useCartActions from '../appOrder/useCartActions';
import { IArticleAggregation } from './types';
import { IArticle } from '../../api/Article';

interface IProps {
  item: IArticleAggregation;
  isGuest: boolean;
  editView: boolean;
}

interface IImageProps {
  imageKey: string;
  name: string;
  isGuest: boolean;
}

const OrderImage = ({ imageKey, name, isGuest }: IImageProps): JSX.Element => {
  const classes = useStyles();
  const { url, loading } = useFileDownload({ key: imageKey, isGuest });

  if (loading) {
    return (
      <Skeleton
        variant="rect"
        className={classes.itemImg}
        width="150px"
        height="100px"
      />
    );
  }
  return <img alt={name} src={url} className={classes.itemImg} />;
};

const OrderListItem = ({ item, isGuest, editView }: IProps): JSX.Element => {
  const { removeItem, addItem, removeAllItems } = useCartActions();
  const itemPrice = getSingleArticlePrice(item.grossPrice);
  const itemWithoutModifiers: IArticle = {
    allergens: item.allergens,
    articleGroupFromHour: item.articleGroupFromHour,
    articleGroupMelzerId: item.articleGroupMelzerId,
    articleGroupName: item.articleGroupName,
    articleGroupToHour: item.articleGroupToHour,
    description: item.description,
    grossPrice: item.grossPrice,
    hasThermobox: item.hasThermobox,
    maximumOrders: item.maximumOrders,
    id: item.id,
    imageId: item.imageId,
    isDeprecated: item.isDeprecated,
    isLocked: item.isLocked,
    isMainDish: item.isMainDish,
    melzerId: item.melzerId,
    name: item.name,
    tax: item.tax,
    modifiers: [],
  };
  const classes = useStyles();
  return (
    <>
      <ListItem alignItems="flex-start">
        {editView && (
          <OrderImage
            imageKey={item.imageId}
            isGuest={isGuest}
            name={item.name}
          />
        )}
        <ListItemText
          primary={item.name}
          secondary={
            <>
              {`Anzahl: ${item.quantity}`}
              <br />
              {`Einzelpreis: €${itemPrice}`}
              <br />
              {`Gesamtpreis: €${
                Math.round(itemPrice * item.quantity * 100) / 100
              }`}
              <br />
              {`Extras: €${Math.round(item.modifiersPrice * 100) / 100}`}
            </>
          }
        />
        {editView && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => addItem(itemWithoutModifiers)}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="remove"
              onClick={() => removeItem(item.id)}
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              className={classes.deleteIcon}
              onClick={() => removeAllItems(item.id)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </>
  );
};

export default OrderListItem;
