import React from 'react';

import { Provider as CompanyProvider } from '../../store/Companies';
import { RoleEnum, useTrackedState as useAuthState } from '../../store/Auth';
import AdminView from './AdminView';
import CompanyAdminView from './CompanyAdminView';

const AppOrderManagment = (): JSX.Element => {
  const { role, companyDetails } = useAuthState();

  if (role === RoleEnum.ADMIN) {
    return (
      <CompanyProvider>
        <AdminView />
      </CompanyProvider>
    );
  }

  if (role === RoleEnum.COMPANYADMIN) {
    return <CompanyAdminView companyId={companyDetails?.id} />;
  }

  return <div />;
};

export default AppOrderManagment;
