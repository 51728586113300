import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import { Box, Fab } from '@material-ui/core';

interface IProps {
  bottom: number;
  right: number;
}

const PrintButton = ({ bottom, right }: IProps): JSX.Element => {
  return (
    <Box displayPrint="none">
      <Fab
        variant="extended"
        color="primary"
        style={{
          position: 'fixed',
          bottom,
          right,
        }}
        onClick={() => window.print()}
      >
        <PrintIcon style={{ marginRight: '5px' }} />
        Drucken
      </Fab>
    </Box>
  );
};

export default PrintButton;
