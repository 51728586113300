import React from 'react';

import { INews, getNews } from '../api/News';

interface IUseNews {
  news: INews;
  loading: boolean;
  error: string;
  refetch(): void;
}

const useNews = (isGuest: boolean): IUseNews => {
  const [news, setNews] = React.useState<INews>({
    id: '',
    title: 'Derzeit keine News',
    date: '',
    content: '',
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [refetchFlag, setRefetchFlag] = React.useState(true);

  const refetch = () => {
    setRefetchFlag(!refetchFlag);
  };

  React.useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setError('');
      try {
        const res = await getNews(isGuest);
        if (res.data?.getNews?.error) {
          setError(res.data.getNews.error.message);
        } else if (res.data?.getNews?.data) {
          setNews(res.data.getNews.data as INews);
        }
      } catch (err) {
        setError('News konnten nicht geladen werden');
      }
      setLoading(false);
    };
    fetch();
  }, [isGuest, refetchFlag]);

  return {
    news,
    loading,
    error,
    refetch,
  };
};

export default useNews;
