import React from 'react';
import { Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import useStyles from './AppUserManagement.css';
import AddCompanyDialog from './AddCompanyDialog';
import CompanyAccordion from './CompanyAccordion';
import SkeletonList from '../skeletonList/SkeletonList';
import { useTrackedState } from '../../store/Companies';
import useCompanyActions from './useCompanyActions';
import { Provider as UsersProvider } from '../../store/Users';
import Spacer from '../util/Spacer';
import UserDownloadButton from './UserDownloadButton';

const UserManagementAdminView = (): JSX.Element => {
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const classes = useStyles();
  const { fetch } = useCompanyActions();
  const { companies, loading, error } = useTrackedState();

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return <SkeletonList />;
  }

  if (error) {
    return (
      <Typography align="center" variant="body2" color="error">
        Error: Unternehmen konnten nicht geladen werden
      </Typography>
    );
  }
  return (
    <>
      {companies.map((company) => (
        <UsersProvider key={`cmpnyaccrdn-${company.companyName}`}>
          <CompanyAccordion company={company} single={false} />
        </UsersProvider>
      ))}
      <Fab
        variant="extended"
        className={classes.addFab}
        color="primary"
        onClick={() => setAddDialogOpen(true)}
      >
        <AddIcon /> Unternehmen Hinzufügen
      </Fab>
      <AddCompanyDialog
        open={addDialogOpen}
        close={() => setAddDialogOpen(false)}
      />
      <UserDownloadButton companies={companies} />
      <Spacer height={100} />
    </>
  );
};

export default UserManagementAdminView;
