import React from 'react';
import { createContainer } from 'react-tracked';

import { IArticle } from '../api/Article';

interface IArticlesState {
  articles: IArticle[];
  loading: boolean;
  error: string;
}

type ArticleAction =
  | { type: 'startLoading' }
  | { type: 'setError'; error: string }
  | { type: 'setArticles'; articles: IArticle[] }
  | { type: 'updateArticle'; newArticle: IArticle };

const initialState: IArticlesState = {
  articles: [],
  loading: false,
  error: '',
};

const articleReducer = (
  state: IArticlesState,
  action: ArticleAction
): IArticlesState => {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        loading: true,
      };

    case 'setError':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'setArticles':
      return {
        ...state,
        loading: false,
        error: '',
        articles: action.articles,
      };

    case 'updateArticle':
      return {
        ...state,
        loading: false,
        articles: state.articles.map((item) =>
          item.id === action.newArticle.id ? action.newArticle : item
        ),
      };

    default:
      return state;
  }
};

const useValue = () => React.useReducer(articleReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
