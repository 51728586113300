import React from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
  IDeliveryViewGroupedOrders,
  IDeliveryViewOrderDetails,
} from '../../hooks/useOrderActions';

interface ITableProps {
  orders: IDeliveryViewGroupedOrders;
  expandLvl2: boolean;
}

interface ITableRowProps {
  articleName: string;
  details: IDeliveryViewOrderDetails[];
  expandLvl2: boolean;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const DeliveryTableDetailRow = ({
  articleName,
  details,
  expandLvl2,
}: ITableRowProps): JSX.Element => {
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const rowClasses = useRowStyles();
  React.useEffect(() => {
    setDetailsOpen((prev) => {
      if (prev !== expandLvl2) {
        return expandLvl2;
      }
      return prev;
    });
  }, [expandLvl2]);
  return (
    <>
      <TableRow className={rowClasses.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setDetailsOpen((open) => !open)}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{articleName}</TableCell>
        <TableCell>{details.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          {detailsOpen && (
            <Box
              p={2}
              m={1}
              style={{
                borderRadius: '2px',
                border: '1px solid green',
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Extras</TableCell>
                    <TableCell>Kommentar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((detail, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={`detailtablerow-${index}`}>
                      <TableCell>{detail.user}</TableCell>
                      <TableCell>
                        {detail.extras.length > 0
                          ? detail.extras.join(', ')
                          : 'keine'}
                      </TableCell>
                      <TableCell>{detail.comment}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

const DeliveryViewTable = ({
  orders,
  expandLvl2,
}: ITableProps): JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Artikel</TableCell>
            <TableCell align="left">Anzahl</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(orders).map((articleGroup) => (
            <DeliveryTableDetailRow
              articleName={articleGroup}
              details={orders[articleGroup]}
              expandLvl2={expandLvl2}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeliveryViewTable;
