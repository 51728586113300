import React from 'react';
import { SetMenuInput } from '../../api/API';
import { getMenusByDateRange, IMenu, setMenu } from '../../api/Menu';
import useNotificationActions from '../../hooks/useNotificationActions';
import { useDispatch } from '../../store/Menu';

interface IReturn {
  fetch(isGuest: boolean, from: string, to: string): void;
  updateMenu(input: SetMenuInput): void;
}

const useMenuActions = (): IReturn => {
  const dispatch = useDispatch();
  const { show } = useNotificationActions();

  const fetch = React.useCallback(
    async (isGuest: boolean, from: string, to: string) => {
      dispatch({ type: 'startLoading' });
      try {
        const res = await getMenusByDateRange(isGuest, { from, to });
        if (res.data?.getMenusByDateRange?.error) {
          dispatch({
            type: 'setError',
            error: 'Menüs konnten nicht geladen werden',
          });
        }

        if (res.data?.getMenusByDateRange?.data) {
          dispatch({
            type: 'setMenus',
            menus: res.data?.getMenusByDateRange?.data as IMenu[],
          });
        }
      } catch (error) {
        dispatch({
          type: 'setError',
          error: 'Menüs konnten nicht geladen werden',
        });
      }
    },
    [dispatch]
  );

  const updateMenu = async (input: SetMenuInput) => {
    // dispatch({ type: 'startLoading' });
    try {
      await setMenu(input);
      dispatch({ type: 'updateMenu', updated: input });
    } catch (error) {
      show('Error', 'Menü konnte nicht gespeichert werden', 'error');
      // dispatch({ type: 'stopLoading' });
    }
  };

  return {
    fetch,
    updateMenu,
  };
};

export default useMenuActions;
