/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Grid, Paper, Slide, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import routes from '../router/routes';
import useStyles from './Home.css';
import pizza from '../../static/images/pizza.jpg';
import LoginForm from '../loginForm/LoginForm';
import { useTrackedState } from '../../store/Auth';
import SetNewPwForm from '../setNewPwForm/SetNewPwForm';
import ForgotPasswordForm from '../forgotPasswordForm/ForgotPasswordForm';
import EnterConfirmationCodeForm from '../enterConfirmationCodeForm/EnterConfirmationCodeForm';
import useAuthActions from '../../hooks/useAuthActions';

const Home = (): JSX.Element => {
  const {
    user,
    passwordResetNeeded,
    forgotPasswordCodeSent,
    forgotPassword,
  } = useTrackedState();
  const { checkSession } = useAuthActions();
  const history = useHistory();
  const classes = useStyles();

  // on load, check auth session
  React.useEffect(() => {
    checkSession();
  }, []);

  // if user is logged in, send him to app route
  React.useEffect(() => {
    if (user) {
      history.push(routes.app.order);
    }
  }, [user]);

  const CurrentForm = (): JSX.Element => {
    if (forgotPasswordCodeSent) {
      return <EnterConfirmationCodeForm />;
    }

    if (forgotPassword) {
      return <ForgotPasswordForm />;
    }

    if (passwordResetNeeded) {
      return <SetNewPwForm />;
    }

    return <LoginForm />;
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Slide in direction="right">
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          className={classes.image}
          style={{ backgroundImage: `url(${pizza})` }}
        >
          <Box display="flex" height="100%" flexDirection="column">
            <Box marginTop="auto">
              <Box component={Paper} p={2} m={[4, 3, 8]}>
                <Typography variant="h6" color="primary" gutterBottom>
                  „WAS IM CCG MEETINGPOINT SCHMECKT, SCHMECKT AUCH AM
                  ARBEITSPLATZ ODER ZUHAUSE”
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  Die Besonderheiten der einzelnen Büros und Hallen hinsichtlich
                  der Versorgung liegen uns am Herzen — wir bemühen uns und
                  bitten dafür um das direkte und aktive Gespräch.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Slide>
      <Slide in direction="left">
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <CurrentForm />
        </Grid>
      </Slide>
    </Grid>
  );
};

export default Home;
