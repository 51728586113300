import React from 'react';
import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  IconButton,
  Chip,
  Button,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { IOrder } from '../../api/Order';
import { getHoursFromISO, getReadableDateFromISO } from '../../util/time';
import { getOrderTypeLabel, getOrderStateLabel } from '../../util/translate';
import { IArticle, IModifier } from '../../api/Article';
import useStyles from '../appOrderManagement/AppOrderManagement.css';
import {
  getSingleArticlePriceWithModifiers,
  calculateTotalPrice,
  removeDuplicates,
} from '../../util/util';
import useOrderActions from '../../hooks/useOrderActions';
import { RoleEnum, useTrackedState as useAuthState } from '../../store/Auth';
import { OrderTypeEnum } from '../../api/API';

interface IProps {
  order: IOrder;
  articleAmount: number;
  expandLvl2: boolean;
}

const getModifiersAsString = (modifiers: IModifier[]): string => {
  const names = modifiers.map((modifier) => modifier.name);
  return names.join(',');
};

const OrderTableRow = ({
  order,
  articleAmount,
  expandLvl2,
}: IProps): JSX.Element => {
  const [detailsOpen, setDetailsOpen] = React.useState(expandLvl2);
  const [deleting, setDeleting] = React.useState(false);
  const reducedArticles = removeDuplicates<IArticle>(order.articles);
  const classes = useStyles();
  const { deleteOrder } = useOrderActions();
  const { role } = useAuthState();

  React.useEffect(() => {
    setDetailsOpen((prev) => {
      if (prev !== expandLvl2) {
        return expandLvl2;
      }
      return prev;
    });
  }, [expandLvl2]);

  const removeOrder = async (orderId: string) => {
    setDeleting(true);
    await deleteOrder(orderId);
    setDeleting(false);
  };

  return (
    <>
      <TableRow className={classes.removeTableBottomBorder}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setDetailsOpen((open) => !open)}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {order.isBatchOrder && (
            <Chip size="small" color="primary" label="Sammelbestellung" />
          )}
        </TableCell>
        <TableCell>{order.companyName}</TableCell>
        <TableCell align="right">{order.userName}</TableCell>
        <TableCell align="right">{articleAmount}</TableCell>
        <TableCell align="right">
          {`${getOrderTypeLabel(order.orderType)}${
            order.orderType === OrderTypeEnum.PICKUP
              ? `(${getHoursFromISO(order.deliveryDate)})`
              : ''
          }`}
        </TableCell>
        <TableCell align="right">
          {calculateTotalPrice(order.articles)}
        </TableCell>
        <TableCell align="right">
          {getOrderStateLabel(order.orderState)}
        </TableCell>
        {role === RoleEnum.ADMIN && (
          <TableCell align="right">
            <Button
              onClick={() => removeOrder(order.id)}
              disabled={deleting}
              size="small"
              className={classes.errorButton}
            >
              Löschen
            </Button>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={detailsOpen} timeout="auto" unmountOnExit>
            <Box margin={1} className={classes.orderDetails}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Typography variant="caption" gutterBottom component="div">
                {order.comment && `Kommentar: ${order.comment}`}
              </Typography>
              <Typography variant="caption" gutterBottom component="div">
                {`Bestelldatum: ${getReadableDateFromISO(order.orderDate)}`}
              </Typography>
              <Typography variant="caption" gutterBottom component="div">
                {`Lieferdatum: ${getReadableDateFromISO(order.deliveryDate)}`}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Artikel</TableCell>
                    <TableCell>Anzahl</TableCell>
                    <TableCell>Extras</TableCell>
                    <TableCell align="right">Preis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reducedArticles.map((article, index) => {
                    const amount = order.articles.filter(
                      (a) => a.id === article.id
                    ).length;
                    return (
                      <TableRow
                        // eslint-disable-next-line react/no-array-index-key
                        key={`orderdetails${order.id}${article.id}${index}`}
                      >
                        <TableCell>{article.name}</TableCell>
                        <TableCell>{amount}</TableCell>
                        <TableCell>
                          {getModifiersAsString(article.modifiers)}
                        </TableCell>
                        <TableCell align="right">
                          €{getSingleArticlePriceWithModifiers(article)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {order.tip > 0 && (
                    <TableRow>
                      <TableCell>Trinkgeld</TableCell>
                      <TableCell>{1}</TableCell>
                      <TableCell />
                      <TableCell align="right">€{order.tip / 100}</TableCell>
                    </TableRow>
                  )}
                  {order.discount > 0 && (
                    <TableRow>
                      <TableCell>Firmenzuschuss</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="right">
                        - €{order.discount / 100}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow className={classes.removeTableBottomBorder}>
                    <TableCell />
                    <TableCell style={{ fontWeight: 'bold' }}>Gesamt</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      €
                      {calculateTotalPrice(
                        order.articles,
                        order.discount,
                        order.tip
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderTableRow;
