import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from '@material-ui/core';

import useStyles from './AppOrder.css';
import { IArticle } from '../../api/Article';
import useFileDownload from '../../hooks/useFileDownload';
import AddItemDialog from './AddItemDialog';

interface IProps {
  article: IArticle;
  isGuest: boolean;
  addDisabled: boolean;
}

const ItemCard = ({ article, isGuest, addDisabled }: IProps): JSX.Element => {
  const { url } = useFileDownload({ key: article.imageId, isGuest });
  const classes = useStyles();
  const [addOpen, setAddOpen] = React.useState(false);

  const getPrice = (gross: number) => {
    const price = gross / 100;
    return Math.round(price * 100) / 100;
  };

  return (
    <Card className={classes.itemCardRoot} elevation={12}>
      <CardActionArea onClick={() => setAddOpen(true)}>
        <CardMedia image={url} className={classes.cardMedia} />
        <CardContent>
          <Typography variant="h6" align="center" gutterBottom>
            {article.name}
          </Typography>
          <Typography
            align="center"
            className={classes.allergensText}
            gutterBottom
          >
            {article.allergens
              ? `Allergene: ${article.allergens}`
              : 'keine Allergene angegeben'}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Chip
              label={`€ ${getPrice(article.grossPrice)}`}
              color="primary"
              className={classes.priceChip}
            />
          </div>
        </CardContent>
      </CardActionArea>
      <AddItemDialog
        addDisabled={addDisabled}
        open={addOpen}
        close={() => setAddOpen(false)}
        item={article}
        imageUrl={url}
      />
    </Card>
  );
};

export default ItemCard;
