/* eslint-disable prettier/prettier */
import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Imprint = (): JSX.Element => {
  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom>Impressum</Typography>
      <Typography
        color="textSecondary"
        variant="body1"
        style={{whiteSpace: 'pre-line'}}
      >
        {
        `CCG Meetingpoint GmbH & Co KG
          Am Terminal 1
          8402 Werndorf
          ATU73226902

          T +43 (0) 3135 930 31

          reservierung@ccg-meetingpoint.at
          Steiermärkische Bank und Sparkassen AG
          IBAN: AT102081500042085928 BIC: STPAT2G

          fn489370m
          Landesgericht für ZRS Graz

          Datenschutzbeauftragter:
          office@brus.st
          www.brus.st
          DB IT-Service`
        }
      </Typography>
    </Box>
  );
};

export default Imprint;

