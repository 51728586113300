import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Provider as ArticleProvider } from '../../store/Articles';
import { Provider as OrderProvider } from '../../store/Orders';
import { Provider as BoxProvider } from '../../store/Thermoboxes';
import { Provider as CompanyProvider } from '../../store/Companies';
import { useTrackedState, RoleEnum } from '../../store/Auth';
import AppArticleManagement from '../appArticleManagement/AppArticleManagement';
import AppBoxManagement from '../appBoxManagement/AppBoxManagement';
import AppLayout from '../appLayout/AppLayout';
import AppMenuManagement from '../appMenuManagement/AppMenuManagement';
import AppOrder from '../appOrder/AppOrder';
import AppOrderManagment from '../appOrderManagement/AppOrderManagement';
import AppReporting from '../appReporting/AppReporting';
import AppUserManagment from '../appUserManagement/AppUserManagement';
import NotFoundPage from '../notFoundPage/NotFoundPage';
import routes from '../router/routes';
import AppCheckout from '../appCheckout/AppCheckout';
import WeeklyManagement from '../appWeeklyManagement/WeeklyManagement';
import AppCompanyReport from '../appCompanyReport/AppCompanyReport';

const guestRoutes = [
  routes.app.order,
  routes.app.checkout,
  routes.app.menuManagement,
];
const userRoutes = [...guestRoutes];
const companyAdminRoutes = [
  ...userRoutes,
  routes.app.userManagement,
  routes.app.orderOverview,
];
const adminRoutes = [
  ...companyAdminRoutes,
  routes.app.articleManagement,
  routes.app.boxManagement,
  routes.app.weeklyManagement,
  routes.app.companyReport,
];

const App: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user, role, userDetails } = useTrackedState();

  React.useEffect(() => {
    const checkPermissions = () => {
      if (!user) {
        history.push('/');
        return;
      }

      switch (role) {
        case RoleEnum.GUEST:
          if (!guestRoutes.includes(pathname)) {
            history.push('/');
          }
          return;

        case RoleEnum.USER:
          if (!userRoutes.includes(pathname)) {
            history.push('/');
          }
          return;

        case RoleEnum.COMPANYADMIN:
          if (!companyAdminRoutes.includes(pathname)) {
            history.push('/');
          }
          return;

        case RoleEnum.ADMIN:
          if (!adminRoutes.includes(pathname)) {
            history.push('/');
          }
          return;

        default:
          history.push('/');
      }
    };
    checkPermissions();
  }, [user, role, history, pathname]);

  const AppRoute = (): JSX.Element => {
    switch (pathname) {
      case routes.app.order:
        return <AppOrder />;

      case routes.app.checkout:
        return <AppCheckout />;

      case routes.app.orderOverview:
        return (
          <OrderProvider>
            <AppOrderManagment />
          </OrderProvider>
        );

      case routes.app.userManagement:
        return (
          <AppUserManagment
            adminView={role === RoleEnum.ADMIN}
            companyId={userDetails?.companyId || ''}
          />
        );

      case routes.app.articleManagement:
        return (
          <ArticleProvider>
            <AppArticleManagement />
          </ArticleProvider>
        );

      case routes.app.menuManagement:
        return <AppMenuManagement />;

      case routes.app.boxManagement:
        return (
          <BoxProvider>
            <AppBoxManagement />
          </BoxProvider>
        );

      case routes.app.reporting:
        return (
          <OrderProvider>
            <AppReporting />
          </OrderProvider>
        );

      case routes.app.weeklyManagement:
        return <WeeklyManagement />;

      case routes.app.companyReport:
        return (
          <CompanyProvider>
            <OrderProvider>
              <AppCompanyReport />
            </OrderProvider>
          </CompanyProvider>
        );

      default:
        return <NotFoundPage />;
    }
  };

  return (
    <AppLayout>
      <AppRoute />
    </AppLayout>
  );
};

export default App;
