import { MenuEnum, OrderStateEnum, OrderTypeEnum } from '../api/API';

export const getOrderTypeLabel = (orderType: OrderTypeEnum): string => {
  if (orderType === OrderTypeEnum.DELIVERY) {
    return 'Zustellung';
  }
  return 'Abholung';
};

export const getOrderStateLabel = (orderState: OrderStateEnum): string => {
  if (orderState === OrderStateEnum.PAID) {
    return 'Bezahlt';
  }

  if (orderState === OrderStateEnum.BILL) {
    return 'Auf Rechnung';
  }
  return 'Nicht Bezahlt';
};

export const getMenuTypeLabel = (menuType: MenuEnum): string => {
  switch (menuType) {
    case MenuEnum.MENU1:
      return 'Menü 1';
    case MenuEnum.MENU2:
      return 'Menü 2';
    case MenuEnum.MENU3:
      return 'Menü 3';
    case MenuEnum.MENU4:
      return 'Menü 4';
    case MenuEnum.MENU5:
      return 'Menü 5';
    case MenuEnum.MENU6:
      return 'Menü 6';
    case MenuEnum.MENU7:
      return 'Menü 7';
    case MenuEnum.ALT_MENU1:
      return 'Menü 8';
    case MenuEnum.ALT_MENU2:
      return 'Menü 9';
    default:
      return menuType;
  }
};
