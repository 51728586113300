import React from 'react';
import { createContainer } from 'react-tracked';

import { IArticle } from '../api/Article';

interface ICartState {
  items: IArticle[];
  loading: boolean;
  error: string;
}

type CartAction =
  | { type: 'startLoading' }
  | { type: 'setError'; error: string }
  | { type: 'addItem'; newItem: IArticle }
  | { type: 'setItems'; newItems: IArticle[] }
  | { type: 'removeAllItems'; toRemoveId: string }
  | { type: 'clearCart' };

const initialState: ICartState = {
  items: [],
  loading: false,
  error: '',
};

const cartReducer = (state: ICartState, action: CartAction): ICartState => {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        loading: true,
      };

    case 'setError':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'addItem':
      return {
        ...state,
        loading: false,
        error: '',
        items: [...state.items, action.newItem],
      };

    case 'setItems':
      return {
        ...state,
        loading: false,
        items: action.newItems,
      };

    case 'removeAllItems':
      return {
        ...state,
        loading: false,
        items: state.items.filter((item) => item.id !== action.toRemoveId),
      };

    case 'clearCart': {
      return {
        ...state,
        loading: false,
        items: [],
      };
    }

    default:
      return state;
  }
};

const useValue = () => React.useReducer(cartReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
