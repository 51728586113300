/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Paper, Grid, Typography, TextField } from '@material-ui/core';
import {
  DateRange,
  DateRangeDelimiter,
  DesktopDateRangePicker,
} from '@material-ui/pickers';
import { DateTime } from 'luxon';

import { ICompany } from '../../api/Company';
import { useTrackedState as useOrderState } from '../../store/Orders';
import useOrderActions from '../../hooks/useOrderActions';
import DownloadButton from '../appOrderManagement/DownloadButton';
import OrderTable from '../orderTable/OrderTable';
import SkeletonList from '../skeletonList/SkeletonList';

interface IProps {
  companies: ICompany[];
}

const AllCompaniesView = ({ companies }: IProps): JSX.Element => {
  const { orders, error, loading } = useOrderState();
  const { fetchByDateRange } = useOrderActions();
  const [dateRangeError, setDateRangeError] = React.useState('');
  const [dateRange, setDateRange] = React.useState<DateRange<DateTime>>([
    DateTime.now(),
    DateTime.now(),
  ]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dateError, setDateError] = React.useState<any[]>([null, null]);

  React.useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const diff = dateRange[1].diff(dateRange[0], 'days').days;
      if (diff > 35) {
        setDateRangeError('Maximaler Zeitraum: 35 Tage');
      } else {
        setDateRangeError('');
        const from = dateRange[0].toString();
        const to = dateRange[1].toString();
        fetchByDateRange({
          from,
          to,
        });
      }
    }
  }, [dateRange, fetchByDateRange]);

  if (error) {
    return (
      <Typography align="center" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <>
      <Box paddingY={3} marginBottom={1} component={Paper}>
        <Grid container direction="column" alignItems="center">
          <Typography
            align="center"
            variant="body1"
            color="primary"
            gutterBottom
          >
            Wählen Sie hier den Zeitraum aus:
          </Typography>
          <DesktopDateRangePicker
            startText="Start"
            endText="Ende"
            allowSameDateSelection
            onError={([startReason, endReason], [start, end]) => {
              if (
                startReason === 'invalidRange' &&
                start?.toString() === end?.toString()
              ) {
                setDateError([null, null]);
                return;
              }

              setDateError([startReason, endReason]);
            }}
            value={dateRange}
            onChange={(newValue) => {
              if (newValue[0] && newValue[1]) {
                const endOfDay = newValue[1].endOf('day');
                setDateRange([newValue[0], endOfDay]);
              }
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} error={Boolean(dateError[0])} />
                <DateRangeDelimiter> bis </DateRangeDelimiter>
                <TextField {...endProps} error={Boolean(error[1])} />
              </>
            )}
          />
          <Typography
            style={{ marginTop: '10px' }}
            align="center"
            variant="body2"
            color="error"
          >
            {dateRangeError}
          </Typography>
        </Grid>
      </Box>
      {loading ? (
        <SkeletonList />
      ) : (
        <>
          <OrderTable orders={orders} expandLvl2={false} />
          <DownloadButton orders={orders} companies={companies} />
        </>
      )}
    </>
  );
};

export default AllCompaniesView;
