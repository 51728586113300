import { GraphQLResult } from '@aws-amplify/api';
import {
  CreateCompanyInput,
  CreateCompanyMutation,
  GetAllCompaniesQuery,
  GetCompanyByIdInput,
  GetCompanyByIdQuery,
  UpdateCompanyInput,
  UpdateCompanyMutation,
} from './API';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import callGraphQL from './GraphQLOperation';

export interface ICompany {
  address: string;
  zip: string;
  companyName: string;
  contactMail: string;
  contactPerson: string;
  discount: number;
  id: string;
  mobileNumber: string;
  tableId: string;
  discountArticleId: string;
  employeeOrderLimit?: number;
  orderSortTime?: string;
  comment?: string;
}

export const getCompanyByCompanyId = (
  input: GetCompanyByIdInput
): Promise<GraphQLResult<GetCompanyByIdQuery>> => {
  return callGraphQL<GetCompanyByIdQuery>(queries.getCompanyById, {
    variables: { input },
  });
};

export const getAllCompanies = (): Promise<
  GraphQLResult<GetAllCompaniesQuery>
> => {
  return callGraphQL<GetAllCompaniesQuery>(queries.getAllCompanies);
};

export const createCompany = (
  input: CreateCompanyInput
): Promise<GraphQLResult<CreateCompanyMutation>> => {
  return callGraphQL<CreateCompanyMutation>(mutations.createCompany, {
    variables: { input },
  });
};

export const updateCompany = (
  input: UpdateCompanyInput
): Promise<GraphQLResult<UpdateCompanyMutation>> => {
  return callGraphQL<UpdateCompanyMutation>(mutations.updateCompany, {
    variables: { input },
  });
};
