import { GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api';
import { GetNewsQuery, NewsInput, SetNewsMutation } from './API';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

import callGraphQL from './GraphQLOperation';

export interface INews {
  id: string;
  date: string;
  title: string;
  content: string;
}

export const getNews = (
  isGuest: boolean
): Promise<GraphQLResult<GetNewsQuery>> => {
  return callGraphQL<GetNewsQuery>(queries.getNews, {
    authMode: isGuest
      ? GRAPHQL_AUTH_MODE.AWS_IAM
      : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
};

export const setNews = (
  input: NewsInput
): Promise<GraphQLResult<SetNewsMutation>> => {
  return callGraphQL<SetNewsMutation>(mutations.setNews, {
    variables: { input },
  });
};
