import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  list: {
    display: 'grid',
    gridGap: '2px',
  },
  tiles: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tileItem: {
    margin: '10px',
  },
  listItem: {},
}));

export default useStyles;
