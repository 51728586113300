import React from 'react';
import { createContainer } from 'react-tracked';

import { IThermoBoxCount } from '../api/Thermobox';

interface IThermoboxState {
  boxes: IThermoBoxCount[];
  loading: boolean;
  error: string;
}

type BoxAction =
  | { type: 'startLoading' }
  | { type: 'stopLoading' }
  | { type: 'setError'; error: string }
  | { type: 'setBoxes'; boxes: IThermoBoxCount[] };

const initialState: IThermoboxState = {
  boxes: [],
  loading: false,
  error: '',
};

const boxReducer = (
  state: IThermoboxState,
  action: BoxAction
): IThermoboxState => {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        loading: true,
      };

    case 'stopLoading':
      return {
        ...state,
        loading: false,
      };

    case 'setError':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'setBoxes':
      return {
        ...state,
        loading: false,
        error: '',
        boxes: action.boxes,
      };

    default:
      return state;
  }
};

const useValue = () => React.useReducer(boxReducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
