import React from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { IUser } from '../../api/User';
import * as regex from '../../util/regex';

interface IProps {
  input: IUser;
  open: boolean;
  update(input: IUser): Promise<void>;
  close(): void;
}

interface IFormValues {
  email: string;
  mobileNumber: string;
  name: string;
  surname: string;
  address: string;
  zip: string;
}

const UpdateUserDialog = ({
  input,
  open,
  close,
  update,
}: IProps): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [billOrder, setBillOrder] = React.useState(
    input.canOrderOnBill ? input.canOrderOnBill : false
  );
  const { handleSubmit, register, errors } = useForm<IFormValues>({
    defaultValues: {
      email: input.email,
      mobileNumber: input.mobileNumber,
      name: input.name,
      surname: input.surname,
      address: input.address,
      zip: input.zip,
    },
  });

  const handleUpdate = async (data: IFormValues) => {
    setLoading(true);
    await update({
      ...input,
      mobileNumber: data.mobileNumber,
      name: data.name,
      surname: data.surname,
      address: data.address,
      zip: data.zip,
      canOrderOnBill: billOrder,
    });
    setLoading(false);
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <form noValidate onSubmit={handleSubmit(handleUpdate)}>
        <DialogTitle>User bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            disabled
            autoFocus
            margin="dense"
            id="email"
            name="email"
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.email,
                message: 'Bitte gültige Email Adresse eingeben',
              },
            })}
            helperText={errors.email?.message}
            error={!!errors.email}
            label="Email"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="name"
            name="name"
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: {
                value: 2,
                message: 'Min. 2 Zeichen',
              },
            })}
            helperText={errors.name?.message}
            error={!!errors.name}
            label="Vorname"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="surname"
            name="surname"
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: {
                value: 2,
                message: 'Min. 2 Zeichen',
              },
            })}
            helperText={errors.surname?.message}
            error={!!errors.surname}
            label="Nachname"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="mobileNumber"
            name="mobileNumber"
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.austrianPhone,
                message: 'Bitte geben Sie eine gültige Telefonnummer an',
              },
            })}
            helperText={errors.mobileNumber?.message}
            error={!!errors.mobileNumber}
            label="Telefonnummer"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="address"
            name="address"
            inputRef={register({
              required: 'Pflichtfeld',
              minLength: 3,
            })}
            helperText={errors.address?.message}
            error={!!errors.address}
            label="Adresse"
            fullWidth
          />
          <TextField
            disabled={loading}
            margin="dense"
            id="zip"
            name="zip"
            inputRef={register({
              required: 'Pflichtfeld',
              pattern: {
                value: regex.austrianZip,
                message: 'Bitte geben Sie eine gültige Postleitzahl an',
              },
            })}
            helperText={errors.zip?.message}
            error={!!errors.zip}
            label="Postleitzahl"
            fullWidth
          />
          <FormControlLabel
            label="Adminrechte"
            control={<Checkbox checked={input.isCompanyAdmin} disabled />}
          />
          <br />
          <FormControlLabel
            label="Zum Bestellen auf Rechnung berechtigt"
            control={
              <Checkbox
                checked={billOrder}
                onChange={(ev) => setBillOrder(ev.target.checked)}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={loading} onClick={close}>
            Abbrechen
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Speichern'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateUserDialog;
