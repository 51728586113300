import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  pickerArea: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  removeTableBottomBorder: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  orderDetails: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '3px',
    padding: '10px',
  },
  downloadButton: {
    position: 'fixed',
    bottom: '15px',
    right: '80px',
  },
  errorButton: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;
