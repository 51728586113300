import React from 'react';
import {
  getThermoboxCounts,
  returnThermoboxes,
  IThermoBoxCount,
  addThermoboxes,
} from '../../api/Thermobox';
import useNotificationActions from '../../hooks/useNotificationActions';
import { useDispatch } from '../../store/Thermoboxes';

interface IReturn {
  fetch(isRefetch: boolean): Promise<void>;
  returnBoxes(quantity: number, owner: string): Promise<void>;
  addBoxes(quantity: number, owner: string): Promise<void>;
}

const useBoxManagementActions = (): IReturn => {
  const dispatch = useDispatch();
  const { show } = useNotificationActions();

  const fetch = React.useCallback(
    async (isRefetch: boolean) => {
      if (!isRefetch) {
        dispatch({ type: 'startLoading' });
      }
      try {
        const res = await getThermoboxCounts();

        if (res.data?.getThermoboxCounts?.error) {
          dispatch({
            type: 'setError',
            error: 'Daten konnten nicht geladen werden',
          });
        }

        if (res.data?.getThermoboxCounts?.data) {
          const boxes = res.data.getThermoboxCounts.data as IThermoBoxCount[];
          const sortedBoxes = boxes
            .filter((box) => box && box.quantity > 0)
            .sort((a, b) => (a.ownerName > b.ownerName ? 1 : -1));
          dispatch({
            type: 'setBoxes',
            boxes: sortedBoxes as IThermoBoxCount[],
          });
        }
      } catch (error) {
        dispatch({
          type: 'setError',
          error: 'Daten konnten nicht geladen werden',
        });
      }
    },
    [dispatch]
  );

  const returnBoxes = async (quantity: number, owner: string) => {
    dispatch({ type: 'startLoading' });
    try {
      await returnThermoboxes({
        quantity,
        owner,
      });
      show(
        'Boxen retourniert',
        `${quantity} ${
          quantity > 1 ? 'Boxen wurden' : 'Box wurde'
        } zurückgebucht`,
        'success'
      );
      await fetch(true);
    } catch (error) {
      show(
        'Error',
        `${
          quantity > 1 ? 'Boxen konnten' : 'Box konnte'
        } nicht zurückgebucht werden`,
        'error'
      );
      dispatch({ type: 'stopLoading' });
    }
  };

  const addBoxes = async (quantity: number, owner: string) => {
    dispatch({ type: 'startLoading' });
    try {
      await addThermoboxes({
        quantity,
        owner,
      });
      show(
        'Boxen hinzugefügt',
        `${quantity} ${quantity > 1 ? 'Boxen wurden' : 'Box wurde'} gebucht`,
        'success'
      );
      await fetch(true);
    } catch (error) {
      show(
        'Error',
        `${
          quantity > 1 ? 'Boxen konnten' : 'Box konnte'
        } nicht hinzugefügt werden`,
        'error'
      );
      dispatch({ type: 'stopLoading' });
    }
  };

  return {
    fetch,
    returnBoxes,
    addBoxes,
  };
};

export default useBoxManagementActions;
