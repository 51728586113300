import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

import { useTrackedState } from '../../store/Orders';
import { useTrackedState as useCompaniesState } from '../../store/Companies';
import useStyles from './AppOrderManagement.css';
import useOrderActions from '../../hooks/useOrderActions';
import OrderAccordionGroup from './OrderAccordionGroup';
import { CompanyToDetailsMap, SortVariantEnum } from './types';
import DownloadButton from './DownloadButton';
import usePolling from '../../hooks/usePolling';
import DeliveryView from './DeliveryView';
import useCompanyActions from '../appUserManagement/useCompanyActions';
import PrintButton from '../printButton/PrintButton';

const AdminView = (): JSX.Element => {
  const { fetchByDate, getDeliveryView, sortByCompany } = useOrderActions();
  const { fetch: fetchCompanies } = useCompanyActions();
  const { orders, error, loading } = useTrackedState();
  const { companies } = useCompaniesState();
  const { flag } = usePolling(10000);
  const isFirstRun = React.useRef(true);
  const [expandLvl1, setExpandLvl1] = React.useState(false);
  const [expandLvl2, setExpandLvl2] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<DateTime | null>(
    DateTime.local()
  );
  const [sortBy, setSortby] = React.useState<SortVariantEnum>(
    SortVariantEnum.byArticle
  );
  const classes = useStyles();

  const detailsMap: CompanyToDetailsMap = {};
  companies.forEach((company) => {
    detailsMap[company.companyName] = {
      sortTime: company.orderSortTime ? company.orderSortTime : '12:00',
      comment: company.comment ? company.comment : '',
    };
  });

  React.useEffect(() => {
    if (isFirstRun.current) {
      fetchCompanies();
      if (selectedDate && selectedDate.isValid) {
        fetchByDate(selectedDate, false);
      }
      isFirstRun.current = false;
    } else if (selectedDate && selectedDate.isValid) {
      fetchByDate(selectedDate, true);
    }
  }, [fetchByDate, selectedDate, flag, fetchCompanies]);

  if (error) {
    return (
      <Paper square>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Paper>
    );
  }

  return (
    <>
      <Box component={Paper} className={classes.pickerArea}>
        <DatePicker
          openTo="date"
          clearable={false}
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          allowKeyboardControl={false}
          disabled={loading}
          renderInput={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField style={{ marginBottom: 10 }} {...props} />
          )}
        />
        <Box displayPrint="none">
          <FormControl component="fieldset">
            <FormLabel component="legend">Sortieren nach</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="sortby"
              value={sortBy}
              onChange={(ev) => setSortby(ev.target.value as SortVariantEnum)}
            >
              <FormControlLabel
                disabled={loading}
                value={SortVariantEnum.byArticle}
                control={<Radio />}
                label="Artikel"
              />
              <FormControlLabel
                disabled={loading}
                value={SortVariantEnum.byUser}
                control={<Radio />}
                label="User"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box displayPrint="none">
          <FormControl component="fieldset">
            <FormLabel component="legend">Alle Firmen öffnen</FormLabel>
            <Switch
              value={expandLvl1}
              onChange={(ev) => setExpandLvl1(ev.target.checked)}
            />
            <FormLabel component="legend">Alle Bestellungen öffnen</FormLabel>
            <Switch
              value={expandLvl2}
              onChange={(ev) => setExpandLvl2(ev.target.checked)}
            />
          </FormControl>
        </Box>
      </Box>
      {!loading && orders.length < 1 ? (
        <Paper square>
          <Typography variant="h6" align="center" style={{ padding: '20px' }}>
            Derzeit noch keine Bestellungen für diesen Tag.
          </Typography>
        </Paper>
      ) : (
        <>
          {sortBy === SortVariantEnum.byUser ? (
            <>
              <OrderAccordionGroup
                loading={loading}
                groupedOrders={sortByCompany(orders)}
                expandLvl1={expandLvl1}
                expandLvl2={expandLvl2}
              />
              <DownloadButton orders={orders} companies={companies} />
            </>
          ) : (
            <>
              <DeliveryView
                detailsMap={detailsMap}
                orders={getDeliveryView(orders)}
                expandLvl1={expandLvl1}
                expandLvl2={expandLvl2}
              />
              <PrintButton bottom={15} right={80} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdminView;
