import React from 'react';
import BusinessIcon from '@material-ui/icons/Business';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FeedbackIcon from '@material-ui/icons/Feedback';
import PaymentIcon from '@material-ui/icons/Payment';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { ICompany } from '../../api/Company';

interface IProps {
  company: ICompany;
}

const CompanyDetailsList = ({ company }: IProps): JSX.Element => (
  <List dense>
    <ListItem>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Firmenname" secondary={company.companyName} />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <LocationOnIcon />
      </ListItemIcon>
      <ListItemText primary="Adresse" secondary={company.address} />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Kontaktperson" secondary={company.contactPerson} />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <MailIcon />
      </ListItemIcon>
      <ListItemText primary="Kontaktmail" secondary={company.contactMail} />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <PhoneIphoneIcon />
      </ListItemIcon>
      <ListItemText primary="Kontaktnummer" secondary={company.mobileNumber} />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <PaymentIcon />
      </ListItemIcon>
      <ListItemText
        primary="Zuschuss"
        secondary={`€${company.discount / 100}`}
      />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <PaymentIcon />
      </ListItemIcon>
      <ListItemText
        primary="Bestelllimit"
        secondary={`€${
          company.employeeOrderLimit
            ? company.employeeOrderLimit / 100
            : 'kein Limit'
        }`}
      />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <AccessTimeIcon />
      </ListItemIcon>
      <ListItemText
        primary="Bestellzeit"
        secondary={`${company.orderSortTime ? company.orderSortTime : '12:00'}`}
      />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <FeedbackIcon />
      </ListItemIcon>
      <ListItemText
        primary="Kommentar"
        secondary={`${company.comment ? company.comment : '-'}`}
      />
    </ListItem>
  </List>
);

export default CompanyDetailsList;
