import React from 'react';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import CompanyAccordion from './CompanyAccordion';
import { useTrackedState } from '../../store/Companies';
import useCompanyActions from './useCompanyActions';

interface IProps {
  companyId: string;
}
const CompanyAdminView = ({ companyId }: IProps): JSX.Element => {
  const { companies, loading, error } = useTrackedState();
  const { fetchSingle } = useCompanyActions();

  React.useEffect(() => {
    fetchSingle(companyId);
  }, [fetchSingle, companyId]);

  if (error) {
    return (
      <Typography variant="body2" align="center" color="error">
        {error}
      </Typography>
    );
  }

  if (loading) {
    return <Skeleton variant="rect" height={494} />;
  }

  if (companies.length > 0) {
    return <CompanyAccordion company={companies[0]} single />;
  }

  return (
    <Typography variant="body2" align="center">
      Unternehmen nicht gefunden
    </Typography>
  );
};

export default CompanyAdminView;
