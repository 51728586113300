import React from 'react';
import { Box, Fab, Grid, Slide, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';

import Spacer from '../util/Spacer';
import newsImage from '../../static/images/news.jpg';
import { RoleEnum, useTrackedState } from '../../store/Auth';
import useNews from '../../hooks/useNews';
import SetNewsDialog from './SetNewsDialog';

const News = (): JSX.Element => {
  const { role } = useTrackedState();
  const { news, loading, error, refetch } = useNews(role === RoleEnum.GUEST);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  if (error) {
    return (
      <Box p={3} minHeight="90vh">
        <Typography variant="body1" color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={4} minHeight="90vh">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Slide in direction="right">
            <Box p={{ xs: 0, sm: 5 }}>
              <Typography variant="h6" align="center">
                {loading ? <Skeleton height="70px" /> : news.title}
              </Typography>
              <Typography variant="subtitle2" color="primary" align="center">
                {loading ? (
                  <Skeleton height="50px" />
                ) : (
                  `(${new Date(news.date).toLocaleDateString('de-AT')})`
                )}
              </Typography>
              <Spacer height={30} />
              <Typography color="textSecondary" variant="body1" align="justify">
                {loading ? <Skeleton height="150px" /> : news.content}
              </Typography>
            </Box>
          </Slide>
        </Grid>
        <Slide in direction="left">
          <Grid
            item
            xs={12}
            md={6}
            style={{
              backgroundImage: `url(${newsImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          />
        </Slide>
      </Grid>
      <SetNewsDialog
        open={dialogOpen}
        close={() => setDialogOpen(false)}
        news={news}
        refetch={refetch}
      />
      {role === RoleEnum.ADMIN && (
        <Fab
          variant="extended"
          color="primary"
          style={{
            position: 'fixed',
            bottom: '15px',
            right: '75px',
          }}
          onClick={() => setDialogOpen(true)}
        >
          <EditIcon style={{ marginRight: '5px' }} />
          Bearbeiten
        </Fab>
      )}
    </Box>
  );
};

export default News;
