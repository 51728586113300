import React from 'react';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { data } from './data';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
});

const Interviews = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  return (
    <Box p={isMobile ? 1 : 4}>
      <Typography variant="h4" color="primary" align="center" gutterBottom>
        Interviews
      </Typography>
      <Grid container spacing={2}>
        {Object.keys(data).map((interview) => (
          <Grid
            key={`interviewcard-${data[interview].title}`}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
          >
            <Card>
              <CardActionArea
                onClick={() => history.push(`/interviews/${interview}`)}
              >
                <CardMedia
                  className={classes.media}
                  image={data[interview].imgLandscape}
                  title={data[interview].title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {data[interview].title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data[interview].profession}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Interviews;
