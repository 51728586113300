import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import {
  GetMenusByDateRangeInput,
  GetMenusByDateRangeQuery,
  SetMenuInput,
  SetMenuMutation,
  MenuEnum,
} from './API';
import callGraphQL from './GraphQLOperation';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

export interface IMenu {
  menuType: MenuEnum;
  date: string;
  description: string;
  allergens: string;
}

export const getMenusByDateRange = (
  isGuest: boolean,
  input: GetMenusByDateRangeInput
): Promise<GraphQLResult<GetMenusByDateRangeQuery>> => {
  return callGraphQL<GetMenusByDateRangeQuery>(queries.getMenusByDateRange, {
    variables: { input },
    authMode: isGuest
      ? GRAPHQL_AUTH_MODE.AWS_IAM
      : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  });
};

export const setMenu = (
  input: SetMenuInput
): Promise<GraphQLResult<SetMenuMutation>> => {
  return callGraphQL<SetMenuMutation>(mutations.setMenu, {
    variables: { input },
  });
};
