import React from 'react';
import { Box, Grid, Typography, Slide } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { data } from './data';

interface ParamTypes {
  id: string;
}

const InterviewDetailView = (): JSX.Element => {
  const { id } = useParams<ParamTypes>();
  const interviewData = data[id];
  // const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box p={2} bgcolor="white">
      <Slide in direction="down">
        <Box>
          <Box
            style={{
              backgroundImage: `url(${interviewData.imgLandscape})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              height: '400px',
            }}
            mb={2}
          />
          <Typography variant="h4" align="center" color="primary">
            {interviewData.title}
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary">
            {interviewData.profession}
          </Typography>
        </Box>
      </Slide>
      <Grid container>
        <Slide in direction="right">
          <Grid item xs={12} md={8} lg={7}>
            <Box p={4}>
              {interviewData.dialog.map((dialog) => (
                <Box pt={2} pb={4}>
                  <Typography
                    style={{ fontStyle: 'italic' }}
                    variant="body1"
                    align="left"
                    color="primary"
                  >
                    {dialog.question}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    align="left"
                    color="textPrimary"
                  >
                    {dialog.answer}
                  </Typography>
                </Box>
              ))}
              <Typography variant="h6" align="center" color="primary">
                „{interviewData.quote}“
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                color="textSecondary"
              >
                {`Herzlichen Dank an ${interviewData.thanksTo} für ${interviewData.thanksAddress}`}
              </Typography>
            </Box>
          </Grid>
        </Slide>
        <Slide in direction="left">
          <Grid item xs={12} md={4} lg={5}>
            <Box
              style={{
                backgroundImage: `url(${interviewData.imgPortrait})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                height: '100%',
                minHeight: '300px',
              }}
            />
          </Grid>
        </Slide>
      </Grid>
    </Box>
  );
};

export default InterviewDetailView;
