import React from 'react';
import { Card, CardContent, Divider, Typography } from '@material-ui/core';

import useStyles from './AppMenuManagement.css';
import { IMenu } from '../../api/Menu';
import MenuTypeSection from './MenuTypeSection';
import { getReadableDateFromISO } from '../../util/time';

interface IProps {
  day: string;
  date: string;
  menus: IMenu[];
}

const MenuCard = ({ day, date, menus }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Card variant="outlined">
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="center">
            {day}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {getReadableDateFromISO(date)}
          </Typography>
          <Divider />
          {menus.length < 1 ? (
            <Typography
              variant="body2"
              align="center"
              color="primary"
              className={classes.menuTypeLabel}
            >
              Noch keine Menüs eingetragen
            </Typography>
          ) : (
            menus.map((menu) => (
              <MenuTypeSection
                key={`${menu.date}${menu.menuType}`}
                menu={{
                  ...menu,
                  date: menu.date ? menu.date : date,
                }}
              />
            ))
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default MenuCard;
