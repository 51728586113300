import React from 'react';
import SkeletonList from '../skeletonList/SkeletonList';
import OrderAccodion from './OrderAccordion';

import { IGroupedOrders } from '../../hooks/useOrderActions';

interface IProps {
  groupedOrders: IGroupedOrders;
  loading: boolean;
  expandLvl1: boolean;
  expandLvl2: boolean;
}

const OrderAccordionGroup = ({
  groupedOrders,
  loading,
  expandLvl1,
  expandLvl2,
}: IProps): JSX.Element => {
  if (loading) {
    return <SkeletonList />;
  }

  return (
    <>
      {Object.keys(groupedOrders).map((group) => {
        let length = 0;
        groupedOrders[group].forEach((order) => {
          length += order.articles.length;
        });
        return (
          <OrderAccodion
            group={group}
            orders={groupedOrders[group]}
            key={`accordion${group}`}
            length={length}
            expandLvl1={expandLvl1}
            expandLvl2={expandLvl2}
          />
        );
      })}
    </>
  );
};

export default OrderAccordionGroup;
